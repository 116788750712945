import { Toaster } from '@/components/ui/toaster'
import { queryClient } from '@/lib/queryClient'
import { logger } from '@/utils'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { Settings } from './Settings'
import { Users } from './Users'
import Impersonate from './auth/Impersonate'
import { SignInPage, SignUpPage } from './auth/SignInUp'
import FilePage from './files/File'
import Files from './files/Files'
import Folder from './folders/Folder'
import NewFolder from './folders/FolderNew'
import FolderSources from './folders/FolderSources'
import FolderSummariesNew from './folders/FolderSummariesNew'
import FolderSummary from './folders/FolderSummary'
import Folders from './folders/Folders'
import ConnectedLayout from './layout/connected-layout'
import RootLayout from './layout/root-layout'
import MeetingPage from './meetings/Meeting'
import Meetings from './meetings/Meetings'
import NewMeeting from './meetings/New'
import { Prompt } from './prompts/Prompt'
import PromptPage from './prompts/Prompt'
import { Prompts } from './prompts/Prompts'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin, /^https:\/\/api\.notae\.ai/],
    }),
  ],
  //debug: import.meta.env.MODE === 'development',
  // tracesSampleRate: 0.1,

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.notae\.ai/],

  environment: import.meta.env.MODE,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.MODE !== 'development',
})

dayjs.locale('fr')

logger('MODE : ', import.meta.env.MODE)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: '/', element: <Navigate to="/meetings" replace={true} /> },
      { path: '/sign-in', element: <SignInPage /> },
      { path: '/sign-up', element: <SignUpPage /> },
      { path: '/impersonate', element: <Impersonate /> },
      {
        element: <ConnectedLayout />,
        path: '',
        children: [
          { path: 'files', element: <Files /> },
          { path: 'files/:id', element: <FilePage /> },
          { path: 'folders', element: <Folders /> },
          { path: 'folders/new', element: <NewFolder /> },
          {
            path: 'folders/:id',
            element: <Folder />,
            children: [
              { index: true, element: <Navigate to="sources" replace={true} /> },
              { path: 'sources', element: <FolderSources /> },
              { path: 'summaries/new', element: <FolderSummariesNew /> },
              { path: 'summaries/:summary_id', element: <FolderSummary /> },
            ],
          },

          { path: 'meetings/new', element: <NewMeeting /> },
          { path: 'meetings/:id', element: <MeetingPage /> },
          { path: 'meetings', element: <Meetings /> },
          { path: 'prompts', element: <Prompts /> },
          { path: 'prompts/new', element: <Prompt /> },
          { path: 'prompts/:id', element: <PromptPage /> },
          { path: 'users', element: <Users /> },
          { path: 'settings', element: <Settings /> },
        ],
      },
    ],
  },
])

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />

      <Toaster />

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
