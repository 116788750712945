import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { classNames } from '@/utils'
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  type: 'folders' | 'files' | 'meetings'
}

export const DEFAULT_SIZE = 150

export function DataTable<TData, TValue>({ columns, data, type }: DataTableProps<TData, TValue>) {
  const [sorting] = useState<SortingState>([])

  const navigate = useNavigate()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [{ pageIndex, pageSize }, setUrlPagination] = useQueryParams({
    pageIndex: withDefault(NumberParam, 0),
    pageSize: withDefault(NumberParam, 10),
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),

    // VERY IMPORTANT : if we dont set this to false, when we trigger an url change the index will be reset and we go back to pageIndex 0.
    autoResetPageIndex: false, //turn off auto reset of pageIndex

    onPaginationChange: (updater) => {
      const nextState = updater instanceof Function ? updater({ pageIndex: pageIndex, pageSize: pageSize }) : updater
      setUrlPagination(nextState)
      return nextState
    },

    state: {
      sorting,
      columnFilters,
      pagination: { pageIndex: pageIndex, pageSize: pageSize },
    },
    defaultColumn: {
      size: DEFAULT_SIZE,
      minSize: 20,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  })

  return (
    <div>
      {/* 
      <div className="flex items-center py-4">
        <Input
          placeholder="Filtrer par titre..."
          value={(table.getColumn("title")?.getFilterValue() as string) ?? ""}
          onChange={(event) => table.getColumn("title")?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />
      </div> */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      //className={`w-[${header.getSize()}px]`}
                      // style={header.getSize() === DEFAULT_SIZE ? {} : { width: `${header.getSize()}px` }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                if (row.groupingColumnId) {
                  // Vérifie si la ligne est un groupe
                  return (
                    // Rendu pour l'en-tête du groupe
                    <TableRow key={row.id}>
                      <TableCell>
                        {/* Vous pouvez personnaliser ce rendu comme vous le souhaitez */}
                        {row.getValue(row.groupingColumnId)} ({row.subRows.length})
                      </TableCell>
                    </TableRow>
                  )
                } else {
                  return (
                    // TODO : Handle Column size
                    <TableRow
                      onClick={(event) => {
                        if (event.metaKey || event.ctrlKey) {
                          const win = window.open(`/${type}/${row.original.id}`, '_blank')
                          win?.focus()
                        } else {
                          navigate(`/${type}/${row.original.id}`)
                        }
                      }}
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'} /* className={} */
                      className={classNames('hover:cursor-pointer')}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  )
                }
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  Aucun résultat
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button variant="outline" size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Précédent
        </Button>
        <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Suivant
        </Button>
      </div>
    </div>
  )
}
