import type { FolderStatus, Prisma } from '@/types'
import type { FolderPayload, FoldersPayload, SourcePayload, SourceType, SummaryPayload } from '@/types/others.types'
import { API_URL, logger } from '@/utils'
import { useAuth } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { getHeaders, useAsOrg, useAsUser } from './useUser'

export function useFolders({ filters }: { filters: { user?: string | null } } = { filters: { user: null } }) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const params: {
    user?: string
  } = {}

  if (filters.user) {
    params.user = filters.user
  }
  const fetchFolders = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<FoldersPayload>(`${API_URL}/folders`, {
      params: params,
      headers: headers,
    })

    return response
  }

  return useQuery({
    queryKey: ['folders', params],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchFolders().then((result) => result.data)
    },
  })
}

export function useFolder(id: string | undefined) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchFolder = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<FolderPayload>(`${API_URL}/folders/${id}`, {
      headers: headers,
    })

    return response.data
  }

  return useQuery({
    queryKey: ['folders', id],
    queryFn: async () => {
      return fetchFolder().then((result) => result)
    },
    enabled: !!id && isSignedIn,
    refetchInterval: (query) => {
      if (['SUMMARY_IN_PROGRESS'].includes(query.state?.data?.status)) {
        return 5000
      }
      return false
    },
  })
}

export const useDeleteFolder = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deleteFolder = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/folders/${id}`, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-folder'],
    mutationFn: deleteFolder,
    retry: 0,
    onSettled: async (data, error, { id }) => {
      queryClient.removeQueries({
        queryKey: ['folders', id],
      })
      return queryClient.invalidateQueries({ queryKey: ['folders'] })
    },
  })

  return mutation
}

export const useDeleteSourceFromFolder = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deleteSourceFromFolder = async ({ folder_id, source_id }: { folder_id: string; source_id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/folders/${folder_id}/sources/${source_id}`, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-source-from-folder'],
    mutationFn: deleteSourceFromFolder,
    retry: 0,
    onSettled: async (data, error, { folder_id }) => {
      return queryClient.invalidateQueries({ queryKey: ['folders'] })
    },
  })

  return mutation
}

export type CreateFolderInput = {
  title?: string
  status: FolderStatus
  userId: string
  organizationId?: string
}

export const useCreateFolder = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createFolder = async (data: CreateFolderInput) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createFolder : ', data)

    const response = await axios.post<FolderPayload>(`${API_URL}/folders`, data, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-folder'],
    mutationFn: createFolder,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['folders'] })
    },
  })

  return mutation
}

export const useUpdateFolder = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const updateFolder = async ({ id, folder }: { id: string; folder: Prisma.FolderUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('useUpdateFolder : ', folder)

    const response = await axios.put<FolderPayload>(`${API_URL}/folders/${id}`, folder, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-folder'],
    mutationFn: updateFolder,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['folders'] })
    },
  })

  return mutation
}

export const useAddSourceToFolder = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const addSourceToFolder = async ({
    folderId,
    sources,
  }: { folderId: string; sources: { sourceId: string; sourceType: SourceType }[] }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('addSourceToFolder : ', { folderId, sources })

    const response = await axios.post<SourcePayload>(`${API_URL}/folders/${folderId}/sources`, sources, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['add-source-to-folder'],
    mutationFn: addSourceToFolder,
    retry: 0,
    onSettled: async (data) => {
      return queryClient.invalidateQueries({ queryKey: ['folders', data?.id] })
    },
  })

  return mutation
}

export const useCreateSummaryFromSources = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createSummaryFromSources = async ({
    folderId,
    promptId,
    sourceIds,
    title,
    context,
  }: { folderId: string; promptId: string; sourceIds: string[]; title: string; context: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createSummaryFromSources : ', { folderId, promptId, sourceIds })

    const response = await axios.post<SummaryPayload>(
      `${API_URL}/folders/${folderId}/summary`,
      { promptId, sourceIds, title, context },
      {
        headers: headers,
      },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-summary-from-sources'],
    mutationFn: createSummaryFromSources,
    retry: 0,
    onSettled: async (data) => {
      return queryClient.invalidateQueries({ queryKey: ['folders', data?.id] })
    },
  })

  return mutation
}
