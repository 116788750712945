import CustomLoader from '@/components/custom/CustomLoader'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useUpdateMeeting } from '@/hooks/useMeeting'
import { usePrompts } from '@/hooks/usePrompt'
import { cn } from '@/lib/utils'
import { type MeetingQueryResult, PromptShowFor } from '@/types/others.types'
import { CircleCheck } from 'lucide-react'

type Props = {
  meeting: MeetingQueryResult
  className?: string
}

export default function AddDefaultPrompt({ meeting, className }: Props) {
  const updateMeetingMutation = useUpdateMeeting()

  const prompts = usePrompts({ type: 'PROMPT', showFor: PromptShowFor.MEETING })

  const submit = (value: string) => {
    return updateMeetingMutation.mutate({
      id: meeting.data?.id,
      meeting: {
        defaultPromptId: value,
      },
    })
  }

  if (!prompts.data) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex flex-row gap-2 items-center">
        Compte rendu souhaité
        {updateMeetingMutation.isPending && <CustomLoader size={4} />}
        {updateMeetingMutation.isSuccess && <CircleCheck className="h-4 w-4 text-green-600" />}
      </Label>
      <Select defaultValue={meeting.data?.defaultPromptId ?? ''} onValueChange={submit} disabled={updateMeetingMutation.isPending}>
        <SelectTrigger className={cn('md:w-1/3', className)}>
          <SelectValue placeholder="Choisissez un modèle" />
        </SelectTrigger>
        <SelectContent>
          {prompts.data.map((prompt) => (
            <SelectItem key={prompt.id} value={prompt.id}>
              {prompt.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
