import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './containers/App.tsx'
import './styles/index.css'

window.addEventListener('vite:preloadError', () => {
  console.log('vite:preloadError')
  window.location.reload() // for example, refresh the page
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
