import ButtonMagicPrompt from '@/components/custom/ButtonMagicPrompt'
import CustomLoader from '@/components/custom/CustomLoader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { langfuseWeb } from '@/lib/langfuse'
import { cn } from '@/lib/utils'
import type { SummaryPayload } from '@/types/others.types'
import { formatDate, logger } from '@/utils'
import { useUser } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as clipboard from 'clipboard-polyfill'
import { Copy, CopyCheck, ThumbsDown, ThumbsUp } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Markdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'
import * as yup from 'yup'

type Props = {
  summary: SummaryPayload
}

const formSchema = yup.object({
  comment: yup.string(),
})

type FormType = yup.InferType<typeof formSchema>

export default function SummaryContent({ summary }: Props) {
  logger('SummaryContent : ', summary)

  const { user } = useUser()

  const { toast } = useToast()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {},
  })

  const [copied, setCopied] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const [rated, setRated] = useState<boolean>(false)

  const copyHtmlToClipboard = async () => {
    const htmlContent = document.getElementById('content-text')?.innerHTML
    if (!htmlContent) return // TODO : Handle error
    try {
      const blob = new Blob([htmlContent], { type: 'text/html' })
      const data = [new clipboard.ClipboardItem({ 'text/html': blob })]
      await clipboard.write(data)
      logger('Contenu HTML copié dans le presse-papiers.')
      toast({
        title: 'Contenu copié dans le presse-papier.',
      })
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 4000)
    } catch (err) {
      logger('Erreur lors de la copie du contenu HTML :', err)
    }
  }

  const score = async (value: string) => {
    if (!summary?.traceId) return

    logger('score value : ', value)

    setOpen(true)

    await langfuseWeb.score({
      traceId: summary?.traceId,
      observationId: summary?.observationId,
      name: (user?.firstName ? user?.firstName + ' ' + user.lastName : user?.emailAddresses[0]?.emailAddress) ?? 'Unknown user.',
      value: value === 'yes' ? 1 : 0,
    })
  }

  const commentScore = async (data: FormType) => {
    if (!summary?.traceId) return

    await langfuseWeb.score({
      traceId: summary?.traceId,
      observationId: summary?.observationId,
      name: (user?.firstName ? user?.firstName + ' ' + user.lastName : user?.emailAddresses[0]?.emailAddress) ?? 'Unknown user.',
      value: 0,
      comment: data.comment,
    })
    setOpen(false)
    setRated(true)

    toast({
      title: 'Merci pour votre commentaire',
    })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex flex-row justify-between items-center ">
              <div className="flex flex-col">
                <div className="mb-1">{summary?.title ?? 'Compte rendu'}</div>
                <div className="text-xs font-light leading-5 text-gray-500 ">Généré le {formatDate(summary.createdAt)}</div>
              </div>
              <div className="flex gap-1">
                {!!summary && <ButtonMagicPrompt summary={summary} />}

                <Button variant="outline" onClick={copyHtmlToClipboard}>
                  {copied ? (
                    <>
                      <CopyCheck className="md:mr-2 h-4 w-4" />

                      <span className="hidden md:block">Copié !</span>
                    </>
                  ) : (
                    <>
                      <Copy className="md:mr-2 h-4 w-4" />

                      <span className="hidden md:block">Copier</span>
                    </>
                  )}
                </Button>
              </div>
            </div>
            {summary?.traceId ? (
              <div className="flex justify-end ">
                <Card className="flex flex-col pt-2">
                  {rated ? (
                    <>
                      <p className="text-sm font-normal text-center mx-3 mb-2">Merci d'avoir donné votre avis.</p>
                    </>
                  ) : (
                    <>
                      <p className="text-sm font-normal text-center px-2">Êtes-vous satisfait ?</p>
                      <div className="flex justify-between">
                        <Button variant="ghost" onClick={() => score('no')}>
                          <ThumbsDown className="md:mr-2 h-4 w-4" /> <span className="hidden md:block">Non</span>
                        </Button>

                        <Button variant="ghost" onClick={() => score('yes')}>
                          <ThumbsUp className="md:mr-2 h-4 w-4" /> <span className="hidden md:block">Oui</span>
                        </Button>
                      </div>
                    </>
                  )}
                </Card>
              </div>
            ) : (
              <></>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <article
            className={cn(
              'prose-base',
              'prose-table:table-auto',
              'prose-table:border-slate-400 prose-table:border prose-table:border-collapse prose-table:border-spacing-2',
              'prose-thead:bg-slate-50',
              'prose-th:border prose-th:border-slate-300',
              'prose-td:border prose-td:border-slate-300 prose-td:px-2',
              'prose-ul:list-disc',
              'mb-5 mt-5',
            )}
            id="content-text"
          >
            <Markdown remarkPlugins={[remarkBreaks, remarkGfm]}>{summary?.content}</Markdown>
          </article>
        </CardContent>
      </Card>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <Form {...form}>
            <DialogHeader>
              <DialogTitle>Commentaires</DialogTitle>
              <DialogDescription>Donnez nous votre avis sur ce compte rendu pour qu'on puisse l'améliorer.</DialogDescription>
            </DialogHeader>
            <form onSubmit={form.handleSubmit(commentScore)}>
              <FormField
                control={form.control}
                name="comment"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Commentaires</FormLabel>
                    <FormControl>
                      <Textarea placeholder="" rows={5} className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <br />
              <DialogFooter>
                <Button type="submit" disabled={form.formState.isSubmitting}>
                  {form.formState.isSubmitting ? <CustomLoader /> : 'Envoyer'}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
