import type { Prisma } from '@/types'
import type { FilePayload } from '@/types/others.types'
import { API_URL, logger } from '@/utils'
import { useAuth } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { getHeaders, useAsOrg, useAsUser } from './useUser'

export function useFiles() {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchFiles = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<FilePayload[]>(`${API_URL}/files`, {
      headers: headers,
    })

    return response
  }

  return useQuery({
    queryKey: ['files'],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchFiles().then((result) => result.data)
    },
    refetchInterval: (query) => {
      if (query.state?.data) {
        const files = query.state.data
        const hasProcessingFile = files.some((file) => ['PROCESSING'].includes(file.status))

        if (hasProcessingFile) {
          return 5000 // Rafraîchir toutes les 5 secondes
        }
      }

      return false // Ne pas rafraîchir automatiquement si aucun fichier n'est en cours de traitement
    },
  })
}

export function useFile(id: string | undefined) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchFile = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<FilePayload>(`${API_URL}/files/${id}`, {
      headers: headers,
    })

    return response.data
  }

  return useQuery({
    queryKey: ['files', id],
    queryFn: async () => {
      return fetchFile().then((result) => result)
    },
    enabled: !!id && isSignedIn,
  })
}

export const useAnalyzeFile = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const analyzeFile = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<Prisma.FileGetPayload<true>>(
      `${API_URL}/files/${id}/analyze`,
      {},
      {
        headers: headers,
      },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['analyze-file'],
    mutationFn: analyzeFile,
    retry: 0,
    onSettled: async (_data, _error, { id }) => {
      await queryClient.invalidateQueries({ queryKey: ['files'] })
      await queryClient.invalidateQueries({ queryKey: ['files', id] })
    },
  })

  return mutation
}

export const useDeleteFile = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deleteFile = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/files/${id}`, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-file'],
    mutationFn: deleteFile,
    retry: 0,
    onSettled: async (_data, _error, { id }) => {
      queryClient.removeQueries({
        queryKey: ['files', id],
      })
      return queryClient.invalidateQueries({ queryKey: ['files'] })
    },
  })

  return mutation
}

export const useUploadFile = (progressCallBack?: (value: number) => void) => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const uploadFileToServer = async ({ file, mimeType }: { file: File; mimeType?: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const formData = new FormData()
    formData.append('file', file)
    mimeType && formData.append('mimeType', mimeType)

    logger('useUploadFile | file : ', file)

    const response = await axios.post<FilePayload>(`${API_URL}/files`, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        if (event.loaded && event.total) {
          const progress = Math.round((100 * event.loaded) / event?.total)
          progressCallBack?.(progress)
        }
      },
    })

    return response.data
  }

  const mutation = useMutation({
    mutationFn: uploadFileToServer,
    onError(_error, variables, _context) {
      Sentry.withScope((scope) => {
        const newError = new Error(`Failed to upload file ${variables.file.name}`)
        const contextFile = {
          size: variables?.file?.size,
          fileType: variables?.file?.type,
          mimeType: variables?.mimeType,
        }
        scope.setContext('file', contextFile)
        Sentry.captureException(newError)
      })
    },
  })

  return mutation
}
