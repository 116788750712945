import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import type { MeetingQueryResult } from '@/types/others.types'
import { Loader, XCircle } from 'lucide-react'

import SummaryNew from './SummaryNew'
import SummaryView from './SummaryView'

type Props = {
  meeting: MeetingQueryResult
}

export default function SummaryTab({ meeting }: Props) {
  if (
    meeting.data?.status &&
    ['CREATED', 'AUDIO_READY', 'UPLOADING', 'TRANSCRIPTION_IN_PROGRESS', 'ERROR'].includes(meeting.data?.status)
  ) {
    return (
      <div className="w-full">
        <Alert>
          <Loader className="h-4 w-4" />
          <AlertTitle>Le transcript n'est pas encore prêt</AlertTitle>
          <AlertDescription>Vous pourrez générer un compte rendu lorsque le transcript sera prêt.</AlertDescription>
        </Alert>
      </div>
    )
  } else if (meeting.data?.status === 'TRANSCRIPT_READY' && meeting.data.transcript?.transcriptLine.length === 0) {
    return (
      <div className="w-full">
        <Alert variant="destructive">
          <XCircle className="h-4 w-4" />
          <AlertTitle>Le transcript est vide</AlertTitle>
          <AlertDescription>
            Le texte n'a pas pu être extrait de l'audio. <br />
            Si vous pensez que c'est une erreur, contactez-nous.
          </AlertDescription>
        </Alert>
      </div>
    )
  }

  return <>{!meeting?.data?.summary?.length ? <SummaryNew type="card" meeting={meeting} /> : <SummaryView meeting={meeting} />}</>
}
