'use client'

import { Label } from '@/components/ui/label'
import { Clock } from 'lucide-react'
import * as React from 'react'

import { TimePickerInput } from './time-picker-input'

interface TimePickerDemoProps {
	date: Date | undefined
	setDate: (date: Date | undefined) => void
}

export function TimePickerDemo({ date, setDate }: TimePickerDemoProps) {
	const minuteRef = React.useRef<HTMLInputElement>(null)
	const hourRef = React.useRef<HTMLInputElement>(null)
	const secondRef = React.useRef<HTMLInputElement>(null)

	return (
		<div className="flex items-end gap-2">
			<div className="grid gap-1 text-center">
				<Label htmlFor="hours" className="text-xs">
					Heures
				</Label>
				<TimePickerInput picker="hours" date={date} setDate={setDate} ref={hourRef} onRightFocus={() => minuteRef.current?.focus()} />
			</div>
			<div className="grid gap-1 text-center">
				<Label htmlFor="minutes" className="text-xs">
					Minutes
				</Label>
				<TimePickerInput
					picker="minutes"
					date={date}
					setDate={setDate}
					ref={minuteRef}
					onLeftFocus={() => hourRef.current?.focus()}
					onRightFocus={() => secondRef.current?.focus()}
				/>
			</div>
			{/*       <div className="grid gap-1 text-center">
        <Label htmlFor="seconds" className="text-xs">
          Secondes
        </Label>
        <TimePickerInput picker="seconds" date={date} setDate={setDate} ref={secondRef} onLeftFocus={() => minuteRef.current?.focus()} />
      </div> */}
			<div className="flex h-10 items-center">
				<Clock className="ml-2 h-4 w-4" />
			</div>
		</div>
	)
}
