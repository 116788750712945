import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useCreateTitleForMeeting, useUpdateMeeting } from '@/hooks/useMeeting'
import type { MeetingQueryResult } from '@/types/others.types'
import { logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Edit, RefreshCw, Save, X } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocalStorage } from 'usehooks-ts'
import * as yup from 'yup'

import { useToast } from '../../ui/use-toast'
import Button from '../Button'
import CustomLoader from '../CustomLoader'

type Props = {
  meeting: MeetingQueryResult
}

const formSchema = yup.object({
  title: yup.string(),
})

type FormType = yup.InferType<typeof formSchema>

export default function MeetingEditTitle({ meeting }: Props) {
  const mutationUpdateMeeting = useUpdateMeeting()
  const createTitleMutation = useCreateTitleForMeeting()

  const { toast } = useToast()

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: meeting.data?.title ?? '',
    },
  })

  const [editing, setEditing] = useState(false)

  const createTitle = async () => {
    await createTitleMutation.mutateAsync({ id: meeting.data?.id })
  }

  // 2. Define a submit handler.
  const onSubmit = async ({ title }: FormType) => {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    logger('edit title : ', { title })

    await mutationUpdateMeeting.mutateAsync(
      { id: meeting.data?.id, meeting: { title } },
      {
        onSuccess: async (data) => {
          toast({
            title: 'Titre enregistré',
          })
          setEditing(false)
        },
        onError(error) {
          console.error('error : ', error)
          form.setError('root.serverError', {
            message: error?.response?.data?.message ?? error?.message,
          })
          toast({
            title: "Erreur lors de l'enregistrement du titre",
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  if (!meeting.data) {
    return <></>
  }

  return editing ? (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-row gap-2">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="md:w-2/4 w-full">
              <FormControl>
                <Input placeholder="Titre de la réunion" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit" size="icon">
          <Save className="h-4 w-4" />
        </Button>
        <Button type="button" onClick={() => setEditing(false)} variant="outline" size="icon">
          <X className="h-4 w-4" />
        </Button>
      </form>
    </Form>
  ) : (
    <span>
      <span className="hover:cursor-text" onClick={() => setEditing(true)}>
        {meeting.data?.title ?? 'Réunion sans titre'}
      </span>

      <Button variant="ghost" size="icon" className="ml-2" onClick={() => setEditing(true)} disabled={false}>
        <Edit className="h-4 w-4" />
      </Button>

      {(!meeting.data.title || superAdmin) &&
        meeting.data.transcript?.gladiaSummary &&
        (meeting.data.status === 'TRANSCRIPT_READY' || meeting.data.status === 'SUMMARY_READY') && (
          <Button
            variant="ghost"
            size="icon"
            className="ml-2"
            onClick={createTitle}
            loaderSize={4}
            isLoading={createTitleMutation.isPending}
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
        )}
    </span>
  )
}
