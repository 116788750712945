import CustomLoader from '@/components/custom/CustomLoader'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useUpdateMeeting } from '@/hooks/useMeeting'
import { cn } from '@/lib/utils'
import { MeetingLanguage, type MeetingQueryResult } from '@/types/others.types'
import { CircleCheck } from 'lucide-react'

type Props = {
  meeting: MeetingQueryResult
  className?: string
}

export default function ChangeLanguage({ meeting, className }: Props) {
  const updateMeetingMutation = useUpdateMeeting()

  const submit = (value: string) => {
    return updateMeetingMutation.mutate({
      id: meeting.data?.id,
      meeting: {
        language: value,
      },
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex flex-row gap-2 items-center">
        Choix de la langue de transcription
        {updateMeetingMutation.isPending && <CustomLoader size={4} />}
        {updateMeetingMutation.isSuccess && <CircleCheck className="h-4 w-4 text-green-600" />}
      </Label>

      <Select defaultValue={meeting.data?.language?.toString()} onValueChange={submit} disabled={updateMeetingMutation.isPending}>
        <SelectTrigger className={cn('md:w-1/3', className)}>
          <SelectValue placeholder="Choisissez une langue" />
        </SelectTrigger>
        <SelectContent>
          {Object.entries(MeetingLanguage).map(([value, text]) => (
            <SelectItem key={value} value={value}>
              {text}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
