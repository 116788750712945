import Button from '@/components/custom/Button'
import CustomLoader from '@/components/custom/CustomLoader'
import { DataTable } from '@/components/custom/DataTable'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { useFolders } from '@/hooks/useFolder'
import { useAuth } from '@clerk/clerk-react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Rainbow } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'
import { foldersColumns } from './FoldersColumns'

dayjs.extend(isSameOrAfter)

export default function Folders() {
  const { userId } = useAuth()

  const [onlyMine, toggleOnlyMine] = useToggle(true)

  const folders = useFolders({ filters: { user: onlyMine ? userId : null } })

  const modifiedData = folders.data
    ?.map((d) => ({
      ...d,
      isFuture: dayjs(d.createdAt).isAfter(dayjs()),
    }))
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Dossiers</h2>

        <Link to="/folders/new">
          <Button onClick={close}>Nouveau dossier</Button>
        </Link>
      </div>
      <br />

      <div className="flex flex-row justify-end items-center gap-3">
        <Label>Afficher uniquement mes dossiers</Label> <Switch onCheckedChange={toggleOnlyMine} checked={onlyMine} />
      </div>
      <br />
      {folders.isLoading ? (
        <CustomLoader centered />
      ) : modifiedData?.length ? (
        <>
          <DataTable type="folders" columns={foldersColumns} data={modifiedData} />
        </>
      ) : (
        <Alert>
          <Rainbow className="h-4 w-4" />
          <AlertTitle>Créez votre premier dossier</AlertTitle>
          <AlertDescription>
            Vous n'avez encore aucun dossier.
            <br />
            <br />
            <Link to="/folders/new">
              <Button onClick={close}>Nouveau dossier</Button>
            </Link>
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}
