import type { MeetingQueryResult } from '@/types/others.types'
import { TranscriptAudioReady, TranscriptTranscriptionInProgress } from './TranscriptAudioReady'
import TranscriptBot from './TranscriptBot'
import TranscriptError from './TranscriptError'
import TranscriptLiveRecording from './TranscriptLiveRecording'
import TranscriptReady from './TranscriptReady'
import TranscriptUploadFile from './TranscriptUploadFile'

type Props = {
  meeting: MeetingQueryResult
}

export default function TranscriptTab({ meeting }: Props) {
  if (!meeting?.data) {
    return <div>Empty</div>
  }

  return (
    <>
      {meeting.data.status === 'ERROR' && <TranscriptError meeting={meeting} />}
      {meeting.data.status === 'AUDIO_READY' && <TranscriptAudioReady meeting={meeting} />}
      {meeting.data.status === 'TRANSCRIPTION_IN_PROGRESS' && <TranscriptTranscriptionInProgress meeting={meeting} />}
      {['SUMMARY_IN_PROGRESS', 'TRANSCRIPT_READY', 'SUMMARY_READY'].includes(meeting.data.status) && <TranscriptReady meeting={meeting} />}

      {(meeting.data.status === 'CREATED' || meeting.data.status === 'UPLOADING') && meeting.data.type === 'LIVE_RECORDING' && (
        <TranscriptLiveRecording meeting={meeting} />
      )}
      {(meeting.data.status === 'CREATED' || meeting.data.status === 'UPLOADING') && meeting.data.type === 'AUDIO_FILE' && (
        <TranscriptUploadFile meeting={meeting} />
      )}
      {meeting.data.status === 'CREATED' && meeting.data.type === 'BOT' && <TranscriptBot meeting={meeting} />}

      <div className="ml-4" />
    </>
  )
}
