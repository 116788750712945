import { Button } from '@/components/ui/button'
import { Link } from 'react-router-dom'

export default function ButtonCreateTranscript({ close, className }: { close?: () => void; className?: string }) {
	return (
		<Link to="/meetings/new">
			<Button onClick={close} className={className}>
				Nouvelle réunion
			</Button>
		</Link>
	)
}
