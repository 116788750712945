import Button from '@/components/custom/Button'
import { UploadFile } from '@/components/custom/UploadFile'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command'
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { useToast } from '@/components/ui/use-toast'
import { useFiles, useUploadFile } from '@/hooks/useFile'
import { useAddSourceToFolder } from '@/hooks/useFolder'
import { useMeetings } from '@/hooks/useMeeting'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import type { FolderQueryResult } from '@/types/others.types'
import { formatDate, logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { CommandList } from 'cmdk'
import { Check, PlusCircle } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

type Props = {
  folder: FolderQueryResult
}

const formSchema = yup.object({
  sources: yup.array().of(
    yup.object({
      sourceId: yup.string().required(),
      sourceType: yup.string().required(),
    }),
  ),
})

type FormType = yup.InferType<typeof formSchema>

export default function AddSources({ folder }: Props) {
  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {},
  })
  const [progress, setProgress] = useState<number | null>(null)

  const progressCallback = useCallback((progress: number) => setProgress(progress), [])

  const { toast } = useToast()

  const [open, setOpen] = useState(false)

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'sources',
    shouldUnregister: true,
  })

  logger('folder.data.sources : ', folder.data.sources)
  logger('fields : ', fields)
  logger('watch : ', form.watch())

  const meetings = useMeetings()

  const files = useFiles()

  const uploadFile = useUploadFile(progressCallback)

  const addSourcesMutation = useAddSourceToFolder()

  useEffect(() => {
    if (open) {
      form.reset({
        sources: [],
      })
    }
  }, [open, form.reset])

  if (!folder?.data) {
    return <div>Empty</div>
  }

  logger('progress : ', progress)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    logger('files : ', files)

    if (files && files.length > 0) {
      for await (const file of files) {
        logger('file : ', file)
        await uploadFile.mutateAsync(
          { file },
          {
            onSuccess(data, variables, context) {
              logger('data : ', data)
              append({
                sourceId: data.id,
                sourceType: 'FILE',
              })
            },
          },
        )
      }
      event.target.value = null
    }
  }

  const onSubmit = async (data: FormType) => {
    logger('Submitting data: ', data)
    return addSourcesMutation.mutate(
      { folderId: folder.data.id, sources: data.sources },
      {
        async onSuccess(data, variables, context) {
          setOpen(false)
        },
        async onSettled(data, error, variables, context) {
          await queryClient.invalidateQueries({ queryKey: ['folders'] })
          toast({ title: 'Sources ajoutées avec succès' })
        },
      },
    )
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant={'outline'}>Ajouter des sources</Button>
      </SheetTrigger>
      <SheetContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SheetHeader>
              <SheetTitle>Ajouter des sources</SheetTitle>
              <SheetDescription>Choisissez les sources que vous souhaitez ajouter à ce dossier</SheetDescription>
            </SheetHeader>

            <div className="grid gap-4 py-4">
              {fields.map((item, index) => (
                <div key={item.id} className="grid grid-cols-2 items-center gap-4">
                  <FormField
                    control={form.control}
                    name={`sources.${index}.sourceId`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Source ID</FormLabel>
                        <Input {...field} readOnly />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`sources.${index}.sourceType`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Source Type</FormLabel>
                        <Input {...field} readOnly />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}

              <UploadFile buttonText="Ajouter un fichier depuis votre ordinateur" />

              <Separator className="my-1 w-2/3 mx-auto" />

              {/* FILES */}
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    <PlusCircle className="mr-2 h-4 w-4" /> Ajouter un fichier existant
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput placeholder="Rechercher un fichier..." />
                    <CommandList>
                      <CommandEmpty>Aucun fichier trouvé.</CommandEmpty>
                      <CommandGroup>
                        {files.data?.map((file) => {
                          logger('file : ', file)
                          logger('fields : ', fields)

                          return (
                            <CommandItem
                              value={file.id}
                              key={file.id}
                              keywords={[file.extractedName ?? file.name ?? '']}
                              onSelect={() => {
                                append({ sourceId: file.id, sourceType: 'FILE' })
                              }}
                            >
                              {fields.find((f) => f.sourceType === 'FILE' && f.sourceId === file.id)?.id ? (
                                <Check className={cn('mr-2 h-4 w-4', 'opacity-100')} />
                              ) : (
                                ''
                              )}
                              {file.extractedName ?? file.name ?? 'Fichier sans titre'}
                            </CommandItem>
                          )
                        })}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              {/* TRANSCRIPTs */}
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    <PlusCircle className="mr-2 h-4 w-4" /> Ajouter un transcript
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput placeholder="Rechercher un transcript..." />
                    <CommandList>
                      <CommandEmpty>Aucun transcript trouvé.</CommandEmpty>
                      <CommandGroup>
                        {meetings.data
                          ?.filter((meeting) => meeting.transcript?.id)
                          ?.map((meeting) => {
                            return (
                              <CommandItem
                                value={meeting.transcript?.id}
                                key={meeting.transcript?.id}
                                onSelect={() => {
                                  append({ sourceId: meeting.transcript?.id, sourceType: 'TRANSCRIPT' })
                                }}
                              >
                                {fields.find((f) => f.sourceType === 'TRANSCRIPT' && f.sourceId === meeting.transcript?.id)?.id ? (
                                  <Check className={cn('mr-2 h-4 w-4', 'opacity-100')} />
                                ) : (
                                  ''
                                )}
                                {meeting.title || 'Transcript sans titre'}
                              </CommandItem>
                            )
                          })}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              {/* SUMMARIES */}
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    <PlusCircle className="mr-2 h-4 w-4" /> Ajouter un compte rendu
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput placeholder="Search summary..." />
                    <CommandList className="">
                      <CommandEmpty>Aucun compte rendu trouvé.</CommandEmpty>

                      <ScrollArea className="max-h-[200px] w-full rounded-md border">
                        {meetings.data
                          ?.filter((m) => !!m.summary.length)
                          .map((m) => (
                            <CommandGroup heading={m.title || 'Réunion sans titre'} key={m.id}>
                              {m.summary.map((summary, i) => (
                                <CommandItem
                                  value={summary.id}
                                  key={summary.id}
                                  onSelect={() => {
                                    append({ sourceId: summary.id, sourceType: 'SUMMARY' })
                                  }}
                                  keywords={[m.title || '']}
                                >
                                  {fields.find((f) => f.sourceType === 'SUMMARY' && f.sourceId === summary.id)?.id ? (
                                    <Check className={cn('mr-2 h-4 w-4', 'opacity-100')} />
                                  ) : (
                                    ''
                                  )}{' '}
                                  Version {m.summary.length - i} - {formatDate(summary.createdAt, 'DD MMM HH[:]mm')}
                                </CommandItem>
                              ))}{' '}
                            </CommandGroup>
                          ))}
                      </ScrollArea>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <SheetFooter>
              <Button isLoading={addSourcesMutation.isPending} disabled={uploadFile.isPending} type="submit">
                Ajouter
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
