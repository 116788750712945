import Button from '@/components/custom/Button'
import CodeEditor from '@/components/custom/CodeEditor'
import CustomLoader from '@/components/custom/CustomLoader'
import SelectIemModelList from '@/components/custom/SelectIemModelList'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Slider } from '@/components/ui/slider'
import { Switch } from '@/components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import { useCreatePrompt, useLinkOrUnlinkPrompts, usePrompt, usePrompts, useUpdatePrompt } from '@/hooks/usePrompt'
import { useAsOrg, useOrg, useUser } from '@/hooks/useUser'
import type { Prisma } from '@/types'
import { type PossibleError, type PromptQueryResult, PromptShowFor } from '@/types/others.types'
import { classNames, formatDate, isAdmin, logger } from '@/utils'
import { useAuth, useUser as useClerkUser } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Link as LinkIcon, Unlink } from 'lucide-react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import EditSpecificPrompt from './EditSpecificPrompt'

const formSchema = yup.object({
  publicValue: yup.boolean(),
  reuseTranscript: yup.boolean().optional(),
  allowFreeText: yup.boolean().optional(),
  name: yup.string().min(1).max(200),
  description: yup.string().min(1),
  type: yup.string(),
  prompt: yup.string(),
  secondPrompt: yup.string().optional(),
  system: yup.string().when('type', {
    is: 'PROMPT',
    // biome-ignore lint/suspicious/noThenProperty: <explanation>
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  temperature: yup
    .number()
    .min(0)
    .max(2)
    .when('type', {
      is: 'PROMPT',
      // biome-ignore lint/suspicious/noThenProperty: <explanation>
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  model: yup.string().when('type', {
    is: 'PROMPT',
    // biome-ignore lint/suspicious/noThenProperty: <explanation>
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  showFor: yup
    .array()
    .of(yup.string().oneOf(Object.values(PromptShowFor)))
    .required(),
})

type FormType = yup.InferType<typeof formSchema>

export default function PromptPage() {
  const { id } = useParams()

  const prompt = usePrompt(id)

  if (prompt.isLoading) {
    return <CustomLoader />
  }

  if (!id || !prompt.data) return <></>

  return <Prompt key={id} prompt={prompt} />
}

export function Prompt({ prompt }: { prompt?: PromptQueryResult }) {
  const { orgId } = useAuth()
  const { user: userClerk } = useClerkUser()

  const user = useUser()

  const { asOrg } = useAsOrg()

  const organisation = useOrg()

  const existingPrompt = prompt?.data

  const edit = !!existingPrompt

  const magicPromptsOrPrompts = usePrompts({ type: existingPrompt?.type === 'PROMPT' ? 'MAGIC_PROMPT' : 'PROMPT' })

  const linkOrUnlink = useLinkOrUnlinkPrompts()

  const navigate = useNavigate()

  const { toast } = useToast()
  const createPrompt = useCreatePrompt()
  const editPrompt = useUpdatePrompt()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: existingPrompt?.type ?? undefined,

      name: existingPrompt?.name,
      description: existingPrompt?.description ?? undefined,

      system: existingPrompt?.system ?? undefined,
      prompt: existingPrompt?.prompt,
      secondPrompt: existingPrompt?.secondPrompt ?? undefined,

      temperature: existingPrompt?.temperature ?? undefined,
      model: existingPrompt?.model ?? undefined,

      publicValue: existingPrompt?.public ?? false,
      reuseTranscript: existingPrompt?.reuseTranscript ?? false,
      allowFreeText: existingPrompt?.allowFreeText ?? false,

      showFor: existingPrompt?.showFor ?? [],
    },
  })

  logger('form.watch : ', form.watch())

  const date = useRef(dayjs().format('ddd DD MMM YYYY [à] HH[h]mm'))

  const allVariables = {
    date: date.current,
    user: {
      fullName: user.data?.fullName,
      ...user.data?.variables,
    },
    org: {
      name: organisation.data?.name,
      ...organisation.data?.variables,
    },
  }

  const onSubmit = async (formData: FormType) => {
    logger(formData)

    const { publicValue, type, ...restFormData } = formData

    const data: Prisma.PromptCreateInput = {
      ...restFormData,
      ...(edit ? {} : { public: publicValue, type }),
    }

    if (data.public && !isAdmin(userClerk)) {
      return form.setError('root', { message: 'Vous ne pouvez pas créer de prompt public' })
    }

    if (existingPrompt?.id) {
      return editPrompt.mutateAsync(
        { id: existingPrompt.id, prompt: data },
        {
          onSuccess() {
            toast({
              title: 'Prompt modifié',
            })
          },
          onError(error: PossibleError) {
            if (error?.response?.data && Array.isArray(error.response.data)) {
              for (const err of error.response.data) {
                if (err.path) {
                  // Remove the leading slash from the path and replace it with an empty string to match the form field names
                  const fieldName = err.path.replace(/^\//, '')
                  form.setError(fieldName as keyof FormType, {
                    message: err.message,
                  })
                }
              }
            }
            toast({
              title: 'Erreur lors de la modification du prompt',
              description: error?.message,
              variant: 'destructive',
            })
          },
        },
      )
    }
    return createPrompt.mutateAsync(data, {
      onSuccess(data) {
        toast({
          title: 'Prompt créé',
        })
        navigate(`/prompts/${data.id}`)
      },
      onError(error: PossibleError) {
        if (error?.response?.data && Array.isArray(error.response.data)) {
          for (const err of error.response.data) {
            if (err.path) {
              // Remove the leading slash from the path and replace it with an empty string to match the form field names
              const fieldName = err.path.replace(/^\//, '')
              form.setError(fieldName as keyof FormType, {
                message: err.message,
              })
            }
          }
        }
        toast({
          title: 'Erreur lors de la création du prompt',
          description: error?.message,
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/prompts">Prompts</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>{existingPrompt?.name ?? 'Prompt sans titre'}</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <Tabs defaultValue="prompt" className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="prompt">{existingPrompt?.type === 'PROMPT' ? 'Prompt' : 'Magic Prompt'}</TabsTrigger>
          <TabsTrigger disabled={!edit} value="specific-instruction">
            Instructions spécifique
          </TabsTrigger>
          <TabsTrigger disabled={!edit} value="linked-prompts">
            Prompt liés
          </TabsTrigger>
        </TabsList>
        <TabsContent value="prompt">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className="flex items-center space-x-2">
                <div className="grid flex-1 gap-2">
                  <div className="flex items-center gap-x-3">
                    <h2 className="text-2xl">{existingPrompt ? existingPrompt?.name : 'Nouveau Prompt'} </h2>
                    <div
                      className={classNames(
                        'text-gray-700 bg-gray-50 ring-gray-600/20',
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {existingPrompt?.type}
                    </div>

                    {existingPrompt?.public ? (
                      <div
                        className={classNames(
                          'text-green-700 bg-green-50 ring-green-600/20',
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        Public
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          'text-orange-700 bg-orange-50 ring-orange-600/20',
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        Privé
                      </div>
                    )}
                  </div>
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Type</FormLabel>

                        <Select disabled={edit} onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Type" />
                            </SelectTrigger>
                          </FormControl>

                          <SelectContent>
                            <SelectItem value="PROMPT">Prompt</SelectItem>
                            <SelectItem value="MAGIC_PROMPT">Magic Prompt</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {form.getValues('type') && (
                    <>
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Nom</FormLabel>
                            <FormControl>
                              <Input disabled={edit && existingPrompt.public && !isAdmin(userClerk)} placeholder="" {...field} />
                            </FormControl>
                            <FormDescription>Nom du prompt - Visible sur l'interface</FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Description</FormLabel>
                            <FormControl>
                              <Input disabled={edit && existingPrompt.public && !isAdmin(userClerk)} placeholder="" {...field} />
                            </FormControl>
                            <FormDescription>Description du prompt - Visible sur l'interface</FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      {form.getValues('type') === 'PROMPT' && (
                        <FormField
                          control={form.control}
                          name="system"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>System</FormLabel>
                              <FormControl>
                                <CodeEditor
                                  disabled={edit && existingPrompt.public && !isAdmin(userClerk)}
                                  variables={allVariables}
                                  content={field.value}
                                  onChange={field.onChange}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="prompt"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Prompt</FormLabel>
                            <FormControl>
                              <CodeEditor
                                disabled={edit && existingPrompt.public && !isAdmin(userClerk)}
                                variables={allVariables}
                                content={field.value}
                                onChange={field.onChange}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {form.getValues('type') === 'PROMPT' && (
                        <FormField
                          control={form.control}
                          name="secondPrompt"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>2ème Prompt</FormLabel>
                              <FormControl>
                                <CodeEditor
                                  disabled={edit && existingPrompt.public && !isAdmin(userClerk)}
                                  variables={allVariables}
                                  content={field.value}
                                  onChange={field.onChange}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      {form.getValues('type') === 'PROMPT' && (
                        <FormField
                          control={form.control}
                          name="temperature"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Température</FormLabel>
                              <FormControl>
                                <Slider
                                  disabled={edit && existingPrompt.public && !isAdmin(userClerk)}
                                  id="temperature"
                                  value={[field.value]}
                                  onValueChange={(v) => field.onChange(v[0])}
                                  defaultValue={[1]}
                                  min={0}
                                  max={2}
                                  step={0.05}
                                />
                              </FormControl>
                              <FormDescription>{form.watch('temperature')}</FormDescription>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="model"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Modèle</FormLabel>

                            <Select
                              disabled={edit && existingPrompt.public && !isAdmin(userClerk)}
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="Model" />
                                </SelectTrigger>
                              </FormControl>

                              <SelectIemModelList />
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="showFor"
                        render={() => (
                          <FormItem>
                            <FormLabel>Visible sur</FormLabel>
                            <div className="flex flex-col space-y-2">
                              <FormField
                                control={form.control}
                                name="showFor"
                                render={({ field }) => (
                                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                    <FormControl>
                                      <Checkbox
                                        checked={field.value?.includes(PromptShowFor.MEETING)}
                                        onCheckedChange={(checked) => {
                                          const updatedValue = checked
                                            ? [...(field.value || []), PromptShowFor.MEETING]
                                            : field.value?.filter((value: string | undefined) => value !== PromptShowFor.MEETING) || []
                                          field.onChange(updatedValue)
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="font-normal">Réunion</FormLabel>
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={form.control}
                                name="showFor"
                                render={({ field }) => (
                                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                    <FormControl>
                                      <Checkbox
                                        checked={field.value?.includes(PromptShowFor.FOLDER)}
                                        onCheckedChange={(checked) => {
                                          const updatedValue = checked
                                            ? [...(field.value || []), PromptShowFor.FOLDER]
                                            : field.value?.filter((value: string | undefined) => value !== PromptShowFor.FOLDER) || []
                                          field.onChange(updatedValue)
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="font-normal">Dossier</FormLabel>
                                  </FormItem>
                                )}
                              />
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      {form.getValues('type') === 'MAGIC_PROMPT' && (
                        <>
                          <FormField
                            control={form.control}
                            name="reuseTranscript"
                            render={({ field }) => (
                              <FormItem className="flex items-center space-x-2 ">
                                <FormLabel className="mt-2" htmlFor="reuseTranscript">
                                  Réutiliser le transcript
                                </FormLabel>

                                <FormControl>
                                  <Switch id="reuseTranscript" checked={field.value} onCheckedChange={field.onChange} />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="allowFreeText"
                            render={({ field }) => (
                              <FormItem className="flex items-center space-x-2 ">
                                <FormLabel className="mt-2" htmlFor="allowFreeText">
                                  Texte libre
                                </FormLabel>

                                <FormControl>
                                  <Switch id="allowFreeText" checked={field.value} onCheckedChange={field.onChange} />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </>
                      )}
                      <FormField
                        control={form.control}
                        name="publicValue"
                        render={({ field }) => (
                          <FormItem className="flex items-center space-x-2 ">
                            <FormLabel className="mt-2" htmlFor="publicValue">
                              Public
                            </FormLabel>

                            <FormControl>
                              <Switch
                                id="publicValue"
                                disabled={!isAdmin(userClerk) || !!existingPrompt?.id}
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {!form.getValues('publicValue') && (
                        <div className="">
                          <br />
                          <FormDescription>Ce prompt sera lié à l'organisation {asOrg ?? orgId}</FormDescription>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="justify-end">
                <Button
                  type="submit"
                  onClick={form.handleSubmit(onSubmit)}
                  className="px-3"
                  disabled={form.formState.isSubmitting || (edit && existingPrompt.public && !isAdmin(userClerk))}
                >
                  {form.formState.isSubmitting ? <CustomLoader /> : existingPrompt ? 'Sauvegarder' : 'Créer'}
                </Button>
              </div>
            </form>
          </Form>
        </TabsContent>
        <TabsContent value="specific-instruction">
          {existingPrompt?.promptAssignements?.length ? (
            <>
              <h2 className="text-2xl">Instructions spécifique</h2>

              {existingPrompt.promptAssignements.map((p) => (
                <EditSpecificPrompt promptAssignement={p} variables={allVariables} key={p.id} />
              ))}
            </>
          ) : (
            <div>Ce prompt n'est pas lié à l'organisation.</div>
          )}
        </TabsContent>
        <TabsContent value="linked-prompts">
          <h2 className="text-2xl">{existingPrompt?.type === 'PROMPT' ? 'Magic Prompt' : 'Prompt'} liés</h2>

          <ul className="divide-y divide-gray-100">
            {magicPromptsOrPrompts.data
              ?.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
              ?.map((magicPromptOrPrompt) => (
                <li key={magicPromptOrPrompt.id} className="relative flex justify-between gap-x-6 py-5">
                  <div className="flex gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{magicPromptOrPrompt.name}</p>
                        <div
                          className={classNames(
                            'text-gray-700 bg-gray-50 ring-gray-600/20',
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                          )}
                        >
                          {magicPromptOrPrompt.type}
                        </div>
                        {magicPromptOrPrompt.public && (
                          <div
                            className={classNames(
                              'text-green-700 bg-green-50 ring-green-600/20',
                              'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                            )}
                          >
                            Public
                          </div>
                        )}
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Créé le{' '}
                          <time dateTime={formatDate(magicPromptOrPrompt?.createdAt)}>{formatDate(magicPromptOrPrompt?.createdAt)}</time>
                        </p>
                      </div>
                      {!!magicPromptOrPrompt?.updatedAt && (
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <p className="whitespace-nowrap">
                            Mis à jour le{' '}
                            <time dateTime={formatDate(magicPromptOrPrompt?.updatedAt)}>{formatDate(magicPromptOrPrompt?.updatedAt)}</time>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-x-4">
                    {!!existingPrompt?.id && (
                      <Button
                        variant={
                          existingPrompt?.[existingPrompt.type === 'PROMPT' ? 'childrenPrompts' : 'parentPrompts'].find(
                            (p) => p.id === magicPromptOrPrompt.id,
                          )?.id
                            ? 'destructive'
                            : 'default'
                        }
                        isLoading={linkOrUnlink.variables?.promptId === magicPromptOrPrompt.id && linkOrUnlink.isPending}
                        onClick={() =>
                          linkOrUnlink.mutate({
                            action: existingPrompt?.[existingPrompt.type === 'PROMPT' ? 'childrenPrompts' : 'parentPrompts'].find(
                              (p) => p.id === magicPromptOrPrompt.id,
                            )?.id
                              ? 'UNLINK'
                              : 'LINK',
                            promptId: existingPrompt.id,
                            childrenPromptId: existingPrompt.type === 'PROMPT' ? magicPromptOrPrompt.id : undefined,
                            parentPromptId: existingPrompt.type === 'MAGIC_PROMPT' ? magicPromptOrPrompt.id : undefined,
                          })
                        }
                      >
                        {(existingPrompt?.type === 'PROMPT' ? existingPrompt.childrenPrompts : existingPrompt.parentPrompts)?.find(
                          (p) => p.id === magicPromptOrPrompt.id,
                        )?.id ? (
                          <>
                            <Unlink className="h-4 w-4 mr-2" /> Délié
                          </>
                        ) : (
                          <>
                            <LinkIcon className="h-4 w-4 mr-2" /> Lié
                          </>
                        )}
                      </Button>
                    )}

                    {/* {isAdmin(user) && (
						<Link to={`/prompts/${prompt.id}`}>
							<Button variant="outline">Ouvrir</Button>
						</Link>
					)}
					{prompt.type === 'PROMPT' &&
						(myPrompts.data?.find((p) => p.promptId === prompt.id) ? (
							<Button disabled>
								<Check className="h-4 w-4 mr-2" /> Ajouté à mes prompts
							</Button>
						) : (
							<Button
								isLoading={addToMyPrompts.variables?.promptId === prompt.id && addToMyPrompts.isPending}
								onClick={() => addToMyPrompts.mutate({ promptId: prompt.id, orgId: auth.orgId })}
							>
								Ajouter à mes prompts
							</Button>
						))}
					{isAdmin(user) && <DeletePromptButton id={prompt.id} />} */}
                  </div>
                </li>
              ))}
          </ul>
        </TabsContent>
      </Tabs>
    </div>
  )
}
