import CustomLoader from '@/components/custom/CustomLoader'
import { DataTable } from '@/components/custom/DataTable'
import { DataTableFacetedFilter } from '@/components/custom/Filters'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import ButtonCreateMeeting from '@/containers/meetings/ButtonCreateMeeting'
import { useMeetings } from '@/hooks/useMeeting'
import { useUser } from '@/hooks/useUser'
import { getMeetingStatusOptions, getMeetingTypeOptions } from '@/utils'
import { useOrganization } from '@clerk/clerk-react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Rainbow } from 'lucide-react'
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { columns } from './MeetingsColumns'

dayjs.extend(isSameOrAfter)

const CommaArrayParam = {
  encode: (array: string[] | null | undefined) => encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) => decodeDelimitedArray(arrayStr, ','),
}

const PAST = ['TRANSCRIPTION_IN_PROGRESS', 'TRANSCRIPT_READY', 'SUMMARY_READY', 'ERROR']
const FUTUR = ['AUDIO_READY', 'CREATED']

export default function Meetings() {
  const { organization } = useOrganization()
  const user = useUser()

  const [onlyMine, toggleOnlyMine] = useQueryParam('onlyMine', withDefault(BooleanParam, true))
  const [status, setStatus] = useQueryParam('status', CommaArrayParam)
  const [type, setType] = useQueryParam('type', ArrayParam)
  const [showArchived, setShowArchived] = useQueryParam('showArchived', withDefault(BooleanParam, false))

  const [, setUrlPagination] = useQueryParams({
    pageIndex: NumberParam,
    pageSize: NumberParam,
  })

  const meetings = useMeetings({ filters: { onlyMine, status, type, isArchived: showArchived } })

  const resetPagination = () => {
    setUrlPagination({
      pageIndex: 0,
      pageSize: 10,
    })
  }

  const modifiedData = meetings.data
    ?.map((d) => ({
      ...d,
      createdAtOrJoinAt: d.type === 'BOT' ? d.bot?.joinAt : d.createdAt,
      isFuture: dayjs(d.bot?.joinAt).isAfter(dayjs()),
    }))
    .sort((a, b) => new Date(b.createdAtOrJoinAt).getTime() - new Date(a.createdAtOrJoinAt).getTime())

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Réunions</h2>
      </div>
      <br />

      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row gap-2">
          <Tabs
            defaultValue="mine"
            value={onlyMine ? 'mine' : 'all'}
            onValueChange={(value) => {
              resetPagination()
              value === 'mine' ? toggleOnlyMine(true) : toggleOnlyMine(false)
            }}
          >
            <TabsList className="h-8">
              <TabsTrigger value="all" className="h-6">
                {organization?.name}
              </TabsTrigger>
              <TabsTrigger value="mine" className="h-6">
                {user.data?.firstName ? `${user.data?.firstName} ${user.data?.lastName}` : user.data?.email}
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Tabs
            defaultValue="all"
            value={PAST.join(',') === status?.join(',') ? 'past' : FUTUR.join(',') === status?.join(',') ? 'futur' : 'all'}
            onValueChange={(value) => {
              resetPagination()
              value === 'futur' ? setStatus(FUTUR) : value === 'past' ? setStatus(PAST) : setStatus([''])
            }}
          >
            <TabsList className="h-8">
              <TabsTrigger value="all" className="h-6">
                Toutes
              </TabsTrigger>
              <TabsTrigger value="futur" className="h-6">
                A venir
              </TabsTrigger>
              <TabsTrigger value="past" className="h-6">
                Passées
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Tabs
            defaultValue="active"
            value={showArchived ? 'archived' : 'active'}
            onValueChange={(value) => {
              resetPagination()
              setShowArchived(value === 'archived')
            }}
          >
            <TabsList className="h-8">
              <TabsTrigger value="active" className="h-6">
                Actives
              </TabsTrigger>
              <TabsTrigger value="archived" className="h-6">
                Archivées
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className="flex flex-row gap-2">
          <DataTableFacetedFilter
            title="Type"
            values={type}
            set={(v) => {
              resetPagination()
              setType(v)
            }}
            options={getMeetingTypeOptions()}
          />

          <DataTableFacetedFilter
            title="Statut"
            values={status}
            set={(v) => {
              resetPagination()
              setStatus(v)
            }}
            options={getMeetingStatusOptions()}
          />
        </div>
      </div>
      <br />
      {meetings.isLoading ? (
        <CustomLoader centered />
      ) : modifiedData?.length ? (
        <>
          <DataTable type="meetings" columns={columns} data={modifiedData} />
        </>
      ) : (
        <Alert>
          <Rainbow className="h-4 w-4" />
          <AlertTitle>Créez votre première réunion</AlertTitle>
          <AlertDescription>
            Vous n'avez encore aucune réunion{showArchived ? ' archivée' : ''}.
            <br />
            <br />
            <ButtonCreateMeeting />
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}
