import { SelectContent, SelectGroup, SelectItem, SelectLabel } from '../ui/select'

export default function SelectIemModelList() {
  return (
    <SelectContent className="overflow-y-auto max-h-[400px]">
      <SelectGroup>
        <SelectLabel>Bedrock</SelectLabel>
        <SelectItem value="bedrock/anthropic.claude-3-opus-20240229-v1:0">bedrock/anthropic.claude-3-opus-20240229-v1:0</SelectItem>
        <SelectItem value="bedrock/anthropic.claude-3-sonnet-20240229-v1:0">bedrock/anthropic.claude-3-sonnet-20240229-v1:0</SelectItem>
        <SelectItem value="bedrock/cohere/command-r-plus-v1:0">bedrock/cohere/command-r-plus-v1:0</SelectItem>
      </SelectGroup>
      <SelectGroup>
        <SelectLabel>Anthropic</SelectLabel>
        <SelectItem value="anthropic/claude-3-5-sonnet-20240620">anthropic/claude-3-5-sonnet-20240620</SelectItem>
        <SelectItem value="claude-3-opus-20240229">claude-3-opus-20240229</SelectItem>
        <SelectItem value="claude-3-sonnet-20240229">claude-3-sonnet-20240229</SelectItem>
        <SelectItem value="claude-2.1">claude-2.1</SelectItem>
      </SelectGroup>

      <SelectGroup>
        <SelectLabel>Mistral</SelectLabel>
        <SelectItem value="mistral-large-latest">mistral-large-latest</SelectItem>
        <SelectItem value="mistral-medium-latest">mistral-medium-latest</SelectItem>
        <SelectItem value="mistral-small-latest">mistral-small-latest</SelectItem>
      </SelectGroup>

      <SelectGroup>
        <SelectLabel>OpenAI</SelectLabel>
        <SelectItem value="openai/gpt-4o-2024-08-06">gpt-4o-2024-08-06</SelectItem>
        <SelectItem value="openai/gpt-4o">gpt-4o</SelectItem>
        <SelectItem value="gpt-4-turbo">gpt-4-turbo</SelectItem>
        <SelectItem value="gpt-3.5-turbo">gpt-3.5-turbo</SelectItem>
        <SelectItem value="gpt-4">gpt-4</SelectItem>
      </SelectGroup>

      <SelectGroup>
        <SelectLabel>Google (Vertex-AI)</SelectLabel>
        <SelectItem value="vertex_ai/claude-3-5-sonnet@20240620">claude-3-5-sonnet@20240620</SelectItem>
        <SelectItem value="vertex_ai/gemini-1.5-pro-preview-0514">gemini-1.5-pro-preview-0514</SelectItem>
        <SelectItem value="vertex_ai/gemini-1.5-flash-preview-0514">gemini-1.5-flash-preview-0514</SelectItem>
      </SelectGroup>
    </SelectContent>
  )
}
