import type { MeetingPayload, SourcePayload } from '@/types/others.types'
import { MeetingStatus, MeetingType } from '@/types/others.types'
import type { UserResource } from '@clerk/types'
import dayjs from 'dayjs'
import debug from 'debug'
import { v4 as uuidv4 } from 'uuid'
import { translateMeetingStatus, translateMeetingType } from './translate'

export const API_URL = import.meta.env.VITE_API_URL

export const BAD_CONFIDENCE_THRESHOLD = 0.55
export const AVERAGE_CONFIDENCE_THRESHOLD = 0.7

const createLogger = (domain: string): debug.Debugger => {
  return debug(`${domain}`)
}

export const logger = createLogger('notae')

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function getFileName(meetingId: string, fileExtension?: string) {
  return `${meetingId}-${uuidv4()}.${fileExtension}`
}

export function getFileExtension(mimeType: string) {
  return mimeType.split('/')[1]?.split(';')[0]
}

export function formatDate(date?: string | Date | null, format = 'ddd DD MMM YYYY [à] HH[h]mm') {
  if (!date) {
    return undefined
  }
  return dayjs(date).format(format)
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const getMeetingStatusOptions = () => {
  return Object.values(MeetingStatus).map((status) => ({
    label: translateMeetingStatus(status),
    value: status,
  }))
}
export const getMeetingTypeOptions = () => {
  return Object.values(MeetingType).map((type) => ({
    label: translateMeetingType(type),
    value: type,
  }))
}

export const displayDateOfMeeting = (meeting: MeetingPayload) => {
  return formatDate(meeting.type === 'BOT' ? meeting.bot?.joinAt : meeting.createdAt)
}

export const formatMillisec = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60)
  const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24)

  return [hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(':')
}

export const durationAsMinutes = (secondes: number, append?: string) => {
  const value = Number.parseInt((secondes / 60).toFixed(0), 10)

  return `${value === 0 ? '< 1' : value} ${append ? append : ''}`
}

export const displayParticipants = (nbParticipant: number) => {
  return `${nbParticipant} participant${nbParticipant > 1 ? 's' : ''}`
}

export const umami = window.umami

export const track = (...args: string[]) => umami?.track(...args)

export const isAdmin = (user: UserResource | null | undefined) => {
  return user?.publicMetadata?.isAdmin ?? false
}

export const getSourceName = (source: SourcePayload) => {
  if (source.sourceType === 'SUMMARY') {
    return source.summary?.title ?? source.summary?.meeting?.title
  }
  if (source.sourceType === 'TRANSCRIPT') {
    return source.transcript?.meeting?.title
  }
  if (source.sourceType === 'FILE') {
    return source.file?.extractedName ?? source.file?.name ?? 'Fichier sans titre'
  }
}
