import CustomLoader from '@/components/custom/CustomLoader'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useAudioDevices } from '@/hooks/useMicPermission'
import { recording } from '@/lib/states'
import { logger } from '@/utils'
import { useAtom } from 'jotai'
import { Dispatch, SetStateAction, useEffect } from 'react'

type Props = {
	deviceId: string | undefined
	setDeviceId: Dispatch<SetStateAction<string | undefined>>
}

export default function ChooseAudioSource({ deviceId, setDeviceId }: Props) {
	const [isRecording] = useAtom(recording)

	const devices = useAudioDevices()

	useEffect(() => {
		if (devices.data && !deviceId) {
			logger('Setting default device...')
			setDeviceId(devices.data?.[0].deviceId)
		}
	}, [deviceId, devices, setDeviceId])

	return devices.isLoading ? (
		<CustomLoader />
	) : (
		<div className="flex flex-col gap-2 w-full">
			<Label className="">Périphérique audio</Label>

			<Select
				disabled={isRecording}
				onValueChange={(v) => {
					logger('setDeviceId: ', v)
					setDeviceId(v)
				}}
				defaultValue={deviceId || devices.data?.[0].deviceId}
			>
				<SelectTrigger className="w-full">
					<SelectValue placeholder="Audio" />
				</SelectTrigger>
				<SelectContent>
					{devices.data?.map((d) => {
						return (
							<SelectItem key={d.deviceId} value={d.deviceId}>
								{d.label}
							</SelectItem>
						)
					})}
				</SelectContent>
			</Select>
		</div>
	)
}
