import CustomLoader from '@/components/custom/CustomLoader'
import { useAnalyzeFile, useDeleteFile, useFile } from '@/hooks/useFile'
import { cn } from '@/lib/utils'
import 'dayjs/locale/fr'
import Button from '@/components/custom/Button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Badge } from '@/components/ui/badge'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import type { FileQueryResult } from '@/types/others.types'
import { ExternalLink, MoreHorizontal, Trash } from 'lucide-react'
import { useState } from 'react'
import Markdown from 'react-markdown'
import { Link, useNavigate, useParams } from 'react-router-dom'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

function File({ id }: { id: string }) {
  const file = useFile(id)
  const analyzeFileMutation = useAnalyzeFile()

  const handleAnalyze = () => {
    analyzeFileMutation.mutate({ id })
  }

  if (!id) {
    return <div>id is null</div>
  }
  if (file?.isLoading) {
    return <CustomLoader centered size={14} />
  }
  if (!file?.data) {
    return <div>Empty</div>
  }

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/files">Fichiers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>{file.data?.extractedName ?? file.data?.name ?? 'Fichier sans titre'}</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex justify-between items-center	gap-2">
        <div className="flex flex-col w-3/4">
          <div className="flex flex-row gap-2 items-center">
            <h2 className="text-xl">{file.data?.extractedName ?? file.data?.name ?? 'Fichier sans titre'}</h2>

            <Badge variant="outline">{file.data.status}</Badge>
          </div>
          <p className="text-xs leading-5 text-gray-500">
            Nom original : <b>{file.data.name}</b>
          </p>
          <p className="text-xs leading-5 text-gray-500">
            Output Tokens : {file.data.outputTokens} - Input Tokens : {file.data.inputTokens}
          </p>
        </div>

        <div className="flex gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <ExternalLink className="mr-2 h-4 w-4" />
                <a href={file.data.url} target="_blank" rel="noreferrer">
                  Lien vers le fichier
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleAnalyze}>
                {analyzeFileMutation.isPending ? (
                  <CustomLoader />
                ) : (
                  <>
                    <span>Analyser le fichier</span>
                  </>
                )}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DeleteFileButton file={file} />
        </div>
      </div>

      <p className="text-xs leading-5 text-gray-500">
        <b>Description</b> : {file.data.extractedDescription}
      </p>
      <article
        className={cn(
          'prose-base',
          'prose-table:table-auto',
          'prose-table:border-slate-400 prose-table:border prose-table:border-collapse prose-table:border-spacing-2',
          'prose-thead:bg-slate-50',
          'prose-th:border prose-th:border-slate-300',
          'prose-td:border prose-td:border-slate-300 prose-td:px-2',
          'prose-ul:list-disc',
          'mb-5 mt-5',
        )}
        id="content-text"
      >
        <Markdown remarkPlugins={[remarkBreaks, remarkGfm]}>{file.data.extractedContent}</Markdown>
      </article>
    </div>
  )
}

export default function FilePage() {
  const { id } = useParams()
  if (!id) return <></>
  return <File key={id} id={id} />
}

type Props = {
  file: FileQueryResult
}

function DeleteFileButton({ file }: Props) {
  const deleteFileMutation = useDeleteFile()

  const [open, setOpen] = useState(false)

  const navigate = useNavigate()
  const { toast } = useToast()

  const deleteFile = async () => {
    return deleteFileMutation.mutate(
      { id: file.data.id },
      {
        onSuccess() {
          setOpen(false)
          navigate('/files')

          toast({
            title: 'Fichier supprimé',
            variant: 'default',
          })
        },
        onError(error) {
          toast({
            title: 'Erreur lors de la suppression du fichier',
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">
          <Trash className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>Ce fichier sera définitivement supprimé.</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deleteFileMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={(e) => {
                e.preventDefault()
                deleteFile()
              }}
              isLoading={deleteFileMutation.isPending}
            >
              Oui. Supprimer !
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
