import { useQuery } from '@tanstack/react-query'

export const useMicPermission = (deviceId?: string) => {
  return useQuery({
    queryKey: ['permissions-api', deviceId],
    networkMode: 'always',
    queryFn: async () => {
      try {
        const params: MediaStreamConstraints = {
          audio: true,
        }
        if (deviceId) {
          params.audio = { deviceId: { exact: deviceId } }
        }
        await navigator.mediaDevices.getUserMedia(params)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    enabled: true,
    staleTime: Number.POSITIVE_INFINITY,
    retry: 0,
  })
}

export const useAudioDevices = () => {
  return useQuery({
    queryKey: ['audio-devices'],
    networkMode: 'always',
    queryFn: async () => {
      try {
        const devices = await navigator.mediaDevices?.enumerateDevices()
        const audioInputDevices = devices.filter((device) => device.kind === 'audioinput')
        return audioInputDevices
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    enabled: true,
    staleTime: Number.POSITIVE_INFINITY,
    retry: 0,
  })
}
