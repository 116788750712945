import Button from '@/components/custom/Button'
import { useToast } from '@/components/ui/use-toast'
import { useUpdatePromptAssignement } from '@/hooks/usePrompt'
import type { PromptAssignementPayload } from '@/types/others.types'
import { logger } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import CodeEditor from '@/components/custom/CodeEditor'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form'

type Props = {
  promptAssignement?: PromptAssignementPayload
  variables?: Record<string, string>
}

const formSchema = z.object({
  specificPrompt: z.string(),
})

export default function EditSpecificPrompt({ promptAssignement, variables }: Props) {
  const [, setOpen] = useState(false)

  const { toast } = useToast()

  const updatePromptAssignement = useUpdatePromptAssignement()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      specificPrompt: promptAssignement?.specificPrompt || '',
    },
  })

  const onSubmit = async ({ specificPrompt }: z.infer<typeof formSchema>) => {
    // Do something with the form values.

    if (!promptAssignement?.id) {
      throw new Error('promptAssignement?.id is undefined')
    }

    logger({ specificPrompt })

    return updatePromptAssignement.mutateAsync(
      { id: promptAssignement?.id, promptAssignement: { specificPrompt } },
      {
        onSuccess() {
          setOpen(false)
          toast({
            title: 'Prompt spécifique modifié',
          })
        },
        onError(error) {
          toast({
            title: 'Erreur lors de la modification du prompt',
            description: error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <FormField
              control={form.control}
              name="specificPrompt"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Specific Prompt</FormLabel>
                  <FormControl>
                    <CodeEditor variables={variables} content={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormDescription>Contenu du prompt spécifique</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="justify-end">
          <Button isLoading={form.formState.isSubmitting} type="submit" onClick={form.handleSubmit(onSubmit)} className="px-3">
            Sauvegarder
          </Button>
        </div>
      </form>
    </Form>
  )
}
