import * as Sentry from '@sentry/react'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  // https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        const context = {
          mutationId: mutation.mutationId,
          mutationKey: mutation.options.mutationKey,
          variables: mutation.state.variables,
        }
        scope.setContext('mutation', context)

        if (mutation.state.variables?.file) {
          const contextFile = {
            size: mutation.state.variables?.file?.size,
            name: mutation.state.variables?.file?.name,
            fileType: mutation.state.variables?.file?.type,
          }
          scope.setContext('file', contextFile)
        }
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          )
        }
        Sentry.captureException(err)
      })
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      Sentry.withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash })
        scope.setFingerprint([query.queryHash?.replaceAll?.(/[0-9]/g, '0')])
        Sentry.captureException(err)
      })
    },
  }),
  defaultOptions: {
    queries: {
      //staleTime: 1000 * 60 * 10,
    },
  },
})
