import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Progress } from '@/components/ui/progress'
import { useToast } from '@/components/ui/use-toast'
import AddContext from '@/containers/others/AddContext'
import AddDefaultPrompt from '@/containers/others/AddDefaultPrompt'
import ChangeLanguage from '@/containers/others/ChangeLanguage'
import { useAbortMultipartUpload, useCompleteMultipartUpload, useGetSignedUrl, useListParts, useSignPart } from '@/hooks/useMeeting'
import { uploading } from '@/lib/states'
import type { MeetingQueryResult } from '@/types/others.types'
import { logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import { useUppyState } from '@uppy/react'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const MAX_FILE_SIZE = 500 * 1024 * 1024 // 500mb

const formSchema = yup.object({
  file: yup
    .mixed<File>()
    .required('Veuillez ajouter un fichier audio.')
    .test('is-file', "Ce n'est pas de type fichier", (value) => {
      if (value instanceof File) {
        return true
      } else {
        return false
      }
    })
    .test('is-valid-size', 'Taille max autorisée : 500mb', (value) => {
      if (value instanceof File) {
        return value && value.size <= MAX_FILE_SIZE
      } else {
        return false
      }
    }),
})

type FormType = yup.InferType<typeof formSchema>

type Props = {
  meeting: MeetingQueryResult
}

export default function TranscriptUploadFile({ meeting }: Props) {
  const getSignedUrlMutation = useGetSignedUrl()
  const signPartMutation = useSignPart()
  const completeMultipartUploadMutation = useCompleteMultipartUpload()
  const listPartsMutation = useListParts()
  const abortMultipartUploadMutation = useAbortMultipartUpload()

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        // allowedFileTypes: ['audio/*', '.mp3', '.m4a', '.mp4', '.webm'],
      },
      debug: true,
    }).use(AwsS3, {
      shouldUseMultipart() {
        // we always use multipart upload.
        return true
      },
      // Not needed because we always use multipart upload.
      getUploadParameters(file, options) {
        async function _getUploadParameters() {
          logger('file : ', file)
          const response = await getSignedUrlMutation.mutateAsync({ id: meeting.data?.id, fileType: file.type, fileExt: file.extension })

          logger('response : ', response)

          return {
            method: 'PUT',
            url: response.signedUrl,
          }
        }

        return _getUploadParameters()
      },
      createMultipartUpload: async (file) => {
        const response = await getSignedUrlMutation.mutateAsync({
          id: meeting.data?.id,
          fileType: file.type,
          fileExt: file.extension,
          multipart: true,
        })
        return { uploadId: response.uploadId, key: response.key }
      },
      signPart: async (file, { uploadId, key, partNumber }) => {
        const response = await signPartMutation.mutateAsync({
          id: meeting.data?.id,
          uploadId,
          key,
          partNumber,
        })
        return { url: response.signedUrl }
      },
      listParts: async (file, { uploadId, key }) => {
        const response = await listPartsMutation.mutateAsync({
          id: meeting.data?.id,
          uploadId,
          key,
        })
        return response.parts
      },
      completeMultipartUpload: async (file, { uploadId, key, parts }) => {
        const response = await completeMultipartUploadMutation.mutateAsync({
          id: meeting.data?.id,
          uploadId,
          key,
          parts,
        })
        return response.location
      },
      abortMultipartUpload: async (file, { uploadId, key }) => {
        try {
          await abortMultipartUploadMutation.mutateAsync({
            id: meeting.data?.id,
            uploadId,
            key,
          })
          console.log('Multipart upload aborted successfully')
        } catch (error) {
          console.error('Failed to abort multipart upload:', error)
          // Même si l'annulation échoue, Uppy continuera le processus d'annulation
        }
      },
    }),
  )

  const [isUploading, setIsUploading] = useAtom(uploading)

  const totalProgress = useUppyState(uppy, (state) => state.totalProgress)

  const { toast } = useToast()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
  })

  const queryClient = useQueryClient()

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const res = await uppy.upload()
      return res
    },
  })

  useEffect(() => {
    setIsUploading(uploadMutation.isPending)
    return () => {
      setIsUploading(false)
    }
  }, [uploadMutation.isPending, setIsUploading])

  useEffect(() => {
    const fileAddedHandler = (file: any) => {
      form.setValue('file', file.data, { shouldValidate: true })
    }

    const fileRemovedHandler = () => {
      form.setValue('file', null, { shouldValidate: true })
    }

    uppy.on('file-added', fileAddedHandler)
    uppy.on('file-removed', fileRemovedHandler)

    return () => {
      uppy.off('file-added', fileAddedHandler)
      uppy.off('file-removed', fileRemovedHandler)
    }
  }, [uppy, form])

  const onSubmit = async ({ file }: FormType) => {
    if (!meeting.data?.id) {
      throw new Error('meeting.data?.id undefined')
    }
    if (file) {
      logger('Uploading file...')
      logger('file : ', file.type)

      try {
        const res = await uploadMutation.mutateAsync(file)
        logger('res : ', res)
        if (res?.successful?.[0]) {
          await queryClient.invalidateQueries({ queryKey: ['meetings', meeting.data?.id] })

          toast({
            title: 'Fichier envoyé avec succès',
            description: 'Le fichier a été uploadé et le transcript va être créé.',
            variant: 'default',
          })
        } else {
          throw new Error(`Upload failed : ${res?.failed?.[0]?.error}`)
        }
      } catch (error) {
        console.error('Upload error:', error)
        toast({
          title: "Erreur lors de l'envoi du fichier",
          description: "Une erreur s'est produite pendant l'upload.",
          variant: 'destructive',
        })
      }
    }
  }

  console.log('uppy.getFiles() : ', uppy.getFiles())

  return (
    <div className="flex flex-col xl:w-2/4 md:w-2/3 sm:w-2/3 w-full self-center gap-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <FormField
            control={form.control}
            name="file"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Fichier audio</FormLabel>
                <FormControl>
                  <Input
                    accept="audio/*,.mp3,.m4a,.mp4,.webm"
                    type="file"
                    disabled={uploadMutation.isPending || form.formState.isSubmitting}
                    onChange={(e) => {
                      const file = e.target.files ? e.target.files[0] : null
                      if (file) {
                        if (uppy.getFiles().length > 0) {
                          uppy.removeFile(uppy.getFiles()[0].id)
                        }

                        uppy.addFile({
                          name: file.name,
                          type: file.type,
                          data: file,
                        })
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {uploadMutation.isPending || form.formState.isSubmitting ? (
            <Progress value={totalProgress} indeterminate={totalProgress === 100} className="w-full" indicatorColor="bg-indigo-600" />
          ) : (
            <Button type="submit" disabled={uppy.getFiles().length === 0}>
              Envoyez le fichier
            </Button>
          )}
        </form>
      </Form>

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} className="md:w-full" />

            <AddDefaultPrompt meeting={meeting} className="md:w-full" />

            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
