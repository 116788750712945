import CustomLoader from '@/components/custom/CustomLoader'
import { logger } from '@/utils'
import { useAuth, useSignIn, useUser } from '@clerk/clerk-react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

export default function Impersonate() {
	const { isLoaded, signIn, setActive } = useSignIn()
	const { signOut } = useAuth()
	const { user } = useUser()

	const navigate = useNavigate()

	const [searchParams] = useSearchParams()

	const [, setImpersonate] = useLocalStorage('impersonate', false)

	const impersonate = searchParams.get('impersonate') === 'true' ? true : false
	const ticket = searchParams.get('__clerk_ticket')

	logger('impersonate: ', impersonate)

	useEffect(() => {
		const action = async () => {
			try {
				if (isLoaded) {
					if (user && impersonate) {
						logger('Already logged. Signing out...')
						await signOut()
					}
					if (ticket) {
						logger('Signing with ticket...')
						const res = await signIn?.create({
							strategy: 'ticket',
							ticket: ticket,
						})

						await setActive({
							session: res?.createdSessionId,
						})

						setImpersonate(impersonate ? true : false)

						logger('Going to /')
						navigate('/')
					}
				}
			} catch (err) {
				logger('impersonate | err : ', err)
				//navigate("/");
			}
		}
		action()
	}, [isLoaded, ticket, impersonate, signOut, user, signIn, setActive, setImpersonate, navigate])

	return <CustomLoader centered />
}
