import CustomLoader from '@/components/custom/CustomLoader'
import { useFolder } from '@/hooks/useFolder'
import 'dayjs/locale/fr'
import { Outlet, useParams } from 'react-router-dom'

function Folder({ id }: { id: string }) {
  const folder = useFolder(id)

  if (!id) {
    return <div>id is null</div>
  }
  if (folder?.isLoading) {
    return <CustomLoader centered size={14} />
  }
  if (!folder?.data) {
    return <div>Empty</div>
  }

  return (
    <div className="flex flex-col gap-4">
      <Outlet />
    </div>
  )
}

export default function FolderPage() {
  const { id } = useParams()
  if (!id) return <></>
  return <Folder key={id} id={id} />
}
