import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useToast } from '@/components/ui/use-toast'
import { useCreateMeeting } from '@/hooks/useMeeting'
import type { PossibleError } from '@/types/others.types'
import { MeetingType } from '@/types/others.types'
import { logger } from '@/utils'
import { Crisp } from 'crisp-sdk-web'
import { FileMusic, Mic, Presentation } from 'lucide-react'
import { useMixpanel } from 'react-mixpanel-browser'
import { useNavigate } from 'react-router-dom'

export default function NewMeeting() {
  const { toast } = useToast()
  const navigate = useNavigate()

  const mixpanel = useMixpanel()

  const createMeetingMutation = useCreateMeeting()

  const create = async (type: MeetingType) => {
    createMeetingMutation.mutate(
      { type },
      {
        onSuccess: async (data) => {
          logger('Meeting created.')

          mixpanel?.track('Create Meeting', {
            type,
          })
          Crisp.session.pushEvent('Create Meeting', {
            type,
          })

          mixpanel?.people.increment('meetings created')

          return navigate(`/meetings/${data.id}`)
        },
        onError(error: PossibleError) {
          logger('onError : ', error)
          toast({
            title: 'Erreur lors de la création de la réunion',
            description: error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Nouvelle réunion</h2>
      </div>
      <br />

      <h3 className="text-base">Comment voulez vous créer votre réunion ?</h3>

      <br />

      <div className="flex md:flex-row flex-col justify-between gap-2">
        <Card className="w-full hover:cursor-pointer hover:bg-accent" onClick={() => create(MeetingType.BOT)}>
          <CardHeader>
            <CardTitle className="text-center text-2xl">Microsoft Teams</CardTitle>
          </CardHeader>
          <CardContent className="">
            <Presentation strokeWidth={1} className="h-1/4 w-1/4 m-auto md:block hidden" />
            <br className="md:block hidden" />
            <p className="text-sm text-center">Programmer une réunion Teams</p>
          </CardContent>
        </Card>
        <Card className="w-full hover:cursor-pointer hover:bg-accent" onClick={() => create(MeetingType.LIVE_RECORDING)}>
          <CardHeader>
            <CardTitle className="text-center text-2xl">Micro</CardTitle>
          </CardHeader>
          <CardContent className="">
            <Mic strokeWidth={1} className="h-1/4 w-1/4 m-auto md:block hidden" />

            <br className="md:block hidden" />
            <p className="text-sm text-center">Enregistrer via le micro</p>
          </CardContent>
        </Card>
        <Card className="w-full hover:cursor-pointer hover:bg-accent" onClick={() => create(MeetingType.AUDIO_FILE)}>
          <CardHeader>
            <CardTitle className="text-center text-2xl">Fichier audio</CardTitle>
          </CardHeader>
          <CardContent className="">
            <FileMusic strokeWidth={1} className="h-1/4 w-1/4 m-auto md:block hidden" />

            <br className="md:block hidden" />
            <p className="text-sm text-center">Ajouter un fichier déjà enregistré</p>
          </CardContent>
        </Card>
      </div>
    </>
  )
}
