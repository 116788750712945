import type { GladiaData } from '@/types/gladia.types'
import type { UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import type { Prisma } from '.'

declare global {
  interface Window {
    umami: any
  }
}

export interface ValidationError {
  message: string
  path: string
  type: string
  found: string
}

// Cette interface est spécifique à la structure de la réponse que vous attendez d'Axios lorsqu'il y a des erreurs de validation.
interface ValidationErrorResponse {
  data: ValidationError[]
}

// Définir un type qui englobe les situations où error pourrait ne pas suivre la structure standard d'une erreur Axios.
export type PossibleError = AxiosError<ValidationErrorResponse> | AxiosError | any

export type LiveTranscription = {
  start: number
  text: string
  is_final: boolean
}

export type WebsocketPayload = {
  action: string
  type: string
  data: {
    type: string
    origin: string
  } & GladiaData
}

export type MeetingsPayload = Prisma.MeetingGetPayload<{
  include: {
    bot: {
      select: {
        id: true
        meetingUrl: true
        name: true
        joinAt: true
        type: true
        status: true
        participants: true
      }
      //include: { status: true },
    }
    summary: {
      orderBy: {
        createdAt: 'desc'
      }
      select: {
        id: true
        title: true
        createdAt: true
      }
    }
    transcript: {
      select: {
        id: true
        nbSpeakers: true
        duration: true
      }
    }
    user: {
      select: {
        email: true
        firstName: true
        lastName: true
      }
    }
  }
}>[]

export type MeetingPayload = Prisma.MeetingGetPayload<{
  include: {
    bot: {
      include: {
        status: true
      }
    }
    transcript: {
      include: {
        transcriptLine: true
      }
    }
    summary: {
      select: {
        id: true
        meetingId: true
        followUpPrompt: true
        title: true
        createdAt: true
        content: true
        promptId: true
        observationId: true
        traceId: true
      }
    }
    user: { select: { email: true; firstName: true; lastName: true } }
  }
}>

type SelectPromptPayload = {
  id: true
  type: true
  name: true
  description: true
  public: true
  system: true
  prompt: true
  secondPrompt: true
  model: true
  temperature: true
  reuseTranscript: true
  allowFreeText: true

  createdAt: true
  updatedAt: true

  showFor: true
}

export type SourcePayload = Prisma.SourceGetPayload<{
  include: {
    file: true
    summary: {
      include: {
        meeting: {
          select: {
            title: true
          }
        }
      }
    }
    transcript: {
      include: {
        meeting: {
          select: {
            title: true
          }
        }
      }
    }
  }
}>

export type FolderPayload = Prisma.FolderGetPayload<{
  include: {
    user: true

    sources: {
      include: {
        file: true
        summary: {
          include: {
            meeting: {
              select: {
                title: true
              }
            }
          }
        }
        transcript: {
          include: {
            meeting: {
              select: {
                title: true
              }
            }
          }
        }
      }
    }
  }
}>
export type FoldersPayload = Prisma.FolderGetPayload<{
  include: {
    user: true

    sources: {
      select: {
        id: true
      }
    }
  }
}>[]

export type FilePayload = Prisma.FileGetPayload<{
  include: {
    user: {
      select: {
        firstName: true
        lastName: true
      }
    }
  }
}>
export type SummaryPayload = Prisma.SummaryGetPayload<{
  select: {
    id: true
    meetingId: true
    followUpPrompt: true
    title: true
    createdAt: true
    content: true
    promptId: true
    observationId: true
    traceId: true
  }
}>
export type TranscriptLinePayload = Prisma.TranscriptLineGetPayload<true>

export type PromptPayload = Prisma.PromptGetPayload<{
  select: {
    [K in keyof SelectPromptPayload]: true
  } & {
    categories: true
    promptAssignements: true
    childrenPrompts: {
      select: SelectPromptPayload
    }
    parentPrompts: {
      select: SelectPromptPayload
    }
  }
}>

export type PromptAssignementPayload = Prisma.PromptAssignementGetPayload<{
  include: {
    prompt: {
      select: {
        [K in keyof SelectPromptPayload]: true
      }
    }
  }
}>

export type CreatePromptPayload = {
  userId?: string | undefined
  orgId?: string | undefined
  category?:
    | {
        name: string
        description: string
      }
    | undefined
  model: string
  name: string
  prompt: string
  description: string
  system: string
  secondPrompt: string
  temperature: number
  public: boolean
}

export type FileQueryResult = UseQueryResult<FilePayload, Error>
export type MeetingQueryResult = UseQueryResult<MeetingPayload, Error>
export type FolderQueryResult = UseQueryResult<FolderPayload, Error>
export type PromptQueryResult = UseQueryResult<PromptPayload, Error>

export enum MeetingLanguage {
  MULTIPLE = 'Multiples',
  FR = 'Français',
  EN = 'Anglais',
}

export enum SourceType {
  SUMMARY = 'SUMMARY',
  TRANSCRIPT = 'TRANSCRIPT',
  FILE = 'FILE',
}

export enum PromptType {
  PROMPT = 'PROMPT',
  MAGIC_PROMPT = 'MAGIC_PROMPT',
}
export enum MeetingType {
  AUDIO_FILE = 'AUDIO_FILE',
  LIVE_RECORDING = 'LIVE_RECORDING',
  BOT = 'BOT',
}

export enum MeetingStatus {
  CREATED = 'CREATED',
  AUDIO_READY = 'AUDIO_READY',
  TRANSCRIPTION_IN_PROGRESS = 'TRANSCRIPTION_IN_PROGRESS',
  TRANSCRIPT_READY = 'TRANSCRIPT_READY',
  SUMMARY_READY = 'SUMMARY_READY',
  ERROR = 'ERROR',
}
export enum BotStatusCode {
  READY = 'READY',
  JOINING = 'JOINING',
  IN_WAITING_ROOM = 'IN_WAITING_ROOM',
  IN_CALL_NOT_RECORDING = 'IN_CALL_NOT_RECORDING',
  IN_CALL_RECORDING = 'IN_CALL_RECORDING',
  STARTING_RECORDING = 'STARTING_RECORDING',
  DONE = 'DONE',
  TIMEOUT = 'TIMEOUT',
  DENIED = 'DENIED',
  ERROR = 'ERROR',
  FAIL_TO_START_RECORDING = 'FAIL_TO_START_RECORDING',
}

export enum PromptShowFor {
  MEETING = 'MEETING',
  FOLDER = 'FOLDER',
}
