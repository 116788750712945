import Button from '@/components/custom/Button'
import CustomLoader from '@/components/custom/CustomLoader'
import MeetingEditTitle from '@/components/custom/meetings/EditTitle'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'
import { useCreateTranscript, useDeleteMeeting, useMeeting, useUpdateMeeting } from '@/hooks/useMeeting'
import { recording, uploading } from '@/lib/states'
import { cn } from '@/lib/utils'
import type { MeetingQueryResult } from '@/types/others.types'
import { AVERAGE_CONFIDENCE_THRESHOLD, BAD_CONFIDENCE_THRESHOLD, displayDateOfMeeting, logger } from '@/utils'
import { translateMeetingStatus, translateMeetingType } from '@/utils/translate'
import { Crisp } from 'crisp-sdk-web'
import 'dayjs/locale/fr'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import SummaryTab from '@/containers/meetings/summary/SummaryTab'
import TranscriptTab from '@/containers/meetings/transcript/TranscriptTab'
import { useAtom } from 'jotai'
import { ArchiveRestore, ArchiveX, Loader2, MoreHorizontal, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'
import ReactPlayer from 'react-player'
import { unstable_usePrompt, useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

type TabType = 'transcript' | 'summary' | 'informations'

function Meeting({ id }: { id: string }) {
  const [tab, setTab] = useState<TabType>('transcript')

  const meeting = useMeeting(id)

  const [isRecording] = useAtom(recording)
  const [isUploading] = useAtom(uploading)

  useEffect(() => {
    if (meeting.data?.status && ['TRANSCRIPT_READY', 'SUMMARY_READY', 'SUMMARY_IN_PROGRESS'].includes(meeting.data?.status)) {
      handleTabChange('summary')
    }
  }, [meeting.data?.status])

  const handleTabChange = (value: TabType) => {
    setTab(value)
  }

  const showQuality = () => {
    const confidence = meeting.data?.transcript?.confidence
    if (confidence) {
      let score: 'LOW' | 'MEDIUM' | 'GOOD'

      if (confidence < BAD_CONFIDENCE_THRESHOLD) {
        score = 'LOW'
      } else if (confidence < AVERAGE_CONFIDENCE_THRESHOLD) {
        score = 'MEDIUM'
      } else {
        score = 'GOOD'
      }

      return (
        <>
          {' '}
          ·{' '}
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger
                className={cn(
                  '',
                  score === 'LOW' && 'text-red-700',
                  score === 'MEDIUM' && 'text-gray-700',
                  score === 'GOOD' && 'text-green-700',
                )}
              >
                Qualité de l'audio : {score === 'LOW' && 'Faible'}
                {score === 'MEDIUM' && 'Moyenne'}
                {score === 'GOOD' && 'OK'}
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-lg">Qualité de l'audio : {Number.parseFloat((confidence * 100).toFixed(3))} / 100</p>
                {(score === 'LOW' || score === 'MEDIUM') && (
                  <p>
                    La qualité du fichier audio étant {score === 'LOW' ? 'faible' : 'moyenne'}, <br />
                    la transcription ainsi que les comptes rendus risquent d'être de qualité inférieure.
                  </p>
                )}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </>
      )
    }
  }

  // Block navigating elsewhere when data has been entered into the input
  unstable_usePrompt({
    message: "Voulez-vous vraiment quitter la page ? L'enregistrement sera perdu.",
    when: ({ currentLocation, nextLocation }) => (isRecording || isUploading) && currentLocation.pathname !== nextLocation.pathname,
  })

  if (!id) {
    return <div>id is null</div>
  }
  if (meeting?.isLoading) {
    return <CustomLoader centered size={14} />
  }
  if (!meeting?.data) {
    return <div>Empty</div>
  }

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/meetings">Réunions</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>{meeting.data?.title ?? 'Réunion sans titre'}</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex justify-between items-center	gap-2">
        <div className="flex flex-col w-full">
          <h2 className="text-xl">
            <MeetingEditTitle meeting={meeting} />
          </h2>

          <p className="text-xs leading-5 text-gray-500">
            <time dateTime={displayDateOfMeeting(meeting.data)}>{displayDateOfMeeting(meeting.data)}</time> · Créé par{' '}
            {meeting.data.user?.firstName ? `${meeting.data.user?.firstName} ${meeting.data.user?.lastName}` : meeting.data.user?.email}
          </p>
          <div className="text-xs leading-5 text-gray-500">
            <span>{translateMeetingType(meeting.data.type)}</span> · <span>{translateMeetingStatus(meeting.data.status)}</span>
            {showQuality()}
          </div>
          {meeting.data.bot?.participants && meeting.data.bot.participants.length > 0 && (
            <div className="text-xs leading-5 text-gray-500">
              <span>Participants : {meeting.data.bot.participants.join(', ')}</span>
            </div>
          )}
        </div>
        <CreateOrRecreateTranscriptButton meeting={meeting} />
        <ArchiveMeetingButton meeting={meeting} />

        <DeleteMeetingButton meeting={meeting} />
      </div>
      <Tabs value={tab} onValueChange={handleTabChange} className="flex flex-col w-full gap-4">
        {meeting.data.status !== 'CREATED' && meeting.data.status !== 'UPLOADING' && (
          <TabsList className="flex-none">
            <TabsTrigger value="transcript" className="w-full">
              Transcript
            </TabsTrigger>
            <TabsTrigger value="summary" className="w-full" disabled={meeting.data.status === 'ERROR' || isRecording || isUploading}>
              Comptes rendus
            </TabsTrigger>
            {/* {!!superAdmin && (
              <TabsTrigger value="informations" className="w-full" disabled={meeting.data.status === "ERROR" || isRecording}>
                [ADMIN] Informations
              </TabsTrigger>
            )} */}
          </TabsList>
        )}
        <TabsContent value="transcript">
          <div className="p-1 flex flex-col gap-4 justify-between">
            <TranscriptTab meeting={meeting} />
          </div>
        </TabsContent>
        <TabsContent value="summary">
          <div className="p-1 flex flex-col gap-4 justify-between">
            <SummaryTab meeting={meeting} />
          </div>
        </TabsContent>
        <TabsContent value="informations" className="prose">
          <div className="p-1 flex flex-col gap-4 justify-between">
            <InformationsTab meeting={meeting} />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}

type Props = {
  meeting: MeetingQueryResult
}

function InformationsTab({ meeting }: Props) {
  return (
    <>
      {meeting.data?.fileUrl && (
        <>
          <a href={meeting.data.fileUrl} target="_blank" rel="noreferrer">
            Lien vers le fichier audio
          </a>
          <br />
          <ReactPlayer url={meeting.data.fileUrl} controls={true} height={'100px'} config={{ file: { forceAudio: true } }} />
        </>
      )}
    </>
  )
}

function ArchiveMeetingButton({ meeting }: Props) {
  const updateMeetingMutation = useUpdateMeeting()
  const [open, setOpen] = useState(false)
  const mixpanel = useMixpanel()
  const { toast } = useToast()

  const archiveMeeting = async () => {
    const newIsArchived = !meeting.data?.isArchived

    mixpanel?.track('Archive Meeting', {
      type: meeting.data?.type,
      status: meeting.data?.status,
      isArchived: newIsArchived,
    })

    Crisp.session.pushEvent('Archive Meeting', {
      type: meeting.data?.type,
      status: meeting.data?.status,
      isArchived: newIsArchived,
    })

    return updateMeetingMutation.mutate(
      { id: meeting.data.id, meeting: { isArchived: newIsArchived } },
      {
        onSuccess() {
          setOpen(false)
          toast({
            title: newIsArchived ? 'Réunion archivée' : 'Réunion désarchivée',
            variant: 'default',
          })
        },
        onError(error) {
          toast({
            title: `Erreur lors de ${newIsArchived ? "l'archivage" : 'la désarchivation'} de la réunion`,
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <AlertDialogTrigger asChild>
              <Button variant="outline">
                {meeting.data?.isArchived ? <ArchiveRestore className="h-4 w-4" /> : <ArchiveX className="h-4 w-4" />}
              </Button>
            </AlertDialogTrigger>
          </TooltipTrigger>
          <TooltipContent>{meeting.data?.isArchived ? 'Désarchiver' : 'Archiver'} la réunion</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>
            {meeting.data?.isArchived
              ? 'Cette réunion sera désarchivée et réapparaîtra dans la liste principale des réunions.'
              : "Cette réunion sera archivée et n'apparaîtra plus dans la liste principale des réunions."}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={updateMeetingMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={(e) => {
                e.preventDefault()
                archiveMeeting()
              }}
              isLoading={updateMeetingMutation.isPending}
            >
              {meeting.data?.isArchived ? 'Oui, désarchiver' : 'Oui, archiver'}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function DeleteMeetingButton({ meeting }: Props) {
  const deleteMeetingMutation = useDeleteMeeting()

  const [open, setOpen] = useState(false)

  const mixpanel = useMixpanel()

  const [isRecording] = useAtom(recording)
  const [isUploading] = useAtom(uploading)

  const navigate = useNavigate()
  const { toast } = useToast()

  const deleteMeeting = async () => {
    mixpanel?.track('Delete Meeting', {
      type: meeting.data?.type,
      status: meeting.data?.status,
    })

    Crisp.session.pushEvent('Delete Meeting', {
      type: meeting.data?.type,
      status: meeting.data?.status,
    })

    mixpanel?.people.increment('meetings deleted')

    return deleteMeetingMutation.mutate(
      { id: meeting.data.id },
      {
        onSuccess() {
          setOpen(false)
          navigate('/meetings')

          toast({
            title: 'Réunion supprimée',
            variant: 'default',
          })
        },
        onError(error) {
          toast({
            title: 'Erreur lors de la suppression de la réunion',
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger
        asChild
        disabled={
          isRecording ||
          isUploading ||
          meeting.data?.status === 'TRANSCRIPTION_IN_PROGRESS' ||
          (meeting.data?.type === 'BOT' &&
            !(
              meeting.data.bot?.status.code === 'READY' ||
              meeting.data.bot?.status.code === 'DONE' ||
              meeting.data.bot?.status.code === 'ERROR' ||
              meeting.data.bot?.status.code === 'DENIED' ||
              meeting.data.bot?.status.code === 'FAIL_TO_START_RECORDING' ||
              meeting.data.bot?.status.code === 'TIMEOUT' ||
              !meeting.data.bot?.id
            ))
        }
      >
        <Button variant="destructive">
          <Trash className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>
            Cette réunion, le transcript et les comptes rendus générés seront definitivement supprimés.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deleteMeetingMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={(e) => {
                e.preventDefault()
                deleteMeeting()
              }}
              isLoading={deleteMeetingMutation.isPending}
            >
              Oui. Supprimer !
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function CreateOrRecreateTranscriptButton({ meeting }: Props) {
  const { toast } = useToast()
  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)
  const transcriptMutation = useCreateTranscript()

  const createTranscript = async (provider?: string) => {
    logger('createTranscript : ', meeting.data?.id)

    return transcriptMutation.mutate(
      { id: meeting.data?.id, provider },
      {
        onSuccess: async () => {
          toast({
            title: 'Transcript en cours de création',
          })
        },
        onError(error) {
          console.error('error : ', error)
          toast({
            title: 'Erreur lors du lancement de la création du transcript',
            description: error?.response?.data?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  if (superAdmin) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" isLoading={transcriptMutation.isPending}>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => createTranscript()} disabled={transcriptMutation.isPending}>
            [ADMIN] Recréer un transcript
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => createTranscript('assembly')} disabled={transcriptMutation.isPending}>
            [ADMIN] Transcript avec Assembly
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }

  return null
}

export default function MeetingPage() {
  const { id } = useParams()
  if (!id) return <></>
  return <Meeting key={id} id={id} />
}
