import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Trash } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

const formSchema = yup.object({
  variables: yup.array(
    yup.object({
      key: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
})

type FormType = yup.InferType<typeof formSchema>

interface SettingsVariablesProps {
  variables?: Record<string, string>
  onSubmit: (variables: Record<string, string>) => void
}

export function SettingsVariables({ variables, onSubmit }: SettingsVariablesProps) {
  const defaultVariables = variables && Object.entries(variables).map(([key, value]) => ({ key, value }))

  const { register, control, handleSubmit, reset } = useForm<FormType>({
    defaultValues: {
      variables: defaultVariables ?? [{ key: '', value: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variables',
  })

  const prevVariablesRef = useRef(defaultVariables)

  useEffect(() => {
    if (JSON.stringify(prevVariablesRef.current) !== JSON.stringify(defaultVariables)) {
      reset({ variables: defaultVariables ?? [{ key: '', value: '' }] })
      prevVariablesRef.current = defaultVariables
    }
  }, [defaultVariables, reset])

  const handleFormSubmit = ({ variables }: FormType) => {
    if (variables) {
      const transformedData = variables?.reduce(
        (acc, curr) => {
          acc[curr.key] = curr.value
          return acc
        },
        {} as Record<string, string>,
      )
      onSubmit(transformedData)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-2">
      {fields.map((field, index) => (
        <div key={field.id} className="flex space-x-2">
          <Input {...register(`variables.${index}.key`)} placeholder={`Clé ${index + 1}`} />
          <Input {...register(`variables.${index}.value`)} placeholder={`Valeur ${index + 1}`} />
          <Button type="button" variant="destructive" onClick={() => remove(index)}>
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      ))}
      <div className="flex space-x-2 mt-4">
        <Button variant="outline" type="button" onClick={() => append({ key: '', value: '' })}>
          Ajouter une nouvelle ligne
        </Button>
        <Button type="submit">Enregistrer</Button>
      </div>
    </form>
  )
}
