import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import 'dayjs/locale/fr'
import Button from '@/components/custom/Button'
import FoldersEditTitle from '@/components/custom/folders/EditTitle'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import { useDeleteSourceFromFolder, useFolder } from '@/hooks/useFolder'
import { useDeleteFolder } from '@/hooks/useFolder'
import { formatDate, getSourceName } from '@/utils'
import { Crisp } from 'crisp-sdk-web'
import { Trash } from 'lucide-react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddSources from './AddSources'
import 'dayjs/locale/fr'
import SourceTypeBadge from '@/components/custom/SourceTypeBadge'
import type { FolderQueryResult } from '@/types/others.types'
import { useMixpanel } from 'react-mixpanel-browser'

export default function FolderSources() {
  const { id } = useParams()

  const [mode, setMode] = useState<'sources' | 'generations'>('sources')

  const deleteSourceMutation = useDeleteSourceFromFolder()

  const folder = useFolder(id)

  if (!folder?.data) {
    return <div>Empty</div>
  }

  const deleteSource = (source_id: string) => {
    return deleteSourceMutation.mutate({
      folder_id: folder.data?.id,
      source_id,
    })
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/folders">Dossiers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>{folder.data?.title ?? 'Dossier sans titre'}</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex justify-between items-center	gap-2">
        <div className="flex flex-col w-full">
          <h2 className="text-xl">
            <FoldersEditTitle folder={folder} />
          </h2>

          <p className="text-xs leading-5 text-gray-500">
            {folder.data.user?.firstName ? `${folder.data.user?.firstName} ${folder.data.user?.lastName}` : folder.data.user?.email}
          </p>
          <div className="text-xs leading-5 text-gray-500"></div>
        </div>
        <DeleteFolderButton folder={folder} />
      </div>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="flex items-start gap-2">
            Sources du dossier <span className="text-sm text-gray-700">({folder.data?.sources?.length || 0})</span>
          </CardTitle>
          <div className="flex gap-2">
            <AddSources folder={folder} />
            <Link to={`/folders/${id}/summaries/new`}>
              <Button>Nouvelle rédaction</Button>
            </Link>
          </div>
        </CardHeader>
        <CardContent>
          <br />

          <Tabs
            defaultValue="mine"
            value={mode}
            onValueChange={(value) => {
              setMode(value)
            }}
          >
            <TabsList className="h-8">
              <TabsTrigger value="sources" className="h-6">
                Sources
              </TabsTrigger>
              <TabsTrigger value="generations" className="h-6">
                Générations
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="">Nom</TableHead>
                <TableHead>Type</TableHead>
                <TableHead className="text-right">Date de l'ajout</TableHead>
                <TableHead className="text-center w-[100px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {folder.data?.sources?.map((s) => (
                <TableRow key={s.id}>
                  <TableCell className="font-medium">{getSourceName(s)}</TableCell>
                  <TableCell>
                    <SourceTypeBadge source={s} />
                  </TableCell>
                  <TableCell className="text-right">{formatDate(s.createdAt)}</TableCell>
                  <TableCell className="flex flex-row gap-2 justify-end">
                    {s.summaryId && (
                      <Link to={`/folders/${id}/summaries/${s.summaryId}`}>
                        <Button variant="outline">Ouvrir</Button>
                      </Link>
                    )}
                    {s.fileId && (
                      <Link to={`/files/${s.fileId}`}>
                        <Button variant="outline">Ouvrir</Button>
                      </Link>
                    )}
                    <Button
                      size="icon"
                      variant="outline"
                      className="border-red-400"
                      onClick={() => deleteSource(s.id)}
                      isLoading={deleteSourceMutation.variables?.source_id === s.id && deleteSourceMutation.isPending}
                    >
                      <Trash className="h-4 w-4 text-red-400" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

type Props = {
  folder: FolderQueryResult
}

function DeleteFolderButton({ folder }: Props) {
  const deleteFolderMutation = useDeleteFolder()

  const [open, setOpen] = useState(false)

  const mixpanel = useMixpanel()

  const navigate = useNavigate()
  const { toast } = useToast()

  const deleteFolder = async () => {
    mixpanel?.track('Delete Folder', {
      status: folder.data?.status,
    })

    Crisp.session.pushEvent('Delete Folder', {
      status: folder.data?.status,
    })

    mixpanel?.people.increment('folders deleted')

    return deleteFolderMutation.mutate(
      { id: folder.data.id },
      {
        onSuccess() {
          setOpen(false)
          navigate('/folders')

          toast({
            title: 'Dossier supprimé',
            variant: 'default',
          })
        },
        onError(error) {
          toast({
            title: 'Erreur lors de la suppression du dossier',
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">
          <Trash className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>
            Ce dossier, les sources, et les documents générés seront définitivement supprimés.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deleteFolderMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={(e) => {
                e.preventDefault()
                deleteFolder()
              }}
              isLoading={deleteFolderMutation.isPending}
            >
              Oui. Supprimer !
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
