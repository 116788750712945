import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useUploadFile } from '@/hooks/useFile'
import { useQueryClient } from '@tanstack/react-query'
import { type ChangeEvent, useCallback, useState } from 'react'
import { Progress } from '../ui/progress'
import { useToast } from '../ui/use-toast'
import Button from './Button'

interface SelectedFile {
  file: File
  progress: number
  status: 'pending' | 'uploading' | 'success' | 'error'
}

interface UploadFileProps {
  buttonText?: string
}
export function UploadFile({ buttonText = 'Ajouter des fichiers' }: UploadFileProps) {
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])
  const [currentUploadIndex, setCurrentUploadIndex] = useState<number | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const { toast } = useToast()

  const queryClient = useQueryClient()

  const updateFileStatus = useCallback((index: number, updates: Partial<SelectedFile>) => {
    setSelectedFiles((prevState) => prevState.map((sf, i) => (i === index ? { ...sf, ...updates } : sf)))
  }, [])

  const uploadFile = useUploadFile((progress: number) => {
    if (currentUploadIndex !== null) {
      updateFileStatus(currentUploadIndex, { progress })
    }
  })

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file,
        progress: 0,
        status: 'pending' as const,
      }))
      setSelectedFiles(filesArray)
    }
  }

  const handleUpload = async () => {
    for (const [index, selectedFile] of selectedFiles.entries()) {
      setCurrentUploadIndex(index)
      updateFileStatus(index, { status: 'uploading' })
      try {
        await uploadFile.mutateAsync({ file: selectedFile.file })
        updateFileStatus(index, { status: 'success', progress: 100 })
      } catch (error) {
        console.error(`Error uploading file ${selectedFile.file.name}:`, error)
        updateFileStatus(index, { status: 'error' })
      }
    }
    setCurrentUploadIndex(null)
    // Fermer la dialog après l'upload
    setIsOpen(false)
    toast({
      title: 'Fichiers ajoutés avec succès',
      variant: 'default',
    })
    await queryClient.invalidateQueries({ queryKey: ['files'] })
  }
  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    if (open) {
      // Réinitialiser l'état lorsque la dialog est ouverte
      setSelectedFiles([])
      setCurrentUploadIndex(null)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline">{buttonText}</Button>
      </DialogTrigger>
      <DialogContent
        onEscapeKeyDown={(e) => selectedFiles.length !== 0 && e.preventDefault()}
        onPointerDownOutside={(e) => selectedFiles.length !== 0 && e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Ajouter des fichiers</DialogTitle>
          <DialogDescription>Sélectionnez les fichiers à ajouter.</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="">
            <Input id="files" type="file" multiple onChange={handleFileChange} />
          </div>
          {selectedFiles.length > 0 && (
            <div className="mt-4">
              {selectedFiles.map((selectedFile, index) => (
                <div key={index} className="flex items-center justify-between mb-2">
                  <span>{selectedFile.file.name}</span>
                  <div className="w-full bg-gray-200 rounded h-2.5 mx-2">
                    <Progress value={selectedFile.progress} className="w-full" indicatorColor="bg-indigo-600" />
                  </div>
                  <span>{selectedFile.status === 'error' ? 'Erreur' : `${selectedFile.progress}%`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <DialogFooter>
          <Button isLoading={uploadFile.isPending} onClick={handleUpload} type="button">
            Ajoutez ces fichiers
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
