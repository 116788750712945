import { Loader } from 'lucide-react'

type Props = {
  centered?: boolean
  size?: number
  className?: string
}

function CustomLoader({ centered, size, className }: Props) {
  if (centered) {
    if (!size) size = 14
    return (
      <div className="flex flex-col items-center">
        <Loader className={`h-${size} w-${size} animate-spin ${className}`} />
      </div>
    )
  } else {
    if (!size) size = 6
    return <Loader className={`h-${size} w-${size} animate-spin ${className}`} />
  }
}

export default CustomLoader
