import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useCreateSummaryFollowUp } from '@/hooks/useMeeting'
import { usePrompts } from '@/hooks/usePrompt'
import type { PromptPayload, SummaryPayload } from '@/types/others.types'
import { Crisp } from 'crisp-sdk-web'
import { WandSparkles } from 'lucide-react'
import { useMixpanel } from 'react-mixpanel-browser'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { Button } from '../ui/button'
import { useToast } from '../ui/use-toast'
import CustomLoader from './CustomLoader'

function ButtonMagicPrompt({ summary }: { summary: SummaryPayload }) {
  const { toast } = useToast()
  const [_, setSearchParams] = useSearchParams()

  const mixpanel = useMixpanel()

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  const prompts = usePrompts({ type: 'MAGIC_PROMPT', parentPromptId: summary.promptId })

  const createSummaryFollowUp = useCreateSummaryFollowUp()

  const create = async (magicPrompt: PromptPayload) => {
    mixpanel?.track('Create Summary FollowUp', {
      name: magicPrompt.name,
    })
    mixpanel?.people.increment('summaries followup created')

    Crisp.session.pushEvent('Create Summary FollowUp', {
      name: magicPrompt.name,
    })
    if (!summary.meetingId) {
      throw new Error('summary.meetingId not defined')
    }

    await createSummaryFollowUp.mutateAsync(
      {
        id: summary.meetingId,
        summaryId: summary.id,
        promptId: magicPrompt.id,
      },
      {
        onSuccess: async (data) => {
          toast({
            title: 'Compte rendu créé',
          })
          setSearchParams(createSearchParams({ summary: data }))
        },
        onError(error) {
          toast({
            title: 'Erreur lors de la création du compte rendu',
            description: error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }
  if (summary.followUpPrompt && !superAdmin) {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline">
            <>
              <WandSparkles className="h-4 w-4 md:mr-2" /> <span className="hidden md:block">Édition</span>
            </>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <p>Vous avez déjà utilisé l'édition magique. Si vous n'êtes toujours pas satisfaire du résultat, contactez-nous.</p>
        </PopoverContent>
      </Popover>
    )
  }
  if (prompts.data?.length) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" disabled={createSummaryFollowUp.isPending}>
            {createSummaryFollowUp.isPending ? (
              <CustomLoader />
            ) : (
              <>
                <WandSparkles className="h-4 w-4 md:mr-2" /> <span className="hidden md:block">Édition</span>
              </>
            )}
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="overflow-y-auto max-h-[15rem]">
          <DropdownMenuLabel>Édition magique</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {prompts.data?.map((p) => (
            <DropdownMenuItem onSelect={() => create(p)} key={p.id}>
              {p.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }
  return <></>
}

export default ButtonMagicPrompt
