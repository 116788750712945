import { Button } from '@/components/ui/button'
import { ToastAction } from '@/components/ui/toast'
import { useToast } from '@/components/ui/use-toast'
import { useImpersonateUser, useUsers } from '@/hooks/useUser'
import { formatDate, logger } from '@/utils'
import { useCopyToClipboard } from 'usehooks-ts'

export function Users() {
  const { toast } = useToast()

  const [, copy] = useCopyToClipboard()

  const users = useUsers()

  const impersonateMutation = useImpersonateUser()

  const impersonate = async (userId: string) => {
    logger('get impersonate url : ', userId)
    await impersonateMutation.mutateAsync(userId, {
      onSuccess: async (data) => {
        toast({
          title: 'Url généré',
          description: "Copié l'url puis ouvrez là en navigation privée.",
          action: (
            <ToastAction
              altText="Try again"
              onClick={() => {
                copy(data.url)

                toast({
                  title: 'Url copié',
                  description: 'Ouvrez là en navigation privée.',
                })
              }}
            >
              Copier l'url
            </ToastAction>
          ),
        })
      },
    })
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Users</h2>
      </div>
      <br />
      <ul role="list" className="divide-y divide-gray-100">
        {users.data
          ?.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
          ?.map((user) => (
            <li key={user.id} className="relative flex justify-between gap-x-6 py-5">
              <div className="flex gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{user.email}</p>
                  </div>
                  <div className="mt-1 gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="">
                      Créé le <time dateTime={formatDate(user?.createdAt)}>{formatDate(user?.createdAt, 'DD/MM/YY - HH:mm')}</time> / Mis à
                      jour le <time dateTime={formatDate(user?.updatedAt)}>{formatDate(user?.updatedAt, 'DD/MM/YY - HH:mm')}</time>
                      {/* /
											Dernière connexion le{' '}
											<time dateTime={formatDate(user?.lastSignInAt)}>{formatDate(user?.lastSignInAt, 'DD/MM/YY - HH:mm')}</time> */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <Button variant="outline" size="sm" onClick={() => impersonate(user.id)}>
                  Impersonifier
                </Button>
              </div>
            </li>
          ))}
      </ul>
    </>
  )
}
