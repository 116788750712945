import { BotStatusCode, MeetingStatus, MeetingType, SourceType } from '@/types/others.types'

export function translateMeetingType(meetingType: MeetingType): string {
  const translations: {
    [key in MeetingType]: string
  } = {
    [MeetingType.AUDIO_FILE]: 'Fichier',
    [MeetingType.LIVE_RECORDING]: 'Micro',
    [MeetingType.BOT]: 'Teams',
  }

  return translations[meetingType]
}
export function translateSourceType(sourceType: SourceType): string {
  const translations: {
    [key in SourceType]: string
  } = {
    [SourceType.SUMMARY]: 'Compte rendu',
    [SourceType.TRANSCRIPT]: 'Transcript',
    [SourceType.FILE]: 'Fichier',
  }

  return translations[sourceType]
}

export function translateMeetingStatus(meetingStatus: MeetingStatus): string {
  const translations: {
    [key in MeetingStatus]: string
  } = {
    [MeetingStatus.CREATED]: "En attente d'audio",
    [MeetingStatus.ERROR]: 'Erreur',
    [MeetingStatus.AUDIO_READY]: 'Audio prêt',
    [MeetingStatus.TRANSCRIPTION_IN_PROGRESS]: 'Transcription en cours',
    [MeetingStatus.TRANSCRIPT_READY]: 'Transcript prêt',
    [MeetingStatus.SUMMARY_READY]: 'Compte rendu prêt',
  }

  return translations[meetingStatus]
}

export function translateBotStatus(botStatus: BotStatusCode): string {
  const translations: {
    [key in BotStatusCode]: string
  } = {
    [BotStatusCode.READY]: "En attente d'audio",
    [BotStatusCode.ERROR]: 'Erreur',
    [BotStatusCode.DONE]: 'Terminé',
    [BotStatusCode.FAIL_TO_START_RECORDING]: 'Erreur',
    [BotStatusCode.IN_CALL_NOT_RECORDING]: 'Transcription en cours',
    [BotStatusCode.IN_CALL_RECORDING]: "En cours d'enregistrement",
    [BotStatusCode.IN_WAITING_ROOM]: "Dans la salle d'attente",
    [BotStatusCode.STARTING_RECORDING]: "Commence l'enregistrement",
    [BotStatusCode.DENIED]: 'Refusé',
    [BotStatusCode.TIMEOUT]: 'Refusé',
    [BotStatusCode.JOINING]: 'Prêt',
  }

  return translations[botStatus]
}
