import CustomLoader from '@/components/custom/CustomLoader'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useUpdateMeeting } from '@/hooks/useMeeting'
import type { MeetingQueryResult } from '@/types/others.types'
import { CircleCheck } from 'lucide-react'
import { useDebounceCallback } from 'usehooks-ts'

type Props = {
  meeting: MeetingQueryResult
  optionnel?: boolean
}

export default function AddContext({ meeting, optionnel = false }: Props) {
  const updateMeetingMutation = useUpdateMeeting()

  const submit = (value: string) => {
    return updateMeetingMutation.mutate({
      id: meeting.data?.id,
      meeting: {
        context: value,
      },
    })
  }

  const debounced = useDebounceCallback(submit, 500)

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="context" className="flex flex-row gap-2 items-center">
        Contexte de la réunion {optionnel && <span className="text-xs font-light">(optionnel)</span>}
        {updateMeetingMutation.isPending && <CustomLoader size={4} />}
        {updateMeetingMutation.isSuccess && <CircleCheck className="h-4 w-4 text-green-600" />}
      </Label>
      <Textarea
        defaultValue={meeting.data?.context ?? ''}
        onChange={(e) => {
          debounced(e.target.value)
        }}
        id="context"
        placeholder="Date de la réunion, participants, autres informations utiles..."
      />
    </div>
  )
}
