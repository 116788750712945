import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { usePrompts } from '@/hooks/usePrompt'
import type { MeetingQueryResult } from '@/types/others.types'
import { formatDate } from '@/utils'
import { PenLine } from 'lucide-react'
import { useEffect } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'

import SummaryContent from '@/containers/common/SummaryContent'
import SummaryNew from './SummaryNew'

type Props = {
  meeting: MeetingQueryResult
}

export default function SummaryView({ meeting }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()

  const summaries = meeting.data?.summary

  const prompts = usePrompts()

  useEffect(() => {
    if (!searchParams.get('summary') && !!summaries?.[0]?.id) {
      setSearchParams(createSearchParams({ summary: summaries[0]?.id }), { replace: true })
    }
  }, [searchParams.get('summary'), summaries, setSearchParams])

  const currentSummary = summaries?.find((s) => s.id === searchParams.get('summary'))

  return (
    <>
      {meeting.data?.status === 'SUMMARY_IN_PROGRESS' && (
        <div className="w-full">
          <Alert>
            <PenLine className="h-4 w-4" />
            <AlertTitle>Compte rendu en cours de création...</AlertTitle>
            <AlertDescription>Un compte rendu est en cours de création. Il sera disponible dans 2 minutes max.</AlertDescription>
          </Alert>
        </div>
      )}
      <div className="flex flex-row justify-between gap-2">
        {/*         {superAdmin ? (
         */}
        <Select onValueChange={(value) => setSearchParams(createSearchParams({ summary: value }))} value={searchParams.get('summary')}>
          <SelectTrigger className="w-2/3">
            <SelectValue placeholder="Choisissez un compte rendu" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Comptes rendus</SelectLabel>
              {summaries
                ?.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
                ?.map((s, i) => (
                  <SelectItem key={s.id} value={s.id}>
                    {/*                       {formatDate(s.createdAt, "dd DD MMM - HH[:]mm")} | {prompts.data?.find((p) => p.id === s.promptId)?.name} | {s.model}
                     */}
                    Version {summaries.length - i} · {prompts.data?.find((p) => p.id === s.promptId)?.name} ·{' '}
                    {formatDate(s.createdAt, 'DD MMM HH[:]mm')}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        {/*         ) : (
          <div />
        )} */}
        <SummaryNew meeting={meeting} type={'dialog'} />
      </div>

      {currentSummary && <SummaryContent summary={currentSummary} />}
    </>
  )
}
