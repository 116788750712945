import Button from '@/components/custom/Button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import {
  useCreatePromptAssignement,
  useDeletePrompt,
  useDeletePromptAssignement,
  usePromptAssignements,
  usePrompts,
} from '@/hooks/usePrompt'
import { classNames, formatDate, isAdmin } from '@/utils'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Check, Trash, Unlink } from 'lucide-react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export function Prompts() {
  const auth = useAuth()
  const { user } = useUser()

  const [promptType, setPromptType] = useState('PROMPT')

  const allPrompts = usePrompts({ all: 1 })
  const myPrompts = usePromptAssignements()

  const addToMyPrompts = useCreatePromptAssignement()

  const tabs = () => (
    <Tabs
      defaultValue="prompt"
      className="w-[400px] mt-4"
      onValueChange={(value) => {
        setPromptType(value)
      }}
      value={promptType}
    >
      <TabsList>
        <TabsTrigger value="PROMPT">Prompt</TabsTrigger>
        <TabsTrigger value="MAGIC_PROMPT">Magic Prompt</TabsTrigger>
      </TabsList>
    </Tabs>
  )

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Prompts</h2>

        <Link to={'new'}>
          <Button>Créer un prompt</Button>
        </Link>
      </div>
      <br />

      <h3 className="text-xl">Mes Prompts</h3>
      {tabs()}

      <ul className="divide-y divide-gray-100">
        {myPrompts.data
          ?.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
          ?.filter((promptAssignement) => promptAssignement.prompt.type === promptType)
          ?.map((promptAssignement) => (
            <li key={promptAssignement.id} className="relative flex justify-between gap-x-6 py-5">
              <div className="flex gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{promptAssignement.prompt.name}</p>
                    <div
                      className={classNames(
                        'text-gray-700 bg-gray-50 ring-gray-600/20',
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {promptAssignement.prompt.type}
                    </div>
                    {promptAssignement.prompt.public ? (
                      <div
                        className={classNames(
                          'text-green-700 bg-green-50 ring-green-600/20',
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        Public
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          'text-orange-700 bg-orange-50 ring-orange-600/20',
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        Privé
                      </div>
                    )}
                    {!!promptAssignement.specificPrompt && (
                      <>
                        {/* <Badge variant="outline" className="bg-blue-50 text-blue-700 ring-blue-600/20 rounded-sm">
													Prompt spécifique
												</Badge> */}
                        <div
                          className={classNames(
                            'text-blue-700 bg-blue-50 ring-blue-600/20',
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                          )}
                        >
                          Prompt spécifique
                        </div>
                      </>
                    )}
                    {!!promptAssignement.prompt.showFor.length && (
                      <>
                        {/* <Badge variant="outline" className="bg-blue-50 text-blue-700 ring-blue-600/20 rounded-sm">
													Prompt spécifique
												</Badge> */}
                        {promptAssignement.prompt.showFor.map((i) => (
                          <div
                            key={i}
                            className={classNames(
                              'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
                              'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                            )}
                          >
                            {i}
                          </div>
                        ))}
                      </>
                    )}

                    {/* 
                    <p
                      className={classNames(
                        "text-gray-600 bg-gray-50 ring-gray-500/10",
                        "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      Model : {prompt.model}
                    </p>
                    <p
                      className={classNames(
                        "text-gray-600 bg-gray-50 ring-gray-500/10",
                        "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      Temp : {prompt.temperature}
                    </p> */}
                  </div>

                  {!!promptAssignement.updatedAt && (
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Mis à jour le{' '}
                        <time dateTime={formatDate(promptAssignement.updatedAt)}>{formatDate(promptAssignement.updatedAt)}</time>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <Link to={`/prompts/${promptAssignement.prompt.id}`}>
                  <Button variant="outline">Ouvrir</Button>
                </Link>
                <DeletePromptAssignementButton id={promptAssignement.id} />
              </div>
            </li>
          ))}
      </ul>

      <Separator className="my-8 w-2/3 mx-auto" />

      <h3 className="text-xl">Bibliothèques</h3>

      {tabs()}
      <ul className="divide-y divide-gray-100">
        {allPrompts.data
          ?.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
          ?.filter((prompt) => prompt.type === promptType)
          ?.map((prompt) => (
            <li key={prompt.id} className="relative flex justify-between gap-x-6 py-5">
              <div className="flex gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{prompt.name}</p>
                    <div
                      className={classNames(
                        'text-gray-700 bg-gray-50 ring-gray-600/20',
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {prompt.type}
                    </div>
                    {prompt.public && (
                      <div
                        className={classNames(
                          'text-green-700 bg-green-50 ring-green-600/20',
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}
                      >
                        Public
                      </div>
                    )}
                    {/* 
                    <p
                      className={classNames(
                        "text-gray-600 bg-gray-50 ring-gray-500/10",
                        "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      Model : {prompt.model}
                    </p>
                    <p
                      className={classNames(
                        "text-gray-600 bg-gray-50 ring-gray-500/10",
                        "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      Temp : {prompt.temperature}
                    </p> */}
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                      Créé le <time dateTime={formatDate(prompt?.createdAt)}>{formatDate(prompt?.createdAt)}</time>
                    </p>
                  </div>
                  {!!prompt?.updatedAt && (
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Mis à jour le <time dateTime={formatDate(prompt?.updatedAt)}>{formatDate(prompt?.updatedAt)}</time>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                {isAdmin(user) && (
                  <Link to={`/prompts/${prompt.id}`}>
                    <Button variant="outline">Ouvrir</Button>
                  </Link>
                )}
                {!!auth.orgId &&
                  (myPrompts.data?.find((p) => p.promptId === prompt.id) ? (
                    <Button disabled>
                      <Check className="h-4 w-4 mr-2" /> Ajouté à mes prompts
                    </Button>
                  ) : (
                    <Button
                      isLoading={addToMyPrompts.variables?.promptId === prompt.id && addToMyPrompts.isPending}
                      onClick={() => addToMyPrompts.mutate({ promptId: prompt.id })}
                    >
                      Ajouter à mes prompts
                    </Button>
                  ))}
                {isAdmin(user) && <DeletePromptButton id={prompt.id} />}
              </div>
            </li>
          ))}
      </ul>
    </>
  )
}

function DeletePromptButton({ id }: { id: string }) {
  const deletePromptMutation = useDeletePrompt()

  const navigate = useNavigate()
  const { toast } = useToast()

  const deletePrompt = async () => {
    deletePromptMutation.mutate(
      { id: id },
      {
        onSuccess() {
          navigate('/prompts')

          toast({
            title: 'Prompt supprimé',
            variant: 'default',
          })
        },
      },
    )
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" size="icon">
          <Trash className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>
            Ce prompt sera <b>definitivement supprimé</b> pour <b>tous les utilisateurs</b>.
            <br />
            <br />
            Les <b>prompts spécifiques</b> lié à ce prompt <b>seront aussi supprimés</b>.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deletePromptMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction onClick={deletePrompt} disabled={deletePromptMutation.isPending}>
            Oui. Supprimer !
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

function DeletePromptAssignementButton({ id }: { id: string }) {
  const deletePromptAssignementMutation = useDeletePromptAssignement()

  const navigate = useNavigate()
  const { toast } = useToast()

  const unlink = async () => {
    deletePromptAssignementMutation.mutate(
      { id: id },
      {
        onSuccess() {
          navigate('/prompts')

          toast({
            title: 'Prompt supprimé',
            variant: 'default',
          })
        },
      },
    )
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" size="icon">
          <Unlink className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
          <AlertDialogDescription>Ce prompt sera délié pour tous les utilisateurs.</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deletePromptAssignementMutation.isPending}>Annuler</AlertDialogCancel>
          <AlertDialogAction onClick={unlink} disabled={deletePromptAssignementMutation.isPending}>
            Oui. Délié !
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
