import { Badge } from '@/components/ui/badge'
import type { MeetingPayload } from '@/types/others.types'
import { MeetingType } from '@/types/others.types'
import { classNames, displayParticipants, durationAsMinutes, formatDate } from '@/utils'
import { translateBotStatus, translateMeetingStatus, translateMeetingType } from '@/utils/translate'
import type { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { CalendarCheck, CalendarClock } from 'lucide-react'

type ExtendedMeetingPayload = MeetingPayload & { isFuture: boolean }

export const columns: ColumnDef<ExtendedMeetingPayload>[] = [
  /*   {
    accessorKey: "isFuture",
    cell: ({ row }) => {
      return row.getValue("isFuture") ? "oui" : "non";
    },
    
  }, */
  {
    id: 'type_status',
    accessorKey: 'type',
    header: undefined,
    size: 30,
    cell: ({ row }) => {
      if (row.original.type === 'BOT' && dayjs(row.original.bot?.joinAt).isAfter(dayjs())) {
        return <CalendarClock className="h-5 w-5 text-orange-400" />
      }
      return <CalendarCheck className="h-5 w-5 text-green-600" />
    },
  },
  {
    accessorKey: 'title',
    header: () => <div className="">Titre</div>,
    cell: ({ row }) => {
      const title: string = row.getValue('title') ?? 'Réunion sans titre'

      return (
        <>
          <div className="text-left font-medium line-clamp-1" title={title}>
            {title}
          </div>
          <div className="mt-0 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            {row.original.transcript?.duration ? (
              <p className="whitespace-nowrap">{durationAsMinutes(row.original.transcript?.duration, 'min')}</p>
            ) : (
              <p className="whitespace-nowrap">&nbsp;</p>
            )}

            {row.original.transcript?.nbSpeakers ? (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>

                <p className="whitespace-nowrap">{displayParticipants(row.original.transcript?.nbSpeakers)}</p>
              </>
            ) : (
              <p className="whitespace-nowrap">&nbsp;</p>
            )}
          </div>
        </>
      )
    },
  },
  {
    accessorKey: 'user',
    header: 'Crée par',
    size: 100,
    cell: ({ row }) => {
      return (
        <p
          className="line-clamp-2 break-word"
          title={row.original.user?.firstName ? `${row.original.user?.firstName} ${row.original.user?.lastName}` : row.original.user?.email}
        >
          {row.original.user?.firstName ? `${row.original.user?.firstName} ${row.original.user?.lastName}` : row.original.user?.email}
        </p>
      )
    },
  },
  {
    accessorKey: 'type',
    header: 'Type',
    enableGlobalFilter: true,
    size: 20,
    cell: ({ row }) => {
      const color = () => {
        switch (row.getValue('type')) {
          case MeetingType.BOT:
            return 'bg-blue-300'
          case MeetingType.AUDIO_FILE:
            return 'bg-green-300'
          case MeetingType.LIVE_RECORDING:
            return 'bg-yellow-300'
          default:
            return 'bg-yellow-300'
            break
        }
      }

      return (
        <>
          <Badge className={classNames(color(), 'text-gray-600', `hover:${color()}`)}>{translateMeetingType(row.getValue('type'))}</Badge>
          {/* <p
          className={classNames(
            "text-gray-600 bg-gray-50 ring-gray-500/10",
            "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset",
          )}
        >
          {translateMeetingType(row.getValue("type"))}
        </p> */}
          {/* 
        {row.getValue("type") === "BOT" && (
          <div className="text-center">
            <br />
            <time className="truncate" dateTime={date}>
              {date}
            </time>
          </div>
        )} */}
        </>
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Statut',
    cell: ({ row }) => {
      if (row.original.type === 'BOT' && row.original.status === 'CREATED' && row.original.bot?.status?.code) {
        return translateBotStatus(row.original.bot?.status.code)
      }

      return (
        <p className="line-clamp-2" title={translateMeetingStatus(row.getValue('status'))}>
          {translateMeetingStatus(row.getValue('status'))}
        </p>
      )
    },
  },
  /* 
  {
    accessorKey: "joinAt",
    header: "Prévu le",
    size: 20,
    cell: ({ row }) => {
      const date = formatDate(row.original.bot?.joinAt, "DD MMM YYYY, HH[:]mm");

      return (
        <time className="truncate" dateTime={date}>
          {date}
        </time>
      );
    },
  }, */
  {
    accessorKey: 'createdAt',
    header: 'Date de la réunion',
    size: 20,
    cell: ({ row }) => {
      const date = formatDate(row.getValue('type') === 'BOT' ? row.original.bot?.joinAt : row.getValue('createdAt'), 'DD MMM YYYY, HH[:]mm')

      return (
        <time key={row.getValue('createdAt')} className="truncate" dateTime={date}>
          {date}
        </time>
      )
    },
  },
]
