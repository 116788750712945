import CustomLoader from "@/components/custom/CustomLoader";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useCreateFolder } from "@/hooks/useFolder";
import { logger } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";

const formSchema = z.object({
  folderName: z.string().min(1, "Le nom du dossier est requis").max(200, "Le nom du dossier doit comporter au maximum 200 caractères"),
});

export default function NewFolder() {
  const { toast } = useToast();
  const createFolder = useCreateFolder();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      folderName: "",
    },
  });

  const onSubmit = async ({ folderName }: z.infer<typeof formSchema>) => {
    logger({ folderName });

    return createFolder.mutate(
      { title: folderName },
      {
        onSuccess(data) {
          toast({
            title: "Dossier créé avec succès",
          });
          form.reset();
          navigate("/folders/" + data.id);
        },
        onError(error) {
          toast({
            title: "Erreur lors de la création du dossier",
            description: error?.message,
            variant: "destructive",
          });
        },
      },
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <FormField
              control={form.control}
              name="folderName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom du dossier</FormLabel>
                  <FormControl>
                    <Input placeholder="Nom du dossier" {...field} />
                  </FormControl>
                  <FormDescription>Nom du dossier à créer</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button type="submit" className="px-3" disabled={form.formState.isSubmitting}>
          {form.formState.isSubmitting ? <CustomLoader /> : "Créer"}
        </Button>
      </form>
    </Form>
  );
}
