import CustomLoader from '@/components/custom/CustomLoader'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Progress } from '@/components/ui/progress'
import { Separator } from '@/components/ui/separator'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import AddContext from '@/containers/others/AddContext'
import AddDefaultPrompt from '@/containers/others/AddDefaultPrompt'
import useAudioRecorder from '@/hooks/useAudioRecorder'
import { useAbortMultipartUpload, useCompleteMultipartUpload, useListParts, useSignPart } from '@/hooks/useMeeting'
import { useGetSignedUrl } from '@/hooks/useMeeting'
import { useMicPermission } from '@/hooks/useMicPermission'
import useNoSleep from '@/hooks/useNoSleep'
import { db } from '@/lib/localforage'
import { recording, uploading } from '@/lib/states'
import type { MeetingQueryResult } from '@/types/others.types'
import { logger } from '@/utils'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import { useUppyState } from '@uppy/react'
import { Crisp } from 'crisp-sdk-web'
import saveAs from 'file-saver'
import { useAtom } from 'jotai'
import { Circle, CircleAlert, CircleHelp, Loader, Mic, Pause, Square, Trash, Upload } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import { useLocalStorage } from 'usehooks-ts'
import ChangeLanguage from '../../others/ChangeLanguage'
import ChooseAudioSource from './Recording/ChooseAudioSource'

type Props = {
  meeting: MeetingQueryResult
}

export default function TranscriptLiveRecording({ meeting }: Props) {
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined)
  const [keepAwake, setKeepAwake] = useState<boolean>(true)

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  const [, setIsRecording] = useAtom(recording)
  const [, setIsUploading] = useAtom(uploading)

  const queryKeyBlob = ['existing-blob', meeting.data?.id]

  const { toast } = useToast()
  const queryClient = useQueryClient()

  const mic = useMicPermission(deviceId)

  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused, recordingTime, mediaRecorder } =
    useAudioRecorder({ deviceId: deviceId /* TODO : Should we enable it ? echoCancellation: true, noiseSuppression: true */ }, (err) => {
      console.error('ERROR NOT ALLOWED : ', err)
    })

  const nosleep = useNoSleep(keepAwake && isRecording)

  const existingBlob = useQuery<Blob | null>({
    queryKey: queryKeyBlob,
    queryFn: async () => {
      return db.getItem(`blob_${meeting.data?.id}`)
    },
    networkMode: 'always',
  })

  const deleteCurrentBlob = useMutation({
    mutationKey: ['delete-current-blob', meeting.data?.id],
    mutationFn: async () => {
      await db.removeItem(`blob_${meeting.data?.id}`)
      await queryClient.invalidateQueries({ queryKey: queryKeyBlob })
      uploadMutation.reset()
    },
    networkMode: 'always',
    onError(error, _variables, _context) {
      logger('error : ', error)
      const newError = new Error(`Failed to delete-current-blob for meeting ${meeting.data?.id}`, {
        cause: error,
      })
      console.error(newError)
      Sentry.captureException(newError)
    },
  })

  const saveToIndexedDB = useMutation({
    mutationKey: ['save-to-indexedDB', meeting.data?.id],
    mutationFn: async (blob: Blob) => {
      await db.setItem(`blob_${meeting.data?.id}`, blob)
      await queryClient.invalidateQueries({ queryKey: queryKeyBlob })
    },
    networkMode: 'always',
    onError(error, variables, _context) {
      logger('error : ', error)
      const newError = new Error(`Failed to save-to-indexedDB for meeting ${meeting.data?.id}`)
      newError.cause = error
      console.error(newError)
      Sentry.captureException(newError, (scope) => {
        const contextFile = {
          size: variables?.size,
          name: variables?.name,
          fileType: variables?.type,
        }
        logger('contextFile : ', contextFile)
        scope.setContext('file', contextFile)
        return scope
      })
    },
  })

  const getSignedUrlMutation = useGetSignedUrl()
  const signPartMutation = useSignPart()
  const completeMultipartUploadMutation = useCompleteMultipartUpload()
  const listPartsMutation = useListParts()
  const abortMultipartUploadMutation = useAbortMultipartUpload()

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
      },
      debug: true,
    }).use(AwsS3, {
      shouldUseMultipart() {
        return true
      },
      getUploadParameters(file) {
        return Promise.resolve({
          method: 'POST',
          url: '',
          fields: {},
        })
      },
      createMultipartUpload: async (file) => {
        const response = await getSignedUrlMutation.mutateAsync({
          id: meeting.data?.id as string,
          fileType: file.type,
          fileExt: file.extension,
          multipart: true,
        })
        if ('uploadId' in response && 'key' in response) {
          return { uploadId: response.uploadId, key: response.key }
        }
        throw new Error('Invalid response from getSignedUrlMutation')
      },
      signPart: async (_file, { uploadId, key, partNumber }) => {
        const response = await signPartMutation.mutateAsync({
          id: meeting.data?.id as string,
          uploadId,
          key,
          partNumber,
        })
        return { url: response.signedUrl }
      },
      listParts: async (_file, { uploadId, key }) => {
        const response = await listPartsMutation.mutateAsync({
          id: meeting.data?.id as string,
          uploadId,
          key,
        })
        return response.parts
      },
      completeMultipartUpload: async (_file, { uploadId, key, parts }) => {
        const formattedParts = parts.map((part) => ({
          ETag: part.ETag as string,
          PartNumber: part.PartNumber as number,
        }))
        const response = await completeMultipartUploadMutation.mutateAsync({
          id: meeting.data?.id as string,
          uploadId,
          key,
          parts: formattedParts,
        })
        return response
      },
      abortMultipartUpload: async (_file, { uploadId, key }) => {
        try {
          await abortMultipartUploadMutation.mutateAsync({
            id: meeting.data?.id as string,
            uploadId,
            key,
          })
          console.log('Multipart upload aborted successfully')
        } catch (error) {
          console.error('Failed to abort multipart upload:', error)
        }
      },
    }),
  )

  const downloadLocalFile = () => {
    if (existingBlob.data) {
      saveAs(existingBlob.data, `meeting_${meeting.data?.id}.${existingBlob.data.type.split('/')?.[1]?.split(';')[0]}`)
    }
  }

  const uploadMutation = useMutation({
    mutationFn: async (blob: Blob) => {
      if (uppy.getFiles().length > 0) {
        uppy.removeFile(uppy.getFiles()[0].id)
      }
      const file = new File([blob], `meeting_${meeting.data?.id}`, { type: blob.type })
      uppy.addFile({
        name: file.name,
        type: file.type,
        data: file,
      })
      const res = await uppy.upload()
      return res
    },
  })

  const saveAndUploadFile = async (blob: Blob) => {
    if (!meeting.data?.id) {
      throw new Error('meeting.data?.id undefined')
    }
    logger('saveAndUploadFile | blob: ', blob)

    try {
      const res = await uploadMutation.mutateAsync(blob)
      logger('Upload response:', res)

      if (res?.successful?.[0]) {
        await deleteCurrentBlob.mutateAsync()
        await queryClient.invalidateQueries({ queryKey: ['meetings', meeting.data?.id] })

        toast({
          title: 'Fichier envoyé avec succès',
          description: 'Le fichier a été uploadé et le transcript va être créé.',
          variant: 'default',
        })
      } else {
        throw new Error(`Upload failed : ${res?.failed?.[0]?.error}`)
      }
    } catch (error) {
      console.error('Upload error:', error)
      Sentry.captureException(error)
      toast({
        title: "Erreur lors de l'envoi du fichier",
        description: error?.response?.data?.message || "Une erreur s'est produite pendant l'upload.",
        variant: 'destructive',
      })
    }
  }

  const totalProgress = useUppyState(uppy, (state) => state.totalProgress)

  useEffect(() => {
    setIsRecording(isRecording)
    return () => {
      setIsRecording(false)
    }
  }, [isRecording, setIsRecording])

  useEffect(() => {
    setIsUploading(uploadMutation.isPending)
    return () => {
      setIsUploading(false)
    }
  }, [uploadMutation.isPending, setIsUploading])

  useEffect(() => {
    if (!recordingBlob) return
    ;(async () => {
      try {
        await Promise.all([saveToIndexedDB.mutateAsync(recordingBlob), saveAndUploadFile(recordingBlob)])
      } catch (error) {
        logger('Failed to saveToIndexedDB. : ', error)
      }
    })()
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob])

  const [file, setFile] = useState()

  async function handleChange(event) {
    setFile(event.target.files[0])

    const recordingBlob = event.target.files[0] as File

    try {
      await saveToIndexedDB.mutateAsync(new Blob([new Uint8Array(await recordingBlob.arrayBuffer())]))
    } catch (error) {
      logger('Failed to saveToIndexedDB. : ', error)
    }

    // saveAndUploadFile(recordingBlob)
  }

  const renderButtons = useMemo(() => {
    if (isRecording) {
      return (
        <div className="flex flex-row gap-2 w-full">
          {isPaused ? (
            <Button size="lg" className="w-full" variant="destructive" onClick={togglePauseResume}>
              <Mic className="h-5 w-5 mr-2" /> Reprendre
            </Button>
          ) : (
            <Button size="lg" className="w-full" variant="outline" onClick={togglePauseResume}>
              <Pause className="h-5 w-5 mr-2" /> Mettre en pause
            </Button>
          )}

          <Button size="lg" className="w-full" variant="outline" onClick={stopRecording}>
            <Square className="h-4 w-4 mr-2 bg-black" /> Arrêter
          </Button>
        </div>
      )
    } else {
      return (
        <Button size="lg" className="w-full" variant="destructive" onClick={startRecording}>
          <Mic className="h-5 w-5 mr-2" /> Démarrer l'enregistrement
        </Button>
      )
    }
  }, [isRecording, isPaused])

  const renderUpload = useMemo(() => {
    if (uploadMutation.isPending || saveToIndexedDB.isPending) {
      return (
        <Alert variant="default">
          <Loader className="h-4 w-4 animate-spin" />
          <AlertTitle>Fichier en cours d'enregistrement...</AlertTitle>
          <AlertDescription>
            L'enregistrement est en cours d'envoi vers le serveur.
            <br />
            <br />
            <Progress value={totalProgress} indicatorColor="bg-indigo-600" />
          </AlertDescription>
        </Alert>
      )
    } else if (uploadMutation.isError) {
      return (
        <Alert variant="destructive">
          <Upload className="h-4 w-4" />
          <AlertTitle>Erreur lors de l'envoi de l'enregistrement vers le serveur.</AlertTitle>
          <AlertDescription>
            <Button variant="outline" className="mt-2" onClick={() => Crisp.chat.open()}>
              Besoin d'aide ?
            </Button>
          </AlertDescription>
        </Alert>
      )
    }
  }, [uploadMutation.isPending, uploadMutation.isError, saveToIndexedDB.isPending, totalProgress])

  const render = () => {
    if ((existingBlob.data || recordingBlob) && !uploadMutation.isPending && !saveToIndexedDB.isPending) {
      return (
        <Alert variant="default">
          <CircleAlert className="h-4 w-4" />
          <AlertTitle>Un enregistrement existe déjà</AlertTitle>
          <AlertDescription>
            Il existe déjà un enregistrement audio pour cette réunion.
            <br />
            <br />
            <div className="flex flex-row gap-2">
              <Button
                size="sm"
                onClick={() => {
                  existingBlob.data ? saveAndUploadFile(existingBlob.data) : recordingBlob && saveAndUploadFile(recordingBlob)
                }}
              >
                Transcrire
              </Button>
              <Button
                size="sm"
                disabled={deleteCurrentBlob.isPending}
                onClick={() => {
                  deleteCurrentBlob.mutate()
                }}
                variant="outline"
              >
                {deleteCurrentBlob.isPending ? (
                  <CustomLoader />
                ) : (
                  <div className="text-red-500 inline-flex items-center">
                    <Trash className="h-4 w-4 mr-2 mt-0 " /> <span>Supprimer</span>
                  </div>
                )}
              </Button>

              <Button size="sm" variant="link" onClick={downloadLocalFile}>
                Télécharger
              </Button>
            </div>
          </AlertDescription>
        </Alert>
      )
    } else if (!uploadMutation.isPending && !saveToIndexedDB.isPending) {
      return mic.isLoading ? (
        <CustomLoader centered />
      ) : mic.data ? (
        <div className="flex flex-col gap-3">
          {!isRecording && (
            <div className="flex justify-center">
              <ChooseAudioSource setDeviceId={setDeviceId} deviceId={deviceId} />
            </div>
          )}

          {isRecording && (
            <div className="flex justify-center gap-3 items-center">
              <Circle fill="red" className={`h-3 w-3 ${!isPaused && 'animate-ping'} ${!isPaused && 'text-red-500'}`} />{' '}
              {isPaused ? 'Enregistrement en pause' : "En cours d'enregistrement"}
            </div>
          )}
          <div className="flex justify-center">
            {mediaRecorder && <LiveAudioVisualizer barColor="rgb(79 70 229)" mediaRecorder={mediaRecorder} width={300} height={75} />}
          </div>

          <div className="flex justify-center">{renderButtons}</div>

          {isRecording && (
            <>
              <Separator className="my-2" />
              <span>
                <b>Votre écran doit rester allumer.</b> Ne vérrouillez pas votre écran.
              </span>
              <div className="flex items-center space-x-2">
                <Switch id="keep-awake" checked={keepAwake} onCheckedChange={() => setKeepAwake(!keepAwake)} />
                <Label htmlFor="keep-awake">Empêcher la mise en veille de l'écran</Label>
                <Popover>
                  <PopoverTrigger>
                    <Button variant="ghost" size="icon">
                      <CircleHelp className="h-4 w-4" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <h3>Votre écran doit rester allumé</h3>
                    <p className="text-sm">
                      Pour que l'enregistrement fonctionne, votre écran ne doit pas se mettre en veille.
                      <br />
                      Sur mobile, restez sur cette page pour ne pas interrompre l'enregistrement.
                      <br />
                      <br />
                      Désactivez l'option pour ne pas empêcher la mise en veille. Attention, l'enregistrement risque de ne pas fonctionner.
                    </p>
                  </PopoverContent>
                </Popover>
              </div>
            </>
          )}
        </div>
      ) : (
        <Alert variant="destructive">
          <CircleAlert className="h-4 w-4" />
          <AlertTitle>Impossible d'accéder au micro</AlertTitle>
          <AlertDescription>Vous devez autoriser l'accès à votre micro pour pouvoir démarrer l'enregistrement.</AlertDescription>
        </Alert>
      )
    }
  }

  return (
    <div className="flex flex-col xl:w-2/4 md:w-2/3 sm:w-2/3 w-full self-center gap-3">
      {superAdmin && <input type="file" onChange={handleChange} />}

      {renderUpload}

      {render()}

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} className="md:w-full" />

            <AddDefaultPrompt meeting={meeting} className="md:w-full" />

            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
