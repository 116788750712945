import CustomLoader from '@/components/custom/CustomLoader'
import { DateTimePickerDemo } from '@/components/custom/date-time-picker-demo'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import AddContext from '@/containers/others/AddContext'
import AddDefaultPrompt from '@/containers/others/AddDefaultPrompt'
import { useCreateBotForMeeting } from '@/hooks/useMeeting'
import type { MeetingQueryResult, PossibleError, ValidationError } from '@/types/others.types'
import { logger } from '@/utils'
import { useOrganization } from '@clerk/clerk-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import ChangeLanguage from '../../others/ChangeLanguage'

const formSchema = yup.object({
  meetingUrl: yup.string().required("Veuillez entrer l'url de la réunion"),
  joinAt: yup.date().required(),
  botName: yup.string().required(),
})

type FormType = yup.InferType<typeof formSchema>

type Props = {
  meeting: MeetingQueryResult
}
export default function TranscriptCreateBot({ meeting }: Props) {
  const { toast } = useToast()

  const { organization } = useOrganization()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      joinAt: new Date(),
      botName: organization?.name ?? 'Notae',
    },
  })

  const createBotMutation = useCreateBotForMeeting()

  const now = () => {
    form.setValue('joinAt', new Date())
  }

  const onSubmit = async ({ meetingUrl, botName, joinAt }: FormType) => {
    if (!meeting.data?.id) {
      throw new Error('meeting.data?.id undefined')
    }

    return createBotMutation.mutate(
      { id: meeting.data.id, meetingUrl, botName, joinAt },
      {
        onSuccess: async () => {
          logger('Bot created.')
        },
        onError(error: PossibleError) {
          logger('onError : ', error)
          if (error?.response?.data && Array.isArray(error.response.data)) {
            for (const err of error.response.data) {
              if (err.path) {
                // Remove the leading slash from the path and replace it with an empty string to match the form field names
                const fieldName = err.path.replace(/^\//, '')
                form.setError(fieldName as keyof FormType, {
                  message: err.message,
                })
              }
            }
          }
          toast({
            title: 'Erreur lors de la création de la réunion',
            description: error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <div className="flex flex-col w-full gap-2">
      <FormProvider {...form}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
            <FormField
              control={form.control}
              name="meetingUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Lien</FormLabel>
                  <FormControl>
                    <Input className="md:w-8/12" placeholder="Collez le lien d'invitation à la réunion teams" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex-col gap-4 pt-2">
              <FormField
                control={form.control}
                name="joinAt"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date et heure de connexion</FormLabel>
                    <FormControl>
                      <DateTimePickerDemo date={field.value} setDate={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button variant="ghost" size="sm" onClick={now}>
                Maintenant
              </Button>
            </div>

            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
                <AccordionContent className="space-y-3 mx-1">
                  <FormField
                    control={form.control}
                    name="botName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Nom visible sur Teams</FormLabel>
                        <FormControl>
                          <Input className="md:w-3/12" placeholder="Nom visible sur teams" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <ChangeLanguage meeting={meeting} />

                  <AddDefaultPrompt meeting={meeting} />

                  <AddContext meeting={meeting} />
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <br />

            <Button type="submit" disabled={createBotMutation.isPending}>
              {createBotMutation.isPending ? <CustomLoader /> : 'Créer le bot'}
            </Button>
          </form>
        </Form>
      </FormProvider>
    </div>
  )
}
