import type { Prisma } from '@/types'
import { API_URL } from '@/utils'
import { useAuth } from '@clerk/clerk-react'
import type { GetToken } from '@clerk/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useLocalStorage } from 'usehooks-ts'

export function useAsOrg() {
  const [asOrg, setAsOrg] = useLocalStorage<string | null>('asOrg', null)
  return { asOrg, setAsOrg }
}

export function useAsUser() {
  const [asUser, setAsUser] = useLocalStorage<string | null>('asUser', null)
  return { asUser, setAsUser }
}

export const getHeaders = async (getToken: GetToken, asUser: string | null, asOrg: string | null) => {
  const token = await getToken()
  const headers: Record<string, string> = {
    Authorization: `Bearer ${token}`,
  }

  if (asUser) {
    headers['X-Impersonate-User'] = asUser
  }
  if (asOrg) {
    headers['X-Impersonate-Org'] = asOrg
  }

  return headers
}

export function useUsers() {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchUsers = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<
      Prisma.UserGetPayload<{
        include: {
          organizations: true
        }
      }>[]
    >(`${API_URL}/users`, { headers })

    return response
  }

  return useQuery({
    queryKey: ['users'],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchUsers().then((result) => result.data)
    },
  })
}

export function useUser() {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchUser = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<Prisma.UserGetPayload<true>>(`${API_URL}/users/me`, { headers })

    return response
  }

  return useQuery({
    queryKey: ['user'],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchUser().then((result) => result.data)
    },
  })
}

export function useOrg() {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchOrg = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<Prisma.OrganizationGetPayload<true>>(`${API_URL}/orgs/me`, { headers })

    return response
  }

  return useQuery({
    queryKey: ['org'],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchOrg().then((result) => result.data)
    },
  })
}

export function useOrgs() {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchOrgs = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<
      Prisma.OrganizationGetPayload<{
        include: {
          users: true
        }
      }>[]
    >(`${API_URL}/orgs`, { headers })

    return response
  }

  return useQuery({
    queryKey: ['orgs'],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchOrgs().then((result) => result.data)
    },
  })
}

export const useImpersonateUser = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const impersonateUser = async (userId: string) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post(`${API_URL}/users/${userId}/impersonate`, {}, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['impersonate-user'],
    mutationFn: impersonateUser,
  })

  return mutation
}

export const useUpdateUser = () => {
  const { getToken } = useAuth()
  const queryClient = useQueryClient()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const updateUser = async ({ data }: { data: Prisma.UserUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.put(`${API_URL}/users/me`, { ...data }, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-user'],
    mutationFn: updateUser,
    onSettled() {
      return queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  return mutation
}

export const useUpdateOrg = () => {
  const { getToken } = useAuth()
  const queryClient = useQueryClient()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const updateOrg = async ({ data }: { data: Prisma.OrganizationUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.put(`${API_URL}/orgs/me`, { ...data }, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-org'],
    mutationFn: updateOrg,
    onSettled() {
      return queryClient.invalidateQueries({ queryKey: ['org'] })
    },
  })

  return mutation
}
