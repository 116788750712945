import Button from '@/components/custom/Button'
import CustomLoader from '@/components/custom/CustomLoader'
import { DataTable } from '@/components/custom/DataTable'
import { UploadFile } from '@/components/custom/UploadFile'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { useFiles } from '@/hooks/useFile'
import { Rainbow } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'
import { filesColumns } from './FilesColumns'

export default function Files() {
  const [onlyMine, toggleOnlyMine] = useToggle(true)

  const files = useFiles()

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-2xl">Fichiers</h2>

        <UploadFile />
      </div>
      <br />

      <div className="flex flex-row justify-end items-center gap-3">
        <Label>Afficher uniquement mes fichiers</Label> <Switch onCheckedChange={toggleOnlyMine} checked={onlyMine} />
      </div>
      <br />
      {files.isLoading ? (
        <CustomLoader centered />
      ) : files.data?.length ? (
        <>
          <DataTable type="files" columns={filesColumns} data={files.data} />
        </>
      ) : (
        <Alert>
          <Rainbow className="h-4 w-4" />
          <AlertTitle>Uploadez votre premier fichier</AlertTitle>
          <AlertDescription>
            Vous n'avez encore aucun fichier.
            <br />
            <br />
            <UploadFile />
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}
