import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useUpdateFolder } from '@/hooks/useFolder'
import type { FolderQueryResult } from '@/types/others.types'
import { logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Edit, Save, X } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocalStorage } from 'usehooks-ts'
import * as yup from 'yup'

import { useToast } from '../../ui/use-toast'
import Button from '../Button'

type Props = {
  folder: FolderQueryResult
}

const formSchema = yup.object({
  title: yup.string(),
})

type FormType = yup.InferType<typeof formSchema>

export default function FoldersEditTitle({ folder }: Props) {
  const mutationUpdateFolder = useUpdateFolder()

  const { toast } = useToast()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: folder.data?.title ?? '',
    },
  })

  const [editing, setEditing] = useState(false)

  // 2. Define a submit handler.
  const onSubmit = async ({ title }: FormType) => {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    logger('edit title : ', { title })

    await mutationUpdateFolder.mutateAsync(
      { id: folder.data?.id, folder: { title } },
      {
        onSuccess: async (data) => {
          toast({
            title: 'Titre enregistré',
          })
          setEditing(false)
        },
        onError(error) {
          form.setError('root.serverError', {
            message: error?.response?.data?.message ?? error?.message,
          })
          toast({
            title: "Erreur lors de l'enregistrement du titre",
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  if (!folder.data) {
    return <></>
  }

  return editing ? (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-row gap-2">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="md:w-2/4 w-full">
              <FormControl>
                <Input placeholder="Nom du dossier" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit" variant="outline" size="icon">
          <Save className="h-4 w-4" />
        </Button>
        <Button type="button" onClick={() => setEditing(false)} variant="outline" size="icon">
          <X className="h-4 w-4" />
        </Button>
      </form>
    </Form>
  ) : (
    <span>
      <span className="hover:cursor-text" onClick={() => setEditing(true)}>
        {folder.data?.title ?? 'Dossier sans titre'}
      </span>

      <Button variant="ghost" size="icon" className="ml-2" onClick={() => setEditing(true)} disabled={false}>
        <Edit className="h-4 w-4" />
      </Button>
    </span>
  )
}
