import React from 'react'

import { Button as OriginalButton } from '../ui/button'
import CustomLoader from './CustomLoader'

interface ButtonLoadingProps extends React.ComponentPropsWithoutRef<typeof OriginalButton> {
	isLoading?: boolean
	loaderSize?: number
}

const Button = React.forwardRef<React.ElementRef<typeof OriginalButton>, ButtonLoadingProps>(
	({ isLoading, loaderSize = 14, ...props }, ref) => {
		return (
			<OriginalButton ref={ref} {...props} disabled={isLoading || props.disabled}>
				{isLoading ? <CustomLoader size={loaderSize} /> : props.children}
			</OriginalButton>
		)
	},
)

Button.displayName = 'ButtonLoading'

export default Button
