import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSeparator, MenubarTrigger } from '@/components/ui/menubar'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useToast } from '@/components/ui/use-toast'
import { MeetingQueryResult, TranscriptLinePayload } from '@/types/others.types'
import { displayParticipants, durationAsMinutes, formatDate, logger } from '@/utils'
import { Copy, CopyCheck } from 'lucide-react'
import { useState } from 'react'
import { useCopyToClipboard, useLocalStorage } from 'usehooks-ts'

type Props = {
	meeting: MeetingQueryResult
}

const getSpeakerColor = (speakerId: number | null | undefined) => {
	if (!speakerId) return 'gray'
	// Vous pouvez personnaliser davantage les couleurs si nécessaire
	const colors = ['blue', 'green', 'red', 'yellow', 'purple', 'pink']
	return colors[speakerId % colors.length]
}

function mergeBySpeaker(transcriptLines: TranscriptLinePayload[]): TranscriptLinePayload[] {
	const merged: TranscriptLinePayload[] = []
	let currentGroup: TranscriptLinePayload | null = null
	let currentConfidenceSum = 0
	let lineCount = 0

	transcriptLines.forEach((line) => {
		if (currentGroup && line.speaker === currentGroup.speaker) {
			currentGroup.text += ' ' + line.text
			currentGroup.timeEnd = line.timeEnd
			if (line.confidence) currentConfidenceSum += line.confidence
			lineCount++
		} else {
			if (currentGroup) {
				if (currentConfidenceSum) currentGroup.confidence = currentConfidenceSum / lineCount
				merged.push(currentGroup)
			}
			currentGroup = { ...line }
			if (line.confidence) currentConfidenceSum = line.confidence
			lineCount = 1
		}
	})

	if (currentGroup) {
		if (currentConfidenceSum) currentGroup.confidence = currentConfidenceSum / lineCount
		merged.push(currentGroup)
	}

	return merged
}

/* 
 - TimeStart : ${line.timeBegin.toFixed(3)}${
        line.confidence ? `- Confidence : ${line.confidence?.toFixed(3)}` : ``
      }
*/

const getText = (transcriptLines: TranscriptLinePayload[]) => {
	return transcriptLines
		.map((line) => {
			return `Intervenant : ${line.speaker}\n${line.text}`
		})
		.join('\n\n')
}

const getJson = (transcriptLines: TranscriptLinePayload[]) => {
	return JSON.stringify(
		transcriptLines.map((l) => ({
			text: l.text,
			language: l.language,
			confidence: l.confidence,
			timeBegin: l.timeBegin,
			timeEnd: l.timeEnd,
			speaker: l.speaker,
		})),
		null,
		4,
	)
}

export default function TranscriptReady({ meeting }: Props) {
	const transcriptLines = meeting.data?.transcript?.transcriptLine
	const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

	const [, copy] = useCopyToClipboard()
	const { toast } = useToast()

	const [copied, setCopied] = useState<boolean>(false)

	if (!transcriptLines) {
		return <></>
	}

	const groupedLines = mergeBySpeaker(transcriptLines)
	logger('groupedLines: ', groupedLines)

	const textGrouped = getText(groupedLines)
	const jsonGrouped = getJson(groupedLines)

	const textNotGrouped = getText(transcriptLines)
	const jsonNotGrouped = getJson(transcriptLines)

	const copyAndNotify = (value: string) => {
		copy(value)

		setCopied(true)
		setTimeout(() => {
			setCopied(false)
		}, 4000)

		toast({
			title: 'Copié !',
		})
	}

	return (
		<>
			<Card>
				<CardHeader>
					<CardTitle>
						<div className="flex flex-row justify-between items-center">
							<div className="flex flex-col">
								<div className="mb-1">Transcript</div>
								<div className="flex items-center gap-x-2 text-xs font-light leading-5 text-gray-500">
									{!!meeting.data?.transcript?.duration && (
										<>
											<p className="whitespace-nowrap">{durationAsMinutes(meeting.data?.transcript?.duration, 'min')}</p>
										</>
									)}

									{!!meeting.data?.transcript?.nbSpeakers && (
										<>
											<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
												<circle cx={1} cy={1} r={1} />
											</svg>

											<p className="whitespace-nowrap">{displayParticipants(meeting.data?.transcript?.nbSpeakers)}</p>
										</>
									)}
								</div>
								<div className="text-xs font-light leading-5 text-gray-500 ">
									Généré le {formatDate(meeting.data?.transcript?.updatedAt)}
								</div>
							</div>
							<div className="flex gap-1">
								{!!superAdmin && (
									<Menubar className="w-fit">
										<MenubarMenu>
											<MenubarTrigger>[ADMIN] Actions</MenubarTrigger>
											<MenubarContent>
												<MenubarItem onClick={() => copyAndNotify(textNotGrouped)}>Copier le texte (non groupé)</MenubarItem>
												<MenubarItem onClick={() => copyAndNotify(jsonNotGrouped)}>Copier le JSON (non groupé)</MenubarItem>
												<MenubarSeparator />

												<MenubarItem onClick={() => copyAndNotify(textGrouped)}>Copier le texte (groupé)</MenubarItem>
												<MenubarItem onClick={() => copyAndNotify(jsonGrouped)}>Copier le JSON (groupé)</MenubarItem>
											</MenubarContent>
										</MenubarMenu>
									</Menubar>
								)}

								<Button variant="outline" onClick={() => copyAndNotify(textGrouped)}>
									{copied ? (
										<>
											<CopyCheck className="md:mr-2 h-4 w-4" />
											<span className="hidden md:block">Copié !</span>
										</>
									) : (
										<>
											<Copy className="md:mr-2 h-4 w-4" />
											<span className="hidden md:block">Copier</span>
										</>
									)}
								</Button>
								{/*                 <Button onClick={saveAsWord}>saveAsWord</Button>
								 */}
								{/* 
                <Button variant="outline" disabled={loadingWord} onClick={downloadAsWord}>
                  <FileText className="mr-2 h-4 w-4" />
                  {loadingWord ? <CustomLoader /> : "Télécharger en Word"}
                </Button> */}
							</div>
						</div>
					</CardTitle>
				</CardHeader>
				<CardContent>
					<ScrollArea className="h-[500px] mt-0">
						<div className="absolute top-0 left-0 w-full h-[50px] bg-gradient-to-t from-transparent to-white pointer-events-none"></div>
						<br />

						<div className="space-y-4 overflow-auto">
							{groupedLines.map(({ timeBegin, text, speaker }) => (
								<div key={timeBegin} className="group">
									<div className={`flex items-center space-x-2`}>
										<div className={`h-1 w-1 rounded-full bg-${getSpeakerColor(speaker)}-600`}></div>
										<p className="text-sm text-gray-500">{`Intervenant ${speaker}`}</p>
										<p className="text-xs text-gray-500"> - {`${timeBegin.toFixed(1)}s`}</p>
									</div>
									<p className="transition-opacity duration-300 opacity-100">{text}</p>
								</div>
							))}
						</div>
						<br />
						<br />
						<div className="absolute bottom-0 left-0 w-full h-[100px] bg-gradient-to-b from-transparent to-white pointer-events-none"></div>
					</ScrollArea>
				</CardContent>
			</Card>
		</>
	)
}
