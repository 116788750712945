import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { logger } from '@/utils'
import { json } from '@codemirror/lang-json'
import { xml } from '@codemirror/lang-xml'
import { EditorView } from '@codemirror/view'
import CodeMirror from '@uiw/react-codemirror'
import { Eta } from 'eta'
import { useState } from 'react'
import { Card, CardContent } from '../ui/card'
import { Separator } from '../ui/separator'
import Button from './Button'

const eta = new Eta({
  autoEscape: false,
})

export default function CodeEditor({
  content = '',
  variables = {},
  onChange,
  disabled,
}: { content?: string; variables?: object; onChange: (value: string) => void; disabled?: boolean }) {
  const [renderResult, setRenderResult] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const renderContent = () => {
    try {
      const result = eta.renderString(content, variables)
      logger('result : ', result)
      setRenderResult(result)
      setError(null)
    } catch (err) {
      setRenderResult(null)
      setError(err?.message || 'An error occurred during rendering')
    }
  }
  return (
    <>
      <Dialog onOpenChange={() => renderContent()}>
        <DialogTrigger className="self-end" asChild>
          <Button variant="link" size="sm" className="text-blue-800">
            Preview
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-[90%]">
          <DialogHeader>
            <DialogTitle>Preview</DialogTitle>
            <DialogDescription>
              <Card>
                <CardContent className="p-2 overflow-auto">
                  {error ? (
                    <span className="text-red-500">{error}</span>
                  ) : (
                    <CodeMirror editable={false} value={renderResult} height="400px" extensions={[xml(), EditorView.lineWrapping]} />
                  )}
                </CardContent>
              </Card>
              <Separator className="my-4 w-2/3 mx-auto" />
              <Card>
                <CardContent className="p-2 overflow-auto max-h-[250px]">
                  <CodeMirror
                    editable={false}
                    value={JSON.stringify(variables, null, 2)}
                    height="200px"
                    extensions={[json(), EditorView.lineWrapping]}
                  />
                </CardContent>
              </Card>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      {/* <Textarea
        disabled={disabled}
        value={content}
        onChange={(e) => onChange(e.target.value)}
        className="min-h-[150px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 overflow-auto"
      /> */}
      <CodeMirror
        editable={!disabled}
        value={content}
        height="auto"
        className="max-h-[400px] overflow-auto"
        extensions={[xml(), EditorView.lineWrapping]}
        onChange={onChange}
      />
    </>
  )
}
