// ColumnsFolders.tsx
import { Badge } from '@/components/ui/badge'
import type { FolderPayload } from '@/types/others.types'
import type { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

export const foldersColumns: ColumnDef<FolderPayload>[] = [
  {
    accessorKey: 'title',
    header: () => <div className="">Titre</div>,
    cell: ({ row }) => {
      const title: string = row.getValue('title') ?? 'Dossier sans titre'

      return (
        <>
          <div className="text-left font-semibold">{title}</div>
          <div className="text-xs text-gray-500">
            {row.original.sources.length ?? 0} source{row.original.sources?.length > 1 ? 's' : ''}
          </div>
        </>
      )
    },
  },
  {
    accessorKey: 'user',
    header: 'Crée par',
    cell: ({ row }) => {
      return (
        <div className="">
          {row.original.user?.firstName ? `${row.original.user?.firstName} ${row.original.user?.lastName}` : row.original.user?.email}
        </div>
      )
    },
  } /* 
  {
    accessorKey: "status",
    header: "Statut",
    cell: ({ row }) => {
      return <Badge className="text-gray-600">{row.getValue("status")}</Badge>;
    },
  }, */,
  {
    accessorKey: 'createdAt',
    header: 'Date de création',
    size: 20,
    cell: ({ row }) => {
      const date = dayjs(row.getValue('createdAt')).format('DD MMM YYYY, HH:mm')

      return (
        <time key={row.getValue('createdAt')} className="truncate" dateTime={date}>
          {date}
        </time>
      )
    },
  },
]
