import { logger } from '@/utils'
import { SignIn, SignUp, useAuth } from '@clerk/clerk-react'
import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export function SignInPage() {
	const { isLoaded, userId } = useAuth()

	const [, setImpersonate] = useLocalStorage('impersonate', false)

	useEffect(() => {
		if (isLoaded && !userId) {
			logger('Clear impersonate')
			setImpersonate(false)
		}
	}, [isLoaded, setImpersonate, userId])

	return (
		<div className="grid place-items-center h-screen">
			<SignIn />
		</div>
	)
}
export function SignUpPage() {
	return (
		<div className="grid place-items-center h-screen">
			<SignUp />
		</div>
	)
}
