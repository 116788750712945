import Button from '@/components/custom/Button'
import CustomLoader from '@/components/custom/CustomLoader'
import { DateTimePickerDemo } from '@/components/custom/date-time-picker-demo'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import AddContext from '@/containers/others/AddContext'
import AddDefaultPrompt from '@/containers/others/AddDefaultPrompt'
import ChangeLanguage from '@/containers/others/ChangeLanguage'
import { useCreateBotForMeeting } from '@/hooks/useMeeting'
import { useUpdateMeeting } from '@/hooks/useMeeting'
import { useStopBotForMeeting } from '@/hooks/useMeeting'
import type { MeetingQueryResult } from '@/types/others.types'
import { formatDate, logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Crisp } from 'crisp-sdk-web'
import { AlertCircle, AudioLines, Loader } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocalStorage } from 'usehooks-ts'
import * as yup from 'yup'
import TranscriptCreateBot from './TranscriptCreateBot'

type Props = {
  meeting: MeetingQueryResult
}

export default function TranscriptBot({ meeting }: Props) {
  return (
    <>
      {!meeting.data?.bot?.id && <TranscriptCreateBot meeting={meeting} />}
      {meeting.data?.bot?.status.code === 'READY' && <BotReady meeting={meeting} />}
      {['JOINING', 'STARTING_RECORDING'].includes(meeting.data?.bot?.status.code ?? '') && <BotJoining meeting={meeting} />}
      {meeting.data?.bot?.status.code === 'IN_WAITING_ROOM' && <BotWaiting meeting={meeting} />}
      {meeting.data?.bot?.status.code === 'IN_CALL_RECORDING' && <BotInCallRecording meeting={meeting} />}
      {meeting.data?.bot?.status.code === 'DONE' && <BotDone meeting={meeting} />}
    </>
  )
}

function BotNotCreated({ meeting }: Props) {
  const createBotMutation = useCreateBotForMeeting()

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  const createBot = async () => {
    await createBotMutation.mutateAsync(
      { id: meeting.data.id },
      {
        onSuccess(data) {
          logger('Bot created : ', data)
        },
        onError(error) {
          logger('Bot onError : ', error)
        },
      },
    )
  }

  return (
    <div className="w-full">
      <Alert variant="destructive">
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Erreur</AlertTitle>
        <AlertDescription>
          Le bot n'a pas été correctement créé.
          {superAdmin ? (
            <>
              <br />
              <br />
              <Button variant="outline" onClick={createBot} disabled={createBotMutation.isPending}>
                {createBotMutation.isPending ? <CustomLoader /> : 'Créer le bot'}
              </Button>
            </>
          ) : (
            <></>
          )}
        </AlertDescription>
      </Alert>
    </div>
  )
}

const formSchema = yup.object({
  botMeetingUrl: yup.string().required("Veuillez entrer l'url de la réunion"),
  botJoinAt: yup.date().required(),
  botName: yup.string().required(),
})

type FormType = yup.InferType<typeof formSchema>

function BotReady({ meeting }: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const updateMeetingMutation = useUpdateMeeting()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      botMeetingUrl: meeting.data?.bot?.meetingUrl,
      botJoinAt: new Date(meeting.data?.bot?.joinAt),
      botName: meeting.data?.bot?.name,
    },
  })

  const onSubmit = async (values: FormType) => {
    if (!meeting.data?.id) return

    await updateMeetingMutation.mutateAsync({
      id: meeting.data.id,
      meeting: values,
    })
    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
          <FormField
            control={form.control}
            name="botMeetingUrl"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lien</FormLabel>
                <FormControl>
                  <Input placeholder="Lien de la réunion Teams" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="botJoinAt"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Date et heure de connexion</FormLabel>
                <FormControl>
                  <DateTimePickerDemo date={field.value} setDate={field.onChange} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="botName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nom du bot</FormLabel>
                <FormControl>
                  <Input placeholder="Nom du bot" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-row gap-2">
            <Button type="submit" isLoading={updateMeetingMutation.isPending}>
              Mettre à jour
            </Button>
            <Button type="button" variant="outline" onClick={() => setIsEditing(false)}>
              Annuler
            </Button>
          </div>
        </form>
      </Form>
    )
  }

  return (
    <div className="w-full">
      <Alert>
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Bot Notae prêt</AlertTitle>
        <AlertDescription className="flex flex-col space-y-2">
          <div>
            Un bot ayant pour nom <b>{meeting.data?.bot?.name}</b> se connectera automatiquement à votre{' '}
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger className="underline hover:no-underline">réunion Teams</TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-lg">{meeting.data?.bot?.meetingUrl}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>{' '}
            le <b>{formatDate(meeting.data?.bot?.joinAt)}</b>.
          </div>
          <Button onClick={() => setIsEditing(true)} variant="default" size="sm" className="self-start">
            Modifier le bot
          </Button>
        </AlertDescription>
      </Alert>

      <Separator className="my-4 w-2/3 mx-auto" />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} />
            <AddDefaultPrompt meeting={meeting} />
            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

function BotInCallRecording({ meeting }: Props) {
  const stopBotMutation = useStopBotForMeeting()

  const handleStopBot = async () => {
    if (!meeting.data?.id) return
    await stopBotMutation.mutateAsync({ id: meeting.data.id })
  }

  return (
    <div className="w-full">
      <Alert>
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Réunion en cours de retranscription</AlertTitle>
        <AlertDescription className="leading-relaxed">
          Un bot Notae est actuellement en train de retranscrire votre réunion.
          <br />
          Le bot quittera la réunion si :
          <ul className="list-disc pl-4">
            {/* <li className="mb-1">l'organisateur quitte la réunion</li> */}
            <li className="mb-1">vous expulsez le bot de la réunion</li>
            <li className="mb-1">il n'y a plus personne dans la réunion pendant plus de 5 minutes</li>
            <li className="mb-1">aucun son n'est détecté dans la réunion pendant plus de 20 minutes</li>
            <li className="mb-1">l'organisateur met fin à la réunion</li>
            <li className="mb-1">vous arrêtez le bot via le bouton ci-dessous</li>
          </ul>
          <StopBotButton meeting={meeting} />
        </AlertDescription>
      </Alert>

      <Separator className="my-4 w-2/3 mx-auto" />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} />
            <AddDefaultPrompt meeting={meeting} />
            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

function BotJoining({ meeting }: Props) {
  return (
    <div className="w-full">
      <Alert>
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Bot Notae</AlertTitle>
        <AlertDescription>
          Le bot Notae est en train de se connecter à la réunion.
          <br />
          <StopBotButton meeting={meeting} />
        </AlertDescription>
      </Alert>
    </div>
  )
}

function BotWaiting({ meeting }: Props) {
  return (
    <div className="w-full">
      <Alert>
        <Loader className="h-4 w-4" />
        <AlertTitle>Bot Notae</AlertTitle>
        <AlertDescription>
          Le bot Notae est dans la salle d'attente de la réunion.
          <br />
          <StopBotButton meeting={meeting} />
        </AlertDescription>
      </Alert>

      <Separator className="my-4 w-2/3 mx-auto" />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} />

            <AddDefaultPrompt meeting={meeting} />

            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

function StopBotButton({ meeting }: Props) {
  const stopBotMutation = useStopBotForMeeting()

  const handleStopBot = async () => {
    if (!meeting.data?.id) return
    await stopBotMutation.mutateAsync({ id: meeting.data.id })
  }

  return (
    <Button onClick={handleStopBot} isLoading={stopBotMutation.isPending} variant="destructive" size="sm" className="mt-2">
      Arrêter le bot
    </Button>
  )
}

function BotDone({ meeting }: Props) {
  return (
    <div className="w-full">
      <Alert>
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Le bot a quitté la réunion</AlertTitle>
        <AlertDescription>
          Le bot Notae a quitté la réunion.
          <br />
          Aucun audio ne semble disponible.
          <br />
          Si vous pensez que c'est une erreur,{' '}
          <button type="button" className="underline hover:no-underline" onClick={() => Crisp.chat.open()}>
            contactez-nous.
          </button>
        </AlertDescription>
      </Alert>
    </div>
  )
}
