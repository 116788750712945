import 'dayjs/locale/fr'
import Button from '@/components/custom/Button'
import { Badge } from '@/components/ui/badge'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { useCreateSummaryFromSources, useFolder } from '@/hooks/useFolder'
import { usePrompts } from '@/hooks/usePrompt'
import { PromptShowFor } from '@/types/others.types'
import { formatDate, getSourceName, logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const formSchema = yup.object({
  title: yup.string().required('Title is required'),
  context: yup.string(),
  promptId: yup.string().required('un modèle est nécessaire'),
  sourceIds: yup.array(yup.string()),
})

type FormType = yup.InferType<typeof formSchema>

export default function FolderSummariesNew() {
  const { id } = useParams()
  const navigate = useNavigate()

  const folder = useFolder(id)
  const prompts = usePrompts({ type: 'PROMPT', showFor: PromptShowFor.FOLDER })

  const [allSelected, setAllSelected] = useState(false) // New state

  const createSummaryFromSources = useCreateSummaryFromSources()

  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: '',
      context: '',
      promptId: '',
      sourceIds: [],
    },
  })

  logger('watch : ', form.watch())

  const { toast } = useToast()

  const toggleAllSources = () => {
    if (allSelected) {
      form.setValue('sourceIds', [])
    } else {
      const allSourceIds = folder.data?.sources.map((s) => s.id)
      form.setValue('sourceIds', allSourceIds)
    }
    setAllSelected(!allSelected)
  }

  const onSubmit = async (data: FormType) => {
    logger('Submitting data: ', data)

    return createSummaryFromSources.mutate(
      {
        folderId: id,
        title: data.title,
        context: data.context,
        promptId: data.promptId,
        sourceIds: data.sourceIds,
      },
      {
        onSuccess: (data) => {
          toast({ title: 'Document created successfully' })
          navigate(`/folders/${id}/summaries/${data.id}`)
        },
        onError: (error) => {
          toast({ title: 'Error creating document', description: error.message })
        },
      },
    )
  }

  if (!folder?.data) {
    return <div>Empty</div>
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/folders">Dossiers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={`/folders/${id}`}>{folder.data.title}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>Nouvelle rédaction</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex flex-row justify-between items-center">
        <h2 className="text-2xl">Nouvelle rédaction</h2>

        <Link to={`/folders/${id}/sources`}>
          <Button size="default" variant={'outline'}>
            Retour
          </Button>
        </Link>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="md:w-2/4 w-full">
                  <FormLabel>Nom de la rédaction</FormLabel>
                  <FormControl>
                    <Input placeholder="Nom de la rédaction" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="promptId"
              render={({ field }) => (
                <FormItem className="md:w-2/4 w-full">
                  <FormLabel>Choisissez le type de compte rendu souhaité</FormLabel>

                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choisissez un modèle" />
                      </SelectTrigger>
                    </FormControl>

                    <SelectContent className="overflow-y-auto max-h-[10rem]">
                      <SelectGroup>
                        {prompts.data?.map((p) => (
                          <SelectItem key={p.id} value={p.id}>
                            {p.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="context"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contexte</FormLabel>
                <FormControl>
                  <Textarea
                    rows={4}
                    placeholder="Renseignez les informations permettant à Notae Al de générer un document le plus pertinent possible"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="">
            <h3 className="text-xl">Sources de la rédaction</h3>
            <p className="text-sm text-gray-500 mb-4">Sélectionnez les sources à partir desquelles vous souhaiter générer un document.</p>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="">Nom</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead className="text-right">Date de l'ajout</TableHead>
                  <TableHead className="text-center w-[100px]">
                    Actions
                    <Button type="button" variant="link" size="sm" onClick={toggleAllSources} className="text-xs text-gray-500">
                      Tout sélectionner
                    </Button>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {folder.data?.sources?.map((s) => (
                  <TableRow key={s.id}>
                    <TableCell className="font-medium">{getSourceName(s)}</TableCell>
                    <TableCell>
                      <Badge className="bg-amber-500/50">{s.sourceType}</Badge>
                    </TableCell>
                    <TableCell className="text-right">{formatDate(s.createdAt)}</TableCell>
                    <TableCell className="text-center">
                      <FormField
                        key={s.id}
                        control={form.control}
                        name="sourceIds"
                        render={({ field }) => {
                          return (
                            <FormItem key={s.id} className="">
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(s.id)}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([...field.value, s.id])
                                      : field.onChange(field.value?.filter((value) => value !== s.id))
                                  }}
                                />
                              </FormControl>
                            </FormItem>
                          )
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="">
            <Button type="submit" className="w-full" isLoading={createSummaryFromSources.isPending}>
              Lancer la génération
            </Button>
          </div>
        </form>
      </Form>
    </>
  )
}
