import CustomLoader from '@/components/custom/CustomLoader'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import { useOrg, useUpdateOrg, useUpdateUser, useUser } from '@/hooks/useUser'
import { logger } from '@/utils'
import { Protect, useClerk, useOrganization as useClerkOrganization, useOrganizationList } from '@clerk/clerk-react'
import { Building2 } from 'lucide-react'
import { useLocalStorage } from 'usehooks-ts'
import { SettingsVariables } from './SettingsVariables'

export function Settings() {
  const { toast } = useToast()
  const user = useUser()
  const org = useOrg()

  const { mutateAsync: updateUser } = useUpdateUser()
  const { mutateAsync: updateOrg } = useUpdateOrg()

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  const { organization: organizationClerk, isLoaded: isLoadedOrganization } = useClerkOrganization()

  const { setActive, userInvitations } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
    userInvitations: {
      infinite: true,
    },
  })

  const clerk = useClerk()

  //logger('userInvitations : ', userInvitations)

  const join = async () => {
    await userInvitations?.data?.[0]?.accept()
    setActive?.({ organization: userInvitations?.data?.[0]?.publicOrganizationData.id })
    toast({
      title: "Vous avez rejoint l'équipe",
      variant: 'default',
    })
  }

  const handleUserVariablesSubmit = async (variables: Record<string, string>) => {
    await updateUser({
      data: { variables },
    })
    toast({
      title: 'Variables utilisateur mises à jour avec succès',
    })
  }

  const handleOrgVariablesSubmit = async (variables: Record<string, string>) => {
    await updateOrg({
      data: { variables },
    })
    toast({
      title: 'Variables organisation mises à jour avec succès',
    })
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-3xl">Paramètres</h2>
      </div>
      <br />
      <h3 className="text-2xl">Équipe</h3>
      <br />

      {userInvitations?.data?.[0]?.id && userInvitations?.data?.[0]?.status === 'pending' ? (
        <Alert>
          <Building2 className="h-4 w-4" />
          <AlertTitle>
            Vous êtes invité à rejoindre l'équipe <b>{userInvitations?.data?.[0]?.publicOrganizationData.name}</b>
          </AlertTitle>
          <AlertDescription>
            <br />
            <Button onClick={join}>Rejoindre</Button>
          </AlertDescription>
        </Alert>
      ) : isLoadedOrganization ? (
        <div>
          {!organizationClerk ? (
            <>
              <p>Vous pouvez inviter des collaborateurs à utiliser Notae.</p>
              <br />
              <Button variant="default" onClick={() => clerk.openCreateOrganization({ afterCreateOrganizationUrl: '/settings' })}>
                Créer une équipe et inviter des collaborateurs
              </Button>
            </>
          ) : (
            <>
              <Protect role="org:admin">
                <p>Vous pouvez inviter des collaborateurs à utiliser Notae.</p>
                <br />
                <Button onClick={() => clerk.openOrganizationProfile()}>Gérer votre équipe et inviter des collaborateurs</Button>
              </Protect>
              <Protect role="org:member">
                <Button onClick={() => clerk.openOrganizationProfile()}>Consulter votre équipe</Button>
              </Protect>
            </>
          )}
        </div>
      ) : (
        <CustomLoader />
      )}

      {superAdmin && (
        <>
          <Separator className="my-8 w-2/3 mx-auto" />

          <h3 className="text-2xl">Variables</h3>
          <br />

          <h3 className="text-xl">Organisation ({org.data?.name})</h3>
          <br />
          <SettingsVariables onSubmit={handleOrgVariablesSubmit} variables={org.data?.variables} />
          <br />
          <h3 className="text-xl">Utilisateur ({`${user.data?.firstName} ${user.data?.lastName}`})</h3>
          <br />
          <SettingsVariables onSubmit={handleUserVariablesSubmit} variables={user.data?.variables} />
          <br />
          <br />
        </>
      )}
    </>
  )
}
