import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Separator } from '@/components/ui/separator'
import AddContext from '@/containers/others/AddContext'
import AddDefaultPrompt from '@/containers/others/AddDefaultPrompt'
import ChangeLanguage from '@/containers/others/ChangeLanguage'
import { usePrompts } from '@/hooks/usePrompt'
import type { MeetingQueryResult } from '@/types/others.types'
import { MAX_FILE_DURATION_IN_SEC } from '@/utils/audio'
import { AudioLines } from 'lucide-react'

type Props = {
  meeting: MeetingQueryResult
}

export function TranscriptAudioReady({ meeting }: Props) {
  return (
    <div className="w-full flex flex-col gap-2">
      {meeting.data?.duration >= MAX_FILE_DURATION_IN_SEC ? (
        <Alert variant="default">
          <AudioLines className="h-4 w-4 !text-red-500" />
          <AlertTitle className="!text-red-500">Fichier audio trop long</AlertTitle>
          <AlertDescription>
            Le fichier audio est trop long. (+ de 2h15).
            <br />
            <br />
            <b>
              Nous allons l'optimiser.
              <br />
              Vous n'avez rien à faire de plus.
            </b>
            <br />
            <br />
            Il sera ensuite transcrit en texte.
          </AlertDescription>
        </Alert>
      ) : (
        <Alert>
          <AudioLines className="h-4 w-4" />
          <AlertTitle>Fichier audio prêt</AlertTitle>
          <AlertDescription>
            Le fichier audio de votre réunion est prêt.
            <br />
            Il va bientôt être transcrit en texte.
          </AlertDescription>
        </Alert>
      )}

      <Separator className="my-4 w-2/3 mx-auto" />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <ChangeLanguage meeting={meeting} />

            <AddDefaultPrompt meeting={meeting} />

            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export function TranscriptTranscriptionInProgress({ meeting }: Props) {
  const prompts = usePrompts()

  return (
    <div className="w-full">
      <Alert>
        <AudioLines className="h-4 w-4" />
        <AlertTitle>Transcription en cours</AlertTitle>
        <AlertDescription>Le fichier audio est en cours de transcription...</AlertDescription>
        {meeting.data?.defaultPromptId ? (
          <AlertDescription>
            Vous recevrez un e-mail à l'adresse <b>{meeting.data?.user.email}</b> lorsque le compte rendu{' '}
            <b>{prompts.data?.find((p) => p.id === meeting.data?.defaultPromptId)?.name}</b> sera généré.
          </AlertDescription>
        ) : (
          <AlertDescription>
            Vous recevrez un e-mail à l'adresse <b>{meeting.data?.user.email}</b> lorsque la transcription sera terminée.
          </AlertDescription>
        )}
      </Alert>

      <Separator className="my-4 w-2/3 mx-auto" />

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Paramètres optionnels</AccordionTrigger>
          <AccordionContent className="space-y-3 mx-1">
            <AddDefaultPrompt meeting={meeting} />

            <AddContext meeting={meeting} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
