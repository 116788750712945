import { Badge } from '@/components/ui/badge'
import type { Prisma } from '@/types'
import { SourceType } from '@/types/others.types'
import { classNames } from '@/utils'
import { translateSourceType } from '@/utils/translate'

export default function SourceTypeBadge({ source }: { source: Prisma.SourceGetPayload<true> }) {
  const color = () => {
    switch (source.sourceType) {
      case SourceType.SUMMARY:
        return 'bg-blue-300'
      case SourceType.TRANSCRIPT:
        return 'bg-green-300'
      case SourceType.FILE:
        return 'bg-yellow-300'
      default:
        return 'bg-yellow-300'
    }
  }

  return (
    <>
      <Badge className={classNames(color(), 'text-gray-600', `hover:${color()}`, 'break-keep', 'w-max')}>
        {translateSourceType(source.sourceType)}
      </Badge>
    </>
  )
}
