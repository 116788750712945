// FilesColumns.tsx
import { Badge } from '@/components/ui/badge'
import type { FilePayload } from '@/types/others.types'
import type { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

export const filesColumns: ColumnDef<FilePayload>[] = [
  {
    accessorKey: 'name',
    header: () => <div className="">Nom</div>,
    cell: ({ row }) => {
      const name: string = row.getValue('name') ?? 'Fichier sans nom'

      return (
        <>
          <div className="text-left font-semibold">{row.original.extractedName ?? name}</div>
          <div className="text-xs italic text-gray-500">{name}</div>
        </>
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Statut',
    cell: ({ row }) => {
      return <Badge variant="outline">{row.getValue('status')}</Badge>
    },
  },
  {
    accessorKey: 'mimetype',
    header: 'Type',
    cell: ({ row }) => {
      return <div className="">{row.getValue('mimetype')}</div>
    },
  },
  {
    accessorKey: 'user',
    header: 'Créé par',
    cell: ({ row }) => {
      return (
        <div className="">
          {row.original.user?.firstName ? `${row.original.user?.firstName} ${row.original.user?.lastName}` : row.original.user?.email}
        </div>
      )
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Date de création',
    size: 20,
    cell: ({ row }) => {
      const date = dayjs(row.getValue('createdAt')).format('DD MMM YYYY, HH:mm')

      return (
        <time key={row.getValue('createdAt')} className="truncate" dateTime={date}>
          {date}
        </time>
      )
    },
  },
]
