import CustomLoader from '@/components/custom/CustomLoader'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useToast } from '@/components/ui/use-toast'
import { logger } from '@/utils'
import { useAuth, useClerk, useOrganization, useOrganizationList, useUser } from '@clerk/clerk-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Layout from './Layout'

export default function ConnectedLayout() {
  const { userId, isLoaded: authIsLoaded, orgId } = useAuth()
  const clerk = useClerk()
  const { user, isLoaded: clerkUserIsLoaded } = useUser()
  const navigate = useNavigate()
  const { organization, isLoaded: clerOrganizationIsLoaded } = useOrganization()

  const {
    setActive,
    userMemberships,
    userInvitations,
    isLoaded: clerkOrganizationListIsLoaded,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
    userInvitations: {
      infinite: true,
    },
  })

  //logger('userInvitations : ', userInvitations)

  const queryClient = useQueryClient()

  const { toast } = useToast()

  const [openInvited, setOpenInvited] = useState(false)
  const [openVerifyEmail, setOpenVerifyEmail] = useState(false)
  const [openCreateOrg, setOpenCreateOrg] = useState(false)

  useEffect(() => {
    if (!userId && authIsLoaded) navigate('/sign-in')
  }, [userId, authIsLoaded, navigate])

  const handleAlertDialogs = () => {
    if (!user?.hasVerifiedEmailAddress) {
      setOpenVerifyEmail(true)
      setOpenInvited(false)
      setOpenCreateOrg(false)
    } else if (userInvitations?.data?.[0]?.id && userInvitations?.data?.[0]?.status === 'pending') {
      setOpenVerifyEmail(false)
      setOpenInvited(true)
      setOpenCreateOrg(false)
    } else if (clerOrganizationIsLoaded && !organization && userMemberships?.data?.length === 0 && userInvitations?.data?.length === 0) {
      setOpenVerifyEmail(false)
      setOpenInvited(false)
      setOpenCreateOrg(true)
    } else {
      setOpenVerifyEmail(false)
      setOpenInvited(false)
      setOpenCreateOrg(false)
    }
  }

  useEffect(() => {
    if (clerOrganizationIsLoaded && clerkOrganizationListIsLoaded && clerkUserIsLoaded && !userInvitations.isLoading) {
      handleAlertDialogs()
    }
  }, [
    userInvitations.isLoading,
    clerkUserIsLoaded,
    clerkOrganizationListIsLoaded,
    clerOrganizationIsLoaded,
    user?.hasVerifiedEmailAddress,
    userInvitations?.data,
    organization,
    userMemberships?.data,
    // do not set handleAlertDialogs here, because it will reopen the dialog when user want to create a new organization
  ])

  useEffect(() => {
    ;(async () => {
      if (!orgId && setActive && userMemberships?.data?.[0]?.organization?.id) {
        logger(`set organization ${userMemberships.data[0].organization.id} active`)
        await setActive({ organization: userMemberships.data[0].organization.id })
        await queryClient.invalidateQueries()
      }
    })()
  }, [orgId, setActive, userMemberships?.data, queryClient])

  const joinMutation = useMutation({
    mutationKey: ['join-org'],
    mutationFn: async () => {
      return await userInvitations?.data?.[0]?.accept()
    },
    onSuccess: () => {
      setActive?.({ organization: userInvitations?.data?.[0]?.publicOrganizationData.id })
      setOpenInvited(false)
      toast({
        title: "Vous avez rejoint l'équipe",
        variant: 'default',
      })
    },
  })

  const verifyEmailMutation = useMutation({
    mutationKey: ['verify-email'],
    mutationFn: async () => {
      return await user?.primaryEmailAddress?.prepareVerification({
        strategy: 'email_link',
        redirectUrl: 'https://app.notae.ai',
      })
    },
  })

  if (!authIsLoaded || !userId) return <CustomLoader centered />

  return (
    <Layout>
      <AlertDialog open={openInvited}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Équipe <b>{userInvitations?.data?.[0]?.publicOrganizationData.name}</b>
            </AlertDialogTitle>
            <AlertDialogDescription>
              Vous êtes invité à rejoindre l'équipe <b>{userInvitations?.data?.[0]?.publicOrganizationData.name}</b>.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => joinMutation.mutate()} disabled={joinMutation.isPending}>
              {joinMutation.isPending ? <CustomLoader /> : 'Rejoindre'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={openCreateOrg}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Équipes</AlertDialogTitle>
            <AlertDialogDescription>
              Vous devez rejoindre une équipe ou en créer une.
              <br />
              Pour rejoindre une équipe, demandez à un membre de l'équipe de vous inviter.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                setOpenCreateOrg(false)
                clerk.openCreateOrganization({ afterCreateOrganizationUrl: '/' })
              }}
            >
              Créer une équipe et inviter des collaborateurs
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={openVerifyEmail}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Vérification de votre adresse email</AlertDialogTitle>
            <AlertDialogDescription>
              Cliquez sur le bouton ci-dessous pour recevoir un lien de vérification de votre adresse email : (
              <b>{user?.primaryEmailAddress?.emailAddress}</b>)
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => clerk.signOut()} disabled={verifyEmailMutation.isPending || verifyEmailMutation.isSuccess}>
              Déconnexion
            </AlertDialogAction>
            <AlertDialogAction
              onClick={() => verifyEmailMutation.mutate()}
              disabled={verifyEmailMutation.isPending || verifyEmailMutation.isSuccess}
            >
              {verifyEmailMutation.isPending ? (
                <CustomLoader />
              ) : verifyEmailMutation.isSuccess ? (
                'Email de vérification envoyé'
              ) : (
                'Envoyer le lien de vérification'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Outlet />
    </Layout>
  )
}
