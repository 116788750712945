import type { MeetingType, Prisma } from '@/types'
import type { MeetingPayload, MeetingsPayload } from '@/types/others.types'
import { API_URL, logger } from '@/utils'
import { useAuth } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { getHeaders, useAsOrg, useAsUser } from './useUser'

export function useMeetings(
  { filters }: { filters: { onlyMine?: boolean; status?: string[]; type?: string[]; isArchived?: boolean } } = {
    filters: { onlyMine: false },
  },
) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const params: {
    onlyMine?: boolean
    status?: string
    type?: string
    isArchived?: boolean
  } = {}

  if (filters.onlyMine) {
    params.onlyMine = filters.onlyMine
  }
  if (filters.status) {
    params.status = filters.status.join(',')
  }
  if (filters.type) {
    params.type = filters.type.join(',')
  }

  params.isArchived = filters.isArchived

  const fetchMeetings = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<MeetingsPayload>(`${API_URL}/meetings`, {
      params: params,
      headers: headers,
    })

    return response
  }

  return useQuery({
    queryKey: ['meetings', params],
    enabled: isSignedIn,
    queryFn: async () => {
      return fetchMeetings().then((result) => result.data)
    },
  })
}

export function useMeeting(id: string | undefined) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchMeeting = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<MeetingPayload>(`${API_URL}/meetings/${id}`, {
      headers: headers,
    })

    return response.data
  }

  return useQuery({
    queryKey: ['meetings', id],
    queryFn: async () => {
      return fetchMeeting().then((result) => result)
    },
    enabled: !!id && isSignedIn,
    refetchInterval: (query) => {
      if (['TRANSCRIPTION_IN_PROGRESS', 'AUDIO_READY', 'SUMMARY_IN_PROGRESS'].includes(query.state?.data?.status)) {
        return 5000
      }
      if (query.state?.data?.status === 'CREATED' && dayjs(query.state.data?.bot?.joinAt).isBefore(dayjs())) {
        return 5000
      }

      return false
    },
  })
}

export const useDeleteMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deleteMeeting = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/meetings/${id}`, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-meeting'],
    mutationFn: deleteMeeting,
    retry: 0,
    onSettled: async (_data, _error, { id }) => {
      queryClient.removeQueries({
        queryKey: ['meetings', id],
      })
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export type CreateMeetingInput = {
  type: MeetingType
  meetingUrl?: string
  joinAt?: Date
  botName?: string
}

export const useCreateMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createMeeting = async (data: CreateMeetingInput) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createMeeting : ', data)

    const response = await axios.post<MeetingPayload>(`${API_URL}/meetings`, data, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-meeting'],
    mutationFn: createMeeting,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useCreateSummary = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createSummary = async ({
    id,
    promptId,
    temperature,
    model,
  }: {
    id: string
    promptId: string
    temperature: number
    model: string
  }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createSummary : ', { promptId, temperature, model })

    const response = await axios.post<string>(`${API_URL}/meetings/${id}/summary`, { promptId, temperature, model }, { headers: headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-summary'],
    mutationFn: createSummary,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useCreateTranscript = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createTranscript = async ({ id, provider }: { id: string; provider?: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createTranscript : ', { id })

    const response = await axios.post<string>(`${API_URL}/meetings/${id}/transcript`, { provider }, { headers: headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-transcript'],
    mutationFn: createTranscript,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useCreateSummaryFollowUp = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createSummaryFollowUp = async ({
    id,
    summaryId,
    promptId,
  }: {
    id: string
    summaryId: string
    promptId: string
  }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createSummaryFollowUp : ', { summaryId, promptId })

    const response = await axios.post<string>(
      `${API_URL}/meetings/${id}/summary/${summaryId}/magic-prompt`,
      { promptId },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-summary-followup'],
    mutationFn: createSummaryFollowUp,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useCreateTitleForMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createTitleForMeeting = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('useCreateTitleForMeeting : ')

    const response = await axios.post<Prisma.SummaryCreateInput>(`${API_URL}/meetings/${id}/generate-title`, {}, { headers: headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-title-for-meeting'],
    mutationFn: createTitleForMeeting,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useCreateBotForMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createBotForMeeting = async ({
    id,
    meetingUrl,
    botName,
    joinAt,
  }: {
    id: string
    meetingUrl: string
    botName: string
    joinAt: Date
  }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('createBotForMeeting : ')

    const response = await axios.post<MeetingPayload>(
      `${API_URL}/meetings/${id}/create-bot`,
      { meetingUrl, botName, joinAt },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-bot-for-meeting'],
    mutationFn: createBotForMeeting,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useUpdateMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const updateMeeting = async ({ id, meeting }: { id: string; meeting: Prisma.MeetingUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('useUpdateMeeting : ', meeting)

    const response = await axios.put<MeetingPayload>(`${API_URL}/meetings/${id}`, meeting, {
      headers: headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-meeting'],
    mutationFn: updateMeeting,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

type CreateMultipartUploadResponse = {
  uploadId: string
  key: string
}

type SignPartResponse = {
  signedUrl: string
}

export const useGetSignedUrl = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const getSignedUrl = async ({
    id,
    fileType,
    fileExt,
    multipart,
  }: { id: string; fileType: string; fileExt?: string; multipart?: boolean }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<{ signedUrl: string } | CreateMultipartUploadResponse>(
      `${API_URL}/meetings/${id}/upload/get-signed-url`,
      { fileType, fileExt, multipart },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['get-signed-url'],
    mutationFn: getSignedUrl,
  })

  return mutation
}

export const useSignPart = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const signPart = async ({ id, uploadId, key, partNumber }: { id: string; uploadId: string; key: string; partNumber: number }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<SignPartResponse>(
      `${API_URL}/meetings/${id}/upload/sign-part`,
      { uploadId, key, partNumber },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['sign-part'],
    mutationFn: signPart,
  })

  return mutation
}

export const useCompleteMultipartUpload = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const completeMultipartUpload = async ({
    id,
    uploadId,
    key,
    parts,
  }: {
    id: string
    uploadId: string
    key: string
    parts: { ETag: string; PartNumber: number }[]
  }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<{ location: string }>(
      `${API_URL}/meetings/${id}/upload/complete-multipart`,
      { uploadId, key, parts },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['complete-multipart-upload'],
    mutationFn: completeMultipartUpload,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}

export const useListParts = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const listParts = async ({ id, uploadId, key }: { id: string; uploadId: string; key: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<{ parts: Array<{ PartNumber: number; ETag: string; Size: number }> }>(
      `${API_URL}/meetings/${id}/upload/list-parts`,
      { uploadId, key },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['list-parts'],
    mutationFn: listParts,
  })

  return mutation
}

export const useAbortMultipartUpload = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const abortMultipartUpload = async ({ id, uploadId, key }: { id: string; uploadId: string; key: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<{ message: string }>(
      `${API_URL}/meetings/${id}/upload/abort-multipart`,
      { uploadId, key },
      { headers: headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['abort-multipart-upload'],
    mutationFn: abortMultipartUpload,
  })

  return mutation
}

export const useUploadMeetingFile = (progressCallBack?: (value: number) => void) => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const uploadFileToServer = async ({ id, file, mimeType }: { id: string; file: Blob; mimeType: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('mimeType', mimeType)

    logger('useUploadMeetingFile | file : ', file)

    const response = await axios.post<{ path: string }>(`${API_URL}/meetings/${id}/upload`, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        if (event.loaded && event.total) {
          const progress = Math.round((100 * event.loaded) / event?.total)
          progressCallBack?.(progress)
        }
      },
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['upload-file-for-meeting'],
    mutationFn: uploadFileToServer,
    onError(_error, variables, _context) {
      Sentry.withScope((scope) => {
        const newError = new Error(`Failed to upload file for meeting ${variables.id}`)
        const contextFile = {
          meetingId: variables.id,
          size: variables?.file?.size,
          fileType: variables?.file?.type,
          mimeType: variables?.mimeType,
        }
        scope.setContext('file', contextFile)
        Sentry.captureException(newError)
      })
    },

    onSettled: async () => {
      // IMPORTANT : dont do that here because it will unmount component when meeting status change.
      //return queryClient.invalidateQueries({ queryKey: ["meetings" /* , id */] });
    },
  })

  return mutation
}

export const useStopBotForMeeting = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const stopBotForMeeting = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    logger('stopBotForMeeting : ')

    const response = await axios.post<{ message: string }>(`${API_URL}/meetings/${id}/stop-bot`, {}, { headers: headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['stop-bot-for-meeting'],
    mutationFn: stopBotForMeeting,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['meetings'] })
    },
  })

  return mutation
}
