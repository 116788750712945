import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useAsOrg, useAsUser, useOrgs } from '@/hooks/useUser'
import { cn } from '@/lib/utils'
import type { Prisma } from '@/types'
import { isAdmin } from '@/utils'
import { useUser } from '@clerk/clerk-react'
import { useQueryClient } from '@tanstack/react-query'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import { useEffect, useState } from 'react'

type Props = {
  className?: string
}

export default function ChooseAsUser({ className }: Props) {
  const orgs = useOrgs()

  const queryClient = useQueryClient()

  const [open, setOpen] = useState(false)

  const { asUser, setAsUser } = useAsUser()

  const { setAsOrg } = useAsOrg()

  // biome-ignore lint/correctness/useExhaustiveDependencies: asUser is not used here, but it's a good practice to invalidate queries when the user changes
  useEffect(() => {
    ;(async () => {
      // asUser is not used here, but it's a good practice to invalidate queries when the user changes
      await queryClient.invalidateQueries()
      await queryClient.refetchQueries()
    })()
  }, [asUser, queryClient])

  const { user } = useUser()

  const allUsers: Prisma.UserGetPayload<true>[] = orgs.data?.reduce((acc, org) => [...acc, ...org.users], [])

  if (isAdmin(user) && orgs.data?.length)
    return (
      <div className={cn('flex items-center gap-2', className)}>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between">
              {asUser ? allUsers?.find((user) => user.id === asUser)?.email : 'User ?'}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command
              className="max-h-80"
              filter={(value, search, keywords) => {
                const extendValue = keywords.join(' ').toLowerCase()
                if (extendValue.includes(search.toLowerCase())) return 1
                return 0
              }}
            >
              <CommandInput placeholder="Rechercher un utilisateur..." />
              <CommandList>
                <CommandEmpty>No framework found.</CommandEmpty>
                {orgs.data?.map((org) => (
                  <CommandGroup key={org.id} heading={org.name}>
                    {org.users.map((user) => {
                      return (
                        <CommandItem
                          key={user.id}
                          value={user.id}
                          onSelect={(currentValue) => {
                            setAsUser(currentValue)
                            setAsOrg(org.id)
                            // setAsOrg(orgs.data?.find((o) => o.users.find((u) => u.id === currentValue))?.id ?? '')
                            setOpen(false)
                          }}
                          keywords={[org.name, user.email, user.firstName, user.lastName].filter((v) => v !== null) as string[]}
                        >
                          <Check className={cn('mr-2 h-4 w-4', asUser === user.id ? 'opacity-100' : 'opacity-0')} />

                          {user.firstName ? `${user.firstName} ${user.lastName}` : user.email}
                        </CommandItem>
                      )
                    })}
                  </CommandGroup>
                ))}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {!!asUser && (
          <Button
            variant="destructive"
            size="sm"
            onClick={() => {
              setAsUser(null)
              setAsOrg(null)
            }}
          >
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>
    )
}
