import { logger } from '@/utils'
import { ClerkProvider, useAuth, useUser } from '@clerk/clerk-react'
import { frFR } from '@clerk/localizations'
import * as Sentry from '@sentry/react'
import { Crisp } from 'crisp-sdk-web'
import { useEffect } from 'react'
import { MixpanelProvider, useMixpanel } from 'react-mixpanel-browser'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { useLocalStorage } from 'usehooks-ts'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

const localization = {
  formFieldLabel__organizationName: "Nom de l'équipe",
  createOrganization: {
    ...frFR.createOrganization,
    title: 'Créer une équipe',
    formButtonSubmit: "Créer l'équipe",
  },
}

export default function RootLayout() {
  const navigate = useNavigate()

  const [impersonate] = useLocalStorage('impersonate', false)
  logger('impersonate: ', impersonate)

  const MIXPANEL_TOKEN = impersonate ? null : import.meta.env.VITE_MIXPANEL_TOKEN

  const location = useLocation()

  logger(`Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`)

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ClerkProvider
        telemetry={false}
        localization={{ ...frFR, ...localization }}
        routerPush={(e) => navigate(e)}
        routerReplace={(e) => navigate(e, { replace: true })}
        appearance={{}}
        publishableKey={PUBLISHABLE_KEY}
      >
        <MixpanelProvider
          config={{
            debug: !import.meta.env.PROD,
            persistence: 'localStorage',
            api_host: 'https://panel.notae.ai',
            //track_pageview: true, // Set to `false` by default
          }}
          token={MIXPANEL_TOKEN}
        >
          <ClerkLoaded />
        </MixpanelProvider>
      </ClerkProvider>
    </QueryParamProvider>
  )
}

function ClerkLoaded() {
  const { userId } = useAuth()
  const { user, isLoaded } = useUser()

  const [impersonate] = useLocalStorage('impersonate', false)

  const mixpanel = useMixpanel()

  useEffect(() => {
    logger('Configure Crisp...')

    Crisp.configure(import.meta.env.VITE_CRISP_TOKEN, {
      autoload: true,
    })
    Crisp.setSafeMode(true)
  }, [])

  // Disabled on 4 juin 2024 because it's seems useless.
  /* 	useEffect(() => {
		if (!userId && isLoaded) {
			navigate('/sign-in')
		}
	}, [userId, isLoaded, navigate]) */

  useEffect(() => {
    logger('Loaded : ', userId, ' / ', isLoaded)
    Crisp.setSafeMode(true)

    if (userId && isLoaded && user && !impersonate) {
      logger(`Loaded : `, user)
      logger('Setting session...')

      Crisp.setTokenId(userId)
      Crisp.session.reset()

      user.primaryEmailAddress?.emailAddress && Crisp.user.setEmail(user.primaryEmailAddress.emailAddress)
      user.fullName && Crisp.user.setNickname(user.fullName)

      Sentry.setUser({
        id: userId,
        email: user?.primaryEmailAddress?.emailAddress,
        username: user?.fullName,
      })
      mixpanel?.identify(userId)
    } else {
      logger('Resetting session...')
      // Réinitialiser la session Crisp lorsque l'utilisateur se déconnecte

      Crisp.setTokenId()
      Crisp.session.reset()

      mixpanel?.reset()

      Sentry.setUser(null)
    }
  }, [userId, isLoaded])

  return <Outlet />
}
