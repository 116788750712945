import type { Prisma, PromptType } from '@/types'
import type { PossibleError, PromptAssignementPayload, PromptPayload, PromptShowFor } from '@/types/others.types'
import { API_URL } from '@/utils'
import { useAuth } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { getHeaders, useAsOrg, useAsUser } from './useUser'

export function usePromptAssignements() {
  const { getToken, isSignedIn, orgId, userId } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchPromptAssignements = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<PromptAssignementPayload[]>(`${API_URL}/prompt-assignements`, {
      headers,
    })

    return response
  }

  return useQuery({
    queryKey: ['prompt-assignements', asOrg, userId, orgId],
    enabled: isSignedIn && !!orgId && !!userId,
    queryFn: async () => {
      return fetchPromptAssignements().then((result) => result.data)
    },
  })
}

export function usePrompts(
  {
    all,
    type,
    parentPromptId,
    showFor,
  }: { all?: 1 | 0; type?: PromptType; parentPromptId?: string | null; showFor?: PromptShowFor | PromptShowFor[] } = { all: 0 },
) {
  const { getToken, isSignedIn, orgId, userId } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchPrompts = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<PromptPayload[]>(`${API_URL}/prompts`, {
      headers,
      params: {
        all,
        type,
        parentPromptId,
        showFor: Array.isArray(showFor) ? showFor.join(',') : showFor, // Convertir en chaîne si c'est un tableau
      },
    })

    return response
  }

  return useQuery({
    queryKey: ['prompts', all, type, parentPromptId, showFor, asOrg, userId, orgId],
    enabled: isSignedIn && !!orgId && !!userId,
    queryFn: async () => {
      return fetchPrompts().then((result) => result.data)
    },
  })
}

export function usePrompt(id: string | undefined) {
  const { getToken, isSignedIn } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()

  const fetchPrompt = async () => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.get<PromptPayload>(`${API_URL}/prompts/${id}`, {
      headers,
    })

    return response.data
  }

  return useQuery({
    queryKey: ['prompts', id],
    queryFn: async () => {
      return fetchPrompt().then((result) => result)
    },
    enabled: !!id && isSignedIn,
  })
}

export const useCreatePrompt = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const createPrompt = async (data: Partial<Prisma.PromptCreateInput>) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<{ id: string }>(`${API_URL}/prompts/`, data, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['create-prompt'],
    mutationFn: createPrompt,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['prompts'] })
    },
  })

  return mutation
}

export const useUpdatePrompt = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const updatePrompt = async ({ id, prompt }: { id: string; prompt: Prisma.PromptUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.put<{ id: string }>(`${API_URL}/prompts/${id}`, prompt, {
      headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-prompt'],
    mutationFn: updatePrompt,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['prompts'] })
    },
  })

  return mutation
}

export const useDeletePrompt = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deletePrompt = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/prompts/${id}`, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-prompt'],
    mutationFn: deletePrompt,
    retry: 0,
    onSettled: async (_data, _error, { id }) => {
      queryClient.removeQueries({
        queryKey: ['prompts', id],
      })
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['prompts'] }),
        queryClient.invalidateQueries({ queryKey: ['prompt-assignements'] }),
      ])
    },
  })

  return mutation
}

export const useLinkOrUnlinkPrompts = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const linkOrUnlinkPrompts = async ({
    action,
    promptId,
    childrenPromptId,
    parentPromptId,
  }: { action: string; promptId?: string; childrenPromptId?: string; parentPromptId?: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<Prisma.PromptCreateInput>(
      `${API_URL}/prompts/${promptId}`,
      { action, childrenPromptId, parentPromptId },
      { headers },
    )

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['link-or-unlink-prompts'],
    mutationFn: linkOrUnlinkPrompts,
    retry: 0,
    onSettled: async (_data, _error, { promptId }) => {
      return queryClient.invalidateQueries({ queryKey: ['prompts', promptId] })
    },
  })

  return mutation
}

export const useCreatePromptAssignement = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const addToMyPrompts = async ({ promptId, specificPrompt }: { promptId: string; specificPrompt?: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.post<Prisma.PromptCreateInput>(`${API_URL}/prompt-assignements`, { promptId, specificPrompt }, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['add-to-my-prompts'],
    mutationFn: addToMyPrompts,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['prompt-assignements'] })
    },
  })

  return mutation
}

export const useUpdatePromptAssignement = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const updatePromptAssignement = async ({
    id,
    promptAssignement,
  }: { id: string; promptAssignement: Prisma.PromptAssignementUpdateInput }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.put<Prisma.PromptCreateInput>(`${API_URL}/prompt-assignements/${id}`, promptAssignement, {
      headers,
    })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['update-prompt-assignement'],
    mutationFn: updatePromptAssignement,
    retry: 0,
    onSettled: async () => {
      return queryClient.invalidateQueries({ queryKey: ['prompt-assignements'] })
    },
  })

  return mutation
}

export const useDeletePromptAssignement = () => {
  const { getToken } = useAuth()
  const { asUser } = useAsUser()
  const { asOrg } = useAsOrg()
  const queryClient = useQueryClient()

  const deletePromptAssignement = async ({ id }: { id: string }) => {
    const headers = await getHeaders(getToken, asUser, asOrg)

    const response = await axios.delete(`${API_URL}/prompt-assignements/${id}`, { headers })

    return response.data
  }

  const mutation = useMutation({
    mutationKey: ['delete-prompt-assignements'],
    mutationFn: deletePromptAssignement,
    retry: 0,
    onSettled: async (_data, _error) => {
      return queryClient.invalidateQueries({ queryKey: ['prompt-assignements'] })
    },
  })

  return mutation
}
