import 'dayjs/locale/fr'
import Button from '@/components/custom/Button'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { useFolder } from '@/hooks/useFolder'
import { Link, useParams } from 'react-router-dom'
import SummaryContent from '../common/SummaryContent'

export default function FolderSummary() {
  const { id, summary_id } = useParams()

  const folder = useFolder(id)

  if (!folder?.data) {
    return <div>Empty</div>
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/folders">Dossiers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={`/folders/${id}`}>{folder.data.title}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>Rédaction</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex flex-row justify-between items-center">
        <h2 className="text-2xl">Nouvelle rédaction</h2>

        <Link to={`/folders/${id}/sources`}>
          <Button size="default" variant={'outline'}>
            Retour
          </Button>
        </Link>
      </div>

      {folder.data.sources.find((s) => s.summaryId === summary_id)?.summary && (
        <SummaryContent summary={folder.data.sources.find((s) => s.summaryId === summary_id)?.summary} />
      )}
    </>
  )
}
