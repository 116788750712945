import CustomLoader from '@/components/custom/CustomLoader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useToast } from '@/components/ui/use-toast'
import AddContext from '@/containers/others/AddContext'
import { useCreateSummary } from '@/hooks/useMeeting'
import { usePrompts } from '@/hooks/usePrompt'
import { type MeetingQueryResult, PromptShowFor } from '@/types/others.types'
import { logger } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useMixpanel } from 'react-mixpanel-browser'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

type Props = {
  meeting: MeetingQueryResult
  type: 'card' | 'dialog'
}

const formSchema = yup.object({
  prompt: yup.string().required('Veuillez sélectioner un type de compte rendu'),
})

type FormType = yup.InferType<typeof formSchema>

export default function SummaryNew({ meeting, type }: Props) {
  const { toast } = useToast()
  const [open, setOpen] = useState(false)

  const mixpanel = useMixpanel()

  const summaryMutation = useCreateSummary()

  // 1. Define your form.
  const form = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {},
  })

  const [, setSearchParams] = useSearchParams()

  const prompts = usePrompts({ type: 'PROMPT', showFor: PromptShowFor.MEETING })

  // 2. Define a submit handler.
  const onSubmit = async ({ prompt }: FormType) => {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    logger('create summary : ', { prompt })

    mixpanel?.track('Create Summary', {
      type,
      version: meeting.data?.summary.length + 1,
    })

    // Crisp event is sent by backend.

    mixpanel?.people.increment('summaries created')

    await summaryMutation.mutateAsync(
      { id: meeting.data?.id, promptId: prompt },
      {
        onSuccess: async (data) => {
          toast({
            title: 'Compte rendu créé',
          })
          setSearchParams(createSearchParams({ summary: data }))
          setOpen(false)
        },
        onError(error) {
          console.error('error : ', error)
          form.setError('root.serverError', {
            message: error?.response?.data?.message ?? error?.message,
          })
          toast({
            title: 'Erreur lors de la création du compte rendu',
            description: error?.response?.data?.message ?? error?.message,
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {type === 'card' ? (
            <Card>
              <CardHeader>
                <CardTitle>Générer un compte rendu</CardTitle>
              </CardHeader>
              <CardContent>
                <SummaryForm meeting={meeting} prompts={prompts.data || []} />
              </CardContent>
              <CardFooter>
                <Button onClick={form.handleSubmit(onSubmit)} type="submit" className="px-3" disabled={form.formState.isSubmitting}>
                  {form.formState.isSubmitting ? <CustomLoader /> : 'Générer le compte rendu'}
                </Button>
              </CardFooter>
            </Card>
          ) : (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                <Button>
                  <span className="hidden md:block">Générer un nouveau compte rendu</span>
                  <span className="md:hidden">Nouveau</span>
                </Button>
              </DialogTrigger>
              <DialogContent className={'lg:max-w-screen-lg overflow-y-scroll max-h-screen'}>
                <DialogHeader>
                  <DialogTitle>Générer un compte rendu</DialogTitle>
                </DialogHeader>
                <div className="flex items-center space-x-2">
                  <div className="grid flex-1 gap-2">
                    <SummaryForm meeting={meeting} prompts={prompts.data || []} />
                  </div>
                </div>
                <DialogFooter className="justify-end">
                  <Button onClick={form.handleSubmit(onSubmit)} type="submit" className="px-3" disabled={form.formState.isSubmitting}>
                    {form.formState.isSubmitting ? <CustomLoader /> : 'Générer le compte rendu'}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </form>
      </FormProvider>
    </>
  )
}

type SummaryFormProps = {
  meeting: MeetingQueryResult
  prompts: { id: string; name: string }[]
}

function SummaryForm({ meeting, prompts }: SummaryFormProps) {
  const { control } = useFormContext()

  return (
    <>
      <AddContext meeting={meeting} optionnel />
      <br />
      <FormField
        control={control}
        name="prompt"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Choisissez le type de compte rendu souhaité</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Choisissez un modèle" />
                </SelectTrigger>
              </FormControl>
              <SelectContent className="overflow-y-auto max-h-[10rem]">
                {prompts.map((p) => (
                  <SelectItem key={p.id} value={p.id}>
                    {p.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
