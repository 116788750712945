import CustomLoader from '@/components/custom/CustomLoader'
import { Button } from '@/components/ui/button'
import ButtonCreateMeeting from '@/containers/meetings/ButtonCreateMeeting'
import { UserButton, useUser } from '@clerk/clerk-react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useMutation } from '@tanstack/react-query'
import { MailQuestion, MenuIcon, UsersRound, X } from 'lucide-react'
import { Fragment, type ReactNode, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { useAsUser } from '@/hooks/useUser'
import { cn } from '@/lib/utils'
import { isAdmin } from '@/utils'
import ChooseAsUser from '../others/ChooseAsUser'
import Sidebar from './Sidebar'

type Props = {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [impersonate] = useLocalStorage('impersonate', false)

  const { asUser } = useAsUser()

  const { user, isLoaded } = useUser()

  //const [sent, setSent] = useState<boolean>(false);

  const verifyEmailMutation = useMutation({
    mutationKey: ['verify-email'],
    mutationFn: async () => {
      await user?.primaryEmailAddress?.prepareVerification({
        strategy: 'email_link',
        redirectUrl: 'https://app.notae.ai',
      })
    },
  })

  const verify = async () => {
    return verifyEmailMutation.mutate()
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <X className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component */}
                <Sidebar close={() => setSidebarOpen(false)} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <Sidebar />
      </div>

      <div className="lg:pl-72 mb-14">
        <div
          className={cn(
            'sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8',
            !!asUser && 'bg-orange-300',
          )}
        >
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1 items-center justify-between">
              {isAdmin(user) ? <ChooseAsUser /> : null}
              {impersonate ? (
                <Button variant="destructive">
                  <UsersRound className={'h-4 w-4 lg:mr-2'} /> <span className="hidden lg:block">Impersonification en cours</span>
                </Button>
              ) : (
                <div />
              )}
              {isLoaded && !user?.hasVerifiedEmailAddress ? (
                <>
                  {verifyEmailMutation.isSuccess ? (
                    <>
                      <Button variant="outline" disabled>
                        Un email a été envoyé à {user?.primaryEmailAddress?.emailAddress}
                      </Button>
                    </>
                  ) : (
                    <Button variant="destructive" onClick={verify} disabled={verifyEmailMutation.isPending}>
                      {verifyEmailMutation.isPending ? (
                        <CustomLoader />
                      ) : (
                        <>
                          <MailQuestion className="w-4 h-4 mr-2" /> Vérifiez votre adresse email
                        </>
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <div />
              )}
              <ButtonCreateMeeting />
              {/* <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <Search className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" aria-hidden="true" />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                /> */}
            </div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <Bell className="h-6 w-6" aria-hidden="true" />
                </button> */}

              {/* Separator */}
              <div className="block h-6 w-px bg-gray-200" aria-hidden="true" />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button as="div" className="-m-1.5 flex items-center gap-3">
                  <UserButton />
                </Menu.Button>
                {/*  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(active ? "bg-gray-50" : "", "block px-3 py-1 text-sm leading-6 text-gray-900")}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition> */}
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-5">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  )
}
