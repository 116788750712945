import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { classNames, isAdmin } from '@/utils'
import { useUser } from '@clerk/clerk-react'
import { Crisp } from 'crisp-sdk-web'
import { Book, FileSearch, FileStack, Settings, TerminalSquare, Users } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import ButtonCreateMeeting from '../meetings/ButtonCreateMeeting'

type NavItem = {
  name: string
  to: string
  icon: any
  current: boolean
  shouldBeAdmin?: boolean
  shouldBeSuperAdmin?: boolean
}

const navigation: NavItem[] = [
  /*   { name: "Dashboard", to: "/", icon: Home, current: true },
   */
  { name: 'Réunions', to: '/meetings', icon: Book, current: false },
  { name: 'Dossiers', to: '/folders', icon: FileStack, current: false, shouldBeAdmin: true },
  { name: 'Fichiers', to: '/files', icon: FileSearch, current: false, shouldBeAdmin: true },

  { name: 'Prompts', to: '/prompts', icon: TerminalSquare, current: false, shouldBeSuperAdmin: true },
  { name: 'Users', to: '/users', icon: Users, current: false, shouldBeAdmin: true },
  { name: 'Paramètres', to: '/settings', icon: Settings, current: false, shouldBeAdmin: false },
]

export default function Sidebar({ close }: { close?: () => void }) {
  const { user } = useUser()

  const [superAdmin] = useLocalStorage('SUPER_ADMIN', false)

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <img className="h-8 w-auto" src="/logo_entier_nblanc_sansfond.png" alt="Notae.ai Logo" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation
                .filter((n) => {
                  if (n.shouldBeAdmin) {
                    return isAdmin(user)
                  }
                  if (n.shouldBeSuperAdmin) {
                    return superAdmin
                  }
                  return true
                })
                .map((item) => (
                  <li key={item.name}>
                    <NavLink
                      onClick={close}
                      to={item.to}
                      className={({ isActive }) => {
                        return classNames(
                          isActive ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                        )
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={classNames(
                              isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {superAdmin && (item.shouldBeSuperAdmin || item.shouldBeAdmin) && '[ADMIN]'} {item.name}
                        </>
                      )}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </li>
          <Separator />
          <div className="w-full flex flex-col gap-4">
            <ButtonCreateMeeting close={close} className="w-full" />

            <Button variant={'outline'} onClick={() => Crisp.chat.open()}>
              Besoin d'aide ?
            </Button>
          </div>
          <li className="mt-auto">
            {/* <Link
              to="#"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
            >
              <Settings className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
              Settings
            </Link> */}
          </li>
        </ul>
      </nav>
    </div>
  )
}
