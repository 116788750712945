import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { MeetingQueryResult } from '@/types/others.types'
import { AlertTriangle } from 'lucide-react'

type Props = {
	meeting: MeetingQueryResult
}

export default function TranscriptError({ meeting }: Props) {
	return (
		<div className="w-full">
			<Alert variant="destructive">
				<AlertTriangle className="h-4 w-4" />
				<AlertTitle>Erreur</AlertTitle>
				<AlertDescription>
					Une erreur est survenue. <br />
					Nous allons regarder ça de plus près et revenir vers vous.
				</AlertDescription>

				<br />
				<Accordion type="single" collapsible className="w-full">
					<AccordionItem value="item-1" className="">
						<AccordionTrigger className="text-xs py-1">Informations techniques</AccordionTrigger>
						<AccordionContent className="text-xs">
							{!!meeting.data?.id && <>Meeting ID : {meeting.data?.id}</>}
							<br />
							{!!meeting.data?.status && <>Status Code : {meeting.data?.status}</>}
							<br />
							{!!meeting.data?.bot?.id && <>Bot ID : {meeting.data?.bot?.id}</>}
							<br />
							{!!meeting.data?.bot?.status.code && <>Bot Status Code : {meeting.data?.bot?.status.code}</>}
							<br />
							{!!meeting.data?.bot?.meetingUrl && <>Bot Meeting URL : {meeting.data?.bot?.meetingUrl}</>}
							<br />
							{!!meeting.data?.bot?.name && <>Bot Name : {meeting.data?.bot?.name}</>}
							<br />
							{!!meeting.data?.bot?.joinAt && <>Bot Meeting URL : {meeting.data?.bot?.joinAt}</>}
							<br />
							{!!meeting.data?.bot?.type && <>Bot Meeting URL : {meeting.data?.bot?.type}</>}
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</Alert>
		</div>
	)
}
